<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/club-admin-dashboard">
              <v-img
                  :src="require('../../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Alle Veranstaltungen
              </span>
          </div>

          <v-spacer/>
          <div class="d-flex align-center mr-5">
            <v-btn style="background:white;color:#00B51A"
                   height="45"
                   elevation="2"
                   v-on:click="newClubEvent">
              <h3 class="font-weight-medium text-none">
                Neue Veranstaltung anlegen
              </h3>
            </v-btn>
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
      <v-row class="pa-0 ml-2 d-flex align-start">
        <ClubAdminSideMenu/>
        <v-col class="mr-15">
          <v-card class="mr-3 ml-3">
            <v-simple-table class="mt-5">
              <template v-slot:default>
                <thead>
                <tr>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Veranstaltung
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Beschreibung
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Startet am
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Endet am
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    min. Teilnehmer
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    max. Teilnehmer
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Belegte Plätze
                  </h3></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="event in clubEvents" :key="event.id" @mouseover="selectItem(event)" @mouseleave="unSelectItem()">
                  <td>{{ event.title }}</td>
                  <td>{{ event.body }}</td>
                  <td>{{ formatEventTime(event.starts_at) }}</td>
                  <td>{{ formatEventTime(event.ends_at) }}</td>
                  <td>{{ event.min_member }}</td>
                  <td>{{ event.max_member }}</td>
                  <td>{{ event.applied_member }} / {{ event.max_member }}</td>

                  <td style="width: 35px">
                    <v-btn :disabled="selectedRow !== event" @click="selectClubEventForReplicate(event)"
                           width="35"
                           plain
                           height="35"
                           class="ma-2"
                           ripple
                           outlined
                           color="#00B51A"
                    >
                      <v-icon size="25">mdi-content-copy</v-icon>
                    </v-btn>
                  </td>
                  <td style="width: 35px">
                    <v-btn :disabled="selectedRow !== event" @click="selectClubEventForEdit(event)"
                           width="35"
                           plain
                           height="35"
                           class="ma-2"
                           ripple
                           outlined
                           color="#00B51A"
                    >
                      <v-icon size="25">mdi-pencil</v-icon>
                    </v-btn>
                  </td>

                  <td style="width: 35px">
                    <div class="text-center">

                        <v-btn :disabled="selectedRow !== event" @click="selectClubEventForDialog"

                               width="35"
                               plain
                               height="35"
                               class="ma-2"
                               ripple
                               outlined
                               color="#00B51A"
                        >
                          <v-icon size="25">mdi-delete</v-icon>
                        </v-btn>


                    </div>
                  </td>

                </tr>
                <v-dialog
                    v-model="dialog"
                    width="500"
                    persistent :retain-focus="false"
                >


                  <v-card>
                    <v-card-title class="headline green" style="color: white">
                      Hinweis
                    </v-card-title>

                    <v-card-text class="mt-3">
                      Soll diese Veranstaltung wirklich gelöscht werden?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="green"
                          text
                          @click="dialog = false"
                      >
                        Nein
                      </v-btn>
                      <v-btn
                          color="green"
                          text
                          @click="deleteEvent()"
                      >
                        Ja
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                </tbody>
              </template>
            </v-simple-table>
            <v-spacer/>
            <v-pagination
                color="#00B51A"
                v-model="page"
                :length="lastPage"
                @input="fetchClubEvents()"
            />
          </v-card>
          <v-snackbar
              v-model="snackbar"
          >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
      <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import ClubAdminSideMenu from "@/components/ClubAdminSideMenu";
import CustomFooter from "@/components/CustomFooter";
import events from "@/api/requests/events";
const {DateTime} = require("luxon");

export default {
  components: {ClubAdminSideMenu, CustomFooter},
  data() {
    return {
      DateTime: DateTime,
      loading: false,
      users: [],
      clubEvents: [],
      page: 1,
      lastPage: 1,
      searchQuery: '',
      error: null,
      selectedItem: null,
      snackbarText: '',
      snackbar: false,
      selectedRow: null,
      selectedClubEvent: null,
      dialog: null,
      selectedFilter: 'Alle',
      items: ['Alle', 'Admins', 'Club Admins', 'Nutzer']
    }
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchClubEvents();
    }
  },
  methods: {
    deleteEvent() {
      events.deleteClubEvent(this.$store.state.token, this.selectedClubEvent.id).then(response => {
        console.log(response);
        this.dialog = false;
        this.fetchClubEvents();
        this.snackbarText = 'Club Veranstaltung erfolgreich gelöscht.'
        this.snackbar = true;
      }).catch(e => {
        console.log(e.response);
        this.dialog = false;
        this.snackbarText = 'Club Veranstaltung konnte nicht gelöscht werden.'
        this.snackbar = true;
      });
    },
    selectClubEventForDialog() {
      this.dialog = true;
      this.selectedClubEvent = this.selectedRow;
    },
    selectClubEventForEdit(clubEvent) {
      this.$router.push(`/edit-club-event/${clubEvent.id}`);
    },
    selectClubEventForReplicate(clubEvent) {
      this.$router.push(`/replicate-club-event/${clubEvent.id}`);
    },
    selectItem(event) {
      this.selectedRow = event;
    },
    unSelectItem() {
      this.selectedRow = null;
    },
    fetchClubEvents() {
      this.loading = true
      events.getAll(this.$store.state.token, this.page).then(response => {
        console.log(response);
        this.lastPage = response.meta.last_page;
        this.clubEvents = response.data.map(event => ({
          id: event.id,
          title: event.title == null ? '' : event.title,
          body: event.body == null ? '' : event.body,
          date: event.date == null ? '' : event.date,
          starts_at: event.starts_at == null ? '' : event.starts_at,
          ends_at: event.ends_at == null ? '' : event.ends_at,
          min_member: event.min_member == null ? '' : event.min_member,
          max_member: event.max_member == null ? '' : event.max_member,
          applied_member: event.participants == null ? '' : event.participants,
        }));
      }).catch(e => {
        console.log(e.response);
      });
    },
    formatEventTime(datetime) {
      if (datetime === '') {
        return '';
      }
      else{
        let tmpDate = new Date(datetime).toLocaleDateString(
            'de-de',
            {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            }
        );
        let tmpTime = new Date(datetime).toLocaleTimeString(
            'de-de',
            {
              hour: 'numeric',
              minute: '2-digit',
            }
        );
        return tmpDate + ' um ' + tmpTime + ' Uhr';
      }
    },
    newClubEvent() {
      this.$router.push('/new-club-event');
    }
  }

}
</script>