<template>
  <v-flex>
    <v-container fluid class="d-flex align-start pb-0 fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A">
        <v-flex>
          <v-row>
            <div class="pa-0 d-flex align-start">
              <router-link to="/club-admin-dashboard">
                <v-img
                    :src="require('../assets/logo_club4ever.svg')"
                    class="ma-0"
                    contain
                    max-height="50"
                    max-width="190"
                />
              </router-link>
            </div>
            <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Mein Club
              </span>
            </div>

            <v-spacer/>
            <div class="d-flex align-center mr-5">
              <v-btn style="background:white;color:#00B51A"
                     height="45"
                     elevation="2"
                     v-on:click="editClub">
                <h3 class="font-weight-medium text-none">
                  Club bearbeiten
                </h3>
              </v-btn>
            </div>
          </v-row>
        </v-flex>
      </v-app-bar>
      <v-layout justify-space-between column>
        <v-row class="mt-16 ml-14">
          <ClubAdminSideMenu/>
          <v-col class="mt-4">
            <h1 v-if="loading">
              <v-progress-circular class="mt-5"
                                   indeterminate
                                   color="#00B51A"
              ></v-progress-circular>
            </h1>
            <v-card class="ma-2 ml-2" width="800" elevation="1" v-else>
              <v-row>
                <v-col class="ml-6 mt-3">
                  <div class="pa-2 mt-7">
                <span class="font-weight-bold">
                  Logo
                </span>
                  </div>
                  <div class="pa-2 ml-3 mt-16 mb-15">
                    <v-row class="mb-6">
                    <span class="font-weight-bold">
                  Hintergrundbild
                </span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                              color="#00B51A"
                              dark
                              v-bind="attrs"
                              v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </template>
                        <span>Das Hintergrundbild wird Clubmitgliedern nach dem Einloggen in der App angezeigt</span>
                      </v-tooltip>
                    </v-row>

                  </div>
                  <div class="pa-2 mt-7">
                <span class="font-weight-bold">
                  Name
                </span>
                  </div>
                  <div class="pa-2">
                 <span class="font-weight-bold">
                  Nutzeranzahl
                </span>
                  </div>
                  <div class="pa-2">
                 <span class="font-weight-bold">
                  E-Mail-Adresse
                </span>
                  </div>
                  <div class="pa-2">
                 <span class="font-weight-bold">
                  Website
                </span>
                  </div>
                  <div class="pa-2">
                 <span class="font-weight-bold">
                  Stadt
                </span>
                  </div>
                  <div class="pa-2">
                 <span class="font-weight-bold">
                  Club Adresse
                </span>
                  </div>
                  <div class="pa-2">
                 <span class="font-weight-bold">
                  Club Plz Ort
                </span>
                  </div>
                  <div class="pa-2">
                 <span class="font-weight-bold">
                  Rechnungsempfänger Nachname, Vorname
                </span>
                  </div>
                  <div class="pa-2">
                 <span class="font-weight-bold">
                  Rechnungsempfänger E-Mail-Adresse
                </span>
                  </div>
                </v-col>
                <v-col class="ml-6 mt-3">
                  <div class="pa-2 ml-1">
                    <v-avatar class="mt-3 mb-3">
                      <v-img :src="(club.logo_url)" max-height="100" width="60"></v-img>
                    </v-avatar>
                  </div>
                  <div class="pa-2 ml-1">
                    <v-avatar width="60" height="100" tile class="mb-10 mr-4" color="#ededed">
                      <v-img v-if="club.background_url!=null" min-width="50"
                             max-width="50" min-height="100" max-height="100" v-bind:src="(club.background_url)"/>
                      <div v-else class="text-center">
                        <v-icon large color="#00B51A">mdi-image-outline
                        </v-icon>
                      </div>
                    </v-avatar>
                  </div>
                  <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                  {{ club.name }}
                </span>
                  </div>
                  <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                  {{ club.user_amount }}
                </span>
                  </div>
                  <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                  {{ club.website }}
                </span>
                  </div>
                  <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                  {{ club.email }}
                </span>
                  </div>
                  <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                  {{ club.city.name }} ({{ club.city.state }})
                </span>
                  </div>
                  <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                   {{ club.mailing_address }}
                </span>
                  </div>
                  <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                   {{ club.zip_code }} {{ club.address_city }}
                </span>
                  </div>
                  <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                   {{ club.invoice_recipient_last_name }}, {{ club.invoice_recipient_first_name }}
                </span>
                  </div>
                  <div class="pa-2 ml-1 mb-10">
                <span class="font-weight-regular">
                   {{ club.invoice_recipient_email }}
                </span>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>
</template>
<script>
import ClubAdminSideMenu from "@/components/ClubAdminSideMenu";
import club from "@/api/requests/club";
import CustomFooter from "@/components/CustomFooter";

export default {
  components: {ClubAdminSideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      saving: false,
      club: null,
      snackbarText: '',
      snackbar: false,
    }
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchDetail()
    }
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    fetchDetail() {
      this.loading = true
      club.clubDetailByToken(this.$store.state.token).then(response => {
        this.club = response.data;
        this.loading = false;
      }).catch(e => {
        console.log(e);
        this.snackbar = true;
        this.snackbarText = 'Es ist ein Fehler aufgetreten';
      });
    },
    editClub() {
      this.$router.push(`/club-admin-edit-club/${this.club.id}`)
    }
  }
}
</script>
