<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Zusatz-Angebote
              </span>
          </div>
          <v-spacer/>
          <div class="d-flex align-center mr-5">
            <v-text-field
                solo
                dense
                clearable
                color="#00B51A"
                height="45px"
                style="background-color: white; max-width: 200px; max-height: 40px; min-height: 40px; border-radius: 5px"
                placeholder="Suche"
                v-model="searchQuery"
                @keyup="fetchDeals()"
                @click:clear="this.clearQuery"
                required
            />
          </div>
          <div class="d-flex align-center mr-5">
            <v-autocomplete
                v-model="selectedCountry"
                :items="countries"
                placeholder="Land"
                no-data-text="Keine Länder gefunden"
                solo
                clearable
                dense
                height="45px"
                class="pa-0 ma-0"
                style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; border-radius: 5px"
                item-text="name"
                color="green lighten-2"
                item-color="green"
                item-value="id"
                @click:clear="clearCountry"
                @change="countrySelectionChanged"
            />
          </div>
          <div class="d-flex align-center mr-5">
            <v-autocomplete
                v-model="selectedState"
                :items="states"
                placeholder="Bundesland"
                no-data-text="Keine Bundesländer gefunden"
                solo
                clearable
                dense
                height="45px"
                style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; height: 45px; border-radius: 5px"
                color="green lighten-2"
                item-color="green"
                item-text="name"
                @click:clear="clearState"
                item-value="id"
                @change="stateSelectionChanged"
            />
          </div>

          <div class="d-flex align-center mr-5">
            <v-autocomplete
                v-model="selectedCity"
                :items="cities"
                placeholder="Stadt"
                no-data-text="Keine Städte gefunden"
                solo
                clearable
                dense
                height="45px"
                style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; height: 45px; border-radius: 5px"
                color="green lighten-2"
                item-color="green"
                item-text="name"
                @click:clear="clearCity"
                item-value="id"
                @change="citySelectionChanged"
            />
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
    <v-row class="pa-0 ml-2 d-flex align-start">
      <SideMenu/>
      <v-col class="mr-15">
        <v-card class="mr-3 ml-3">
          <v-simple-table class="mt-6">
            <template v-slot:default>
              <thead>
              <tr>
                <th>
                  <h3 class="font-weight-bold text-none" @click="changeSorting">
                    Livegang in
                    <v-icon v-if="sorting === 'asc'" size="15">mdi-arrow-down-bold</v-icon>
                    <v-icon v-else size="15">mdi-arrow-up-bold</v-icon>
                  </h3>
                </th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Land
                </h3></th>
                <th>
                  <h3 class="font-weight-bold text-capitalize">
                    Bundesland
                  </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Stadt
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Logo
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Club Name
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Deal ID
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Titel
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Preis
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Firmenname
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Verkäufer Nachname, Vorname
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Verkäufer E-Mail
                </h3></th>
                <th/>
              </tr>
              </thead>
              <tbody>
              <tr v-for="deal in deals" :key="deal.id">
                <td>
                  <v-icon size="22"
                  >mdi-timer-sand
                  </v-icon
                  >
                  {{ `Tage: ${calculateDays(deal.liveIn)} Stunden: ${calculateHours(deal.liveIn)}` }}
                </td>
                <td>{{ deal.country }}</td>
                <td>{{ deal.state }}</td>
                <td>{{ deal.city }}</td>
                <td>
                <v-avatar class="mt-3 mb-3">
                  <v-img :src="(deal.clubLogo)" max-height="100" width="60"></v-img>
                </v-avatar>
              </td>
                <td>{{ deal.clubName }}</td>
                <td>{{ deal.id }}</td>
                <td>{{ deal.title }}</td>
                <td>{{ deal.price }}€</td>
                <td>{{ deal.companyName }}</td>
                <td>{{ deal.userName }}</td>
                <td>{{ deal.userEmail }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-pagination
              color="#00B51A"
              v-model="page"
              :length="lastPage"
              @input="fetchDeals(page)"
          ></v-pagination>
        </v-card>
      </v-col>
    </v-row>
      <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import deal from "@/api/requests/deal.js";
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import city from "@/api/requests/city";
import country from "@/api/requests/country";

export default {
  components: {CustomFooter, SideMenu},
  data() {
    return {
      loading: false,
      deals: [],
      page: 1,
      lastPage: 1,
      sorting: 'asc',
      searchQuery: '',
      selectedCountry: 83,
      countries: [],
      selectedState: null,
      states: [],
      selectedCity: null,
      cities: [],
      error: null,
    }
  },
  created() {
    this.fetchCountries();
    this.fetchDeals();
    this.fetchStatesForCountry()
  },
  methods: {
    calculateDays(hours) {
      let days = hours / 24;
      if (days <= 1) {
        return '< 1';
      } else return Math.floor(days);
    },
    calculateHours(hours) {
      let restHours = hours % 24;
      if (restHours <= 1) {
        return '< 1';
      } else return restHours;
    },
    changeSorting() {
      if (this.sorting === 'asc') {
        this.sorting = 'desc';
      } else {
        this.sorting = 'asc';
      }
      this.fetchDeals()
    },
    clearQuery(){
      this.searchQuery = '';
      this.fetchDeals();
    },
    clearCountry(){
      this.selectedCountry = null;
      this.countrySelectionChanged();
    },
    clearState(){
      this.selectedState = null;
      this.stateSelectionChanged();
    },
    clearCity(){
      this.selectedCity = null;
      this.citySelectionChanged();
    },
    countrySelectionChanged() {
      this.selectedCity = null;
      this.cities = [];
      this.selectedState = null;
      this.states = [];
      this.fetchDeals();
      this.fetchStatesForCountry()
    },
    stateSelectionChanged() {
      if (this.selectedState == null) {
        this.countrySelectionChanged();
      } else {
        this.cities = [];
        this.selectedCity = null;
        this.fetchDeals();
        this.fetchCitiesForState()
      }
    },
    citySelectionChanged() {
      if (this.selectedCity == null) {
        this.stateSelectionChanged();
      } else {
        this.clubs = [];
        this.fetchDeals();
      }
    },
    fetchStatesForCountry() {
      city.getStatesForCountry(this.selectedCountry).then(response => {
        this.states = [];
        this.states = response.data.map(state => ({
          name: state
        }));
      })
    },
    fetchCitiesForState() {
      country.getCitiesForState(this.selectedState).then(response => {
        this.cities = [];
        this.cities = response.data.map(city => ({
          name: city.name,
          id: city.id,
        }));
      })
    },
    fetchCountries() {
      this.loading = true
      country.getAll().then(response => {
        this.countries = [];
        this.countries = response.data.map(country => ({
          name: country.name,
          id: country.id,
          code: country.code,
        }));
        this.loading = false;
      });
    },
    formatDate(date){
      if(date==null){
        return null;
      }
      return new Date(date).toLocaleDateString(
        'de-de',
        {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }
      );
    },
    formatDateTime(date){
      if(date==null){
        return null;
      }
      return new Date(date).toLocaleDateString(
        'de-de',
        {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }
      );
    },
    fetchDeals() {
      this.loading = true
      deal.getAdditional(this.$store.state.token, this.page, this.sorting, this.selectedCountry, this.selectedState, this.selectedCity, this.searchQuery).then(response => {
        this.lastPage = response.meta.last_page;
        this.deals = response.data.map(deal => ({
          title: deal.title,
          id: deal.id,
          price: deal.price.toFixed(2).replace('.', ','),
          expirationTime: this.formatDateTime(deal.expiration_time),
          userID: deal.user.id,
          userEmail: deal.user.email,
          userName: deal.user.last_name + ', ' + deal.user.first_name,
          companyName: deal.user.company_name,
          country: 'Deutschland',
          state: deal.user.club.city.state,
          city: deal.user.club.city.name,
          clubLogo: deal.user.club.logo_url,
          clubName: deal.user.club.name,
          donationClub: deal.donation_club.toFixed(2).replace('.', ','),
          liveIn: this.calculateLiveIn(deal.created_at),
        }));
      }).catch(e => {
        console.log(e.response);
        if (e.response.status === 401) {
          this.$router.push('/login');
        }
      });
    },
    calculateLiveIn(createdAt) {
      let createdAtDate = new Date(createdAt);
      let liveDate = new Date(createdAtDate.getTime() + (5 * 24 * 60 * 60 * 1000));
      let now = new Date();
      return Math.floor(Math.abs(now - liveDate) / 36e5);
    }
  }

}
</script>