import Repository from "../repository";

export default {
    async fetchMe(token) {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const response = await Repository.get(`/me`, config);
        return response.data;
    },
}
