<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/club-admin-dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Dashboard
              </span>
          </div>

          <v-spacer/>
          <div class="d-flex align-center mr-5">
            <v-sheet width="250" height="45" color="#00B51A">
              <v-select color="white" item-color="#00B51A" height="45" style="color: white; border-color: white"
                        :items="items"
                        dense
                        solo
                        v-model="selected"
                        @change="selectionChanged"
              ></v-select>
            </v-sheet>
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
      <v-row class="pa-0 d-flex align-start">
        <ClubAdminSideMenu/>
        <v-progress-circular v-if="loading" class="mt-5"
                             indeterminate
                             color="#00B51A"
        ></v-progress-circular>
        <v-col v-else class="mt-10">
          <v-row class="mb-10">
            <v-card
                class="mx-auto text-center"
                color="#00B51A"
                dark
                min-width="400"
                max-height="180"
                max-width="800"
            >
              <v-card-text style="font-size: 30px">
                <v-sheet color="#EDEDED">
                  <v-sparkline
                      auto-draw
                      :value="appUsers"
                      style="font-size: 30px"
                      color="#A60054"
                      height="82"
                      width="330"
                      padding="24"
                      stroke-linecap="round"
                      smooth
                  >
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text class="pa-0" style="font-size: 25px">
                <div class="font-weight-medium">
                  Nutzer
                </div>
              </v-card-text>
              <v-card-text class="pt-2" style="font-size: 25px">
                <div class="font-weight-light">
                  {{ appUsers[appUsers.length - 1].value }}
                </div>
              </v-card-text>
            </v-card>

            <v-card
                class="mx-auto text-center"
                color="#00B51A"
                dark
                min-width="400"
                max-height="180"
                max-width="800"
            >
              <v-card-text style="font-size: 30px">
                <v-sheet color="#EDEDED">
                  <v-sparkline
                      auto-draw
                      :value="clubAmount"
                      style="font-size: 30px"
                      color="#A60054"
                      height="82"
                      width="330"
                      padding="24"
                      stroke-linecap="round"
                      smooth
                  >
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text class="pa-0" style="font-size: 25px">
                <div class="font-weight-medium">
                  € Betrag Club
                </div>
              </v-card-text>
              <v-card-text class="pt-2" style="font-size: 25px">
                <div class="font-weight-light">
                  {{ clubAmount[clubAmount.length - 1].value.toFixed(2).toString().replace('.', ',') }}€
                </div>
              </v-card-text>
            </v-card>
            <v-card
                class="mx-auto text-center"
                color="#00B51A"
                dark
                min-width="400"
                max-height="180"
                max-width="800"
            >
              <v-card-text style="font-size: 30px">
                <v-sheet color="#EDEDED">
                  <v-sparkline
                      auto-draw
                      :value="activeOffers"
                      style="font-size: 30px"
                      color="#A60054"
                      height="82"
                      width="330"
                      padding="24"
                      stroke-linecap="round"
                      smooth
                  >
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text class="pa-0" style="font-size: 25px">
                <div class="font-weight-medium">
                  Aktuelle Angebote
                </div>
              </v-card-text>
              <v-card-text class="pt-2" style="font-size: 25px">
                <div class="font-weight-light">
                  {{ activeOffers[activeOffers.length - 1].value }}
                </div>
              </v-card-text>
            </v-card>

          </v-row>
          <v-row class="mb-10">
            <v-card
                class="mx-auto text-center"
                color="#00B51A"
                dark
                min-width="400"
                max-height="180"
                max-width="800"
            >
              <v-card-text style="font-size: 30px">
                <v-sheet color="#EDEDED">

                  <v-sparkline
                      auto-draw
                      :value="donationAverage"
                      style="font-size: 30px"
                      color="#A60054"
                      height="82"
                      width="330"
                      padding="24"
                      stroke-linecap="round"
                      smooth
                  >
                  </v-sparkline>

                </v-sheet>
              </v-card-text>

              <v-card-text class="pa-0" style="font-size: 25px">
                <div class="font-weight-medium">
                  Ø Spendenbetrag pro Deal
                </div>
              </v-card-text>
              <v-card-text class="pt-2" style="font-size: 25px">
                <div class="font-weight-light">
                  {{ donationAverage[donationAverage.length - 1].value.toFixed(2).toString().replace('.', ',') }}€
                </div>
              </v-card-text>
            </v-card>
            <v-card
                class="mx-auto text-center"
                color="#00B51A"
                dark
                min-width="400"
                max-height="180"
                max-width="800"
            >
              <v-card-text style="font-size: 30px">
                <v-sheet color="#EDEDED">
                  <v-sparkline
                      auto-draw
                      :value="soldDeals"
                      style="font-size: 30px"
                      color="#A60054"
                      height="82"
                      width="330"
                      padding="24"
                      stroke-linecap="round"
                      smooth
                  >
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text class="pa-0" style="font-size: 25px">
                <div class="font-weight-medium">
                  Verkaufte Deals
                </div>
              </v-card-text>
              <v-card-text class="pt-2" style="font-size: 25px">
                <div class="font-weight-light">
                  {{ soldDeals[soldDeals.length - 1].value }}
                </div>
              </v-card-text>
            </v-card>
            <v-card
                class="mx-auto text-center"
                color="#00B51A"
                dark
                min-width="400"
                max-height="180"
                max-width="800"
            >
              <v-card-text style="font-size: 30px">
                <v-sheet color="#EDEDED">
                  <v-sparkline
                      auto-draw
                      :value="runtimeIncrease"
                      style="font-size: 30px"
                      color="#A60054"
                      height="82"
                      width="330"
                      padding="24"
                      stroke-linecap="round"
                      smooth
                  >
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text class="pa-0" style="font-size: 25px">
                <div class="font-weight-medium">
                  Wie oft Laufzeit Erhöhung?
                </div>
              </v-card-text>
              <v-card-text class="pt-2" style="font-size: 25px">
                <div class="font-weight-light">
                  {{
                    runtimeIncrease[runtimeIncrease.length - 1].value == 0 ? 0 : runtimeIncrease[runtimeIncrease.length - 1].value.replace('.', ',').slice(0, -2)
                  }}%
                </div>
              </v-card-text>
            </v-card>


          </v-row>
          <v-row class="mb-10">
            <v-card
                class="mx-auto text-center"
                color="#00B51A"
                dark
                min-width="400"
                max-height="180"
                max-width="800"
            >
              <v-card-text style="font-size: 30px">
                <v-sheet color="#EDEDED">
                  <v-sparkline
                      auto-draw
                      :value="rangeIncrease"
                      style="font-size: 30px"
                      color="#A60054"
                      height="82"
                      width="330"
                      padding="24"
                      stroke-linecap="round"
                      smooth
                  >
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text class="pa-0" style="font-size: 25px">
                <div class="font-weight-medium">
                  Wie oft Reichweiten Erhöhung?
                </div>
              </v-card-text>
              <v-card-text class="pt-2" style="font-size: 25px">
                <div class="font-weight-light">
                  {{
                    rangeIncrease[rangeIncrease.length - 1].value == 0 ? 0 : rangeIncrease[rangeIncrease.length - 1].value.replace('.', ',').slice(0, -2)
                  }}%
                </div>
              </v-card-text>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
      <CustomFooter/>
    </v-layout>

  </v-container>

</template>

<script>
import ClubAdminSideMenu from "@/components/ClubAdminSideMenu";
import CustomFooter from "@/components/CustomFooter";
import dashboard from "@/api/requests/dashboard";

export default {
  components: {ClubAdminSideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      error: null,
      selected: 'letzte 30 Tage',
      items: ['letzte 30 Tage', 'heute', 'letzte 10 Tage', 'dieses Jahr'],
      selectedClub: null,
      appUsers: [],
      activeOffers: [],
      soldDeals: [],
      clubAmount: [],
      donationAverage: [],
      runtimeIncrease: [],
      rangeIncrease: [],
    }
  },
  created() {
    this.fetchData(3);
  },
  methods: {
    fetchData(filter) {
      this.loading = true
      dashboard.fetchDashboardData(this.$store.state.token, filter).then(response => {
        console.log(response);
        this.appUsers = response.appUser.map(appUserData => ({
          value: appUserData.count != null ? appUserData.count : 0
        }));
        this.activeOffers = response.activeOffers.map(activeOffersData => ({
          value: activeOffersData.count != null ? activeOffersData.count : 0
        }));
        this.soldDeals = response.soldDeals.map(soldDealsData => ({
          value: soldDealsData.count != null ? soldDealsData.count : 0
        }));
        this.clubAmount = response.clubAmount.map(clubAmountData => ({
          value: clubAmountData.count != null ? clubAmountData.count : 0
        }));
        this.donationAverage = response.donationAverage.map(donationAverageData => ({
          value: donationAverageData.count != null ? donationAverageData.count : 0
        }));
        this.runtimeIncrease = response.percentageRuntimeIncrease.map(runtimeIncreaseData => ({
          value: runtimeIncreaseData.count != null ? runtimeIncreaseData.count : 0
        }));
        this.rangeIncrease = response.percentageRangeIncrease.map(rangeIncreaseData => ({
          value: rangeIncreaseData.count != null ? rangeIncreaseData.count : 0
        }));
        this.loading = false;
      }).catch(e => {
        console.log(e.response);
        if (e.response.status === 401) {
          this.$router.push('/login');
        }
      });
    },
    selectionChanged(item) {
      switch (item) {
        case this.items[0]:
          this.fetchData(3);
          break;
        case this.items[1]:
          this.fetchData(1);
          break;
        case this.items[2]:
          this.fetchData(2);
          break;
        case this.items[3]:
          this.fetchData(4);
          break;
      }
    }
  }

}
</script>