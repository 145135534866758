<template>
  <v-flex>
    <v-container fluid class="d-flex align-start pb-0 fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A"
      >
        <div class="d-flex align-center">
          <v-row>
            <v-icon size="30" class="ml-1" @click="goBack" style="color: white"
            >mdi-arrow-left
            </v-icon>
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </v-row>


        </div>
        <span class="font-weight-medium" style="color: white;font-size: x-large">
            Vereinszugehörigkeit ändern
              </span>
        <v-spacer/>

      </v-app-bar>
      <v-layout justify-space-between column>
        <v-row class="mt-16 ml-14">
          <SideMenu/>
          <v-col>
            <h1 v-if="loading">
              <v-progress-circular class="mt-5"
                                   indeterminate
                                   color="#00B51A"
              ></v-progress-circular>
            </h1>
            <v-card class="mt-2 ml-2" width="800" elevation="1" v-else>
              <v-col>
                <v-container>
                   <span class="font-weight-regular">
               Stadt
              </span>
                    <v-autocomplete
                        v-model="selectedCity"
                        :items="cities"
                        @change="citySelectionChanged"
                        no-data-text="Keine Städte gefunden"
                        outlined
                        color="blue-grey lighten-2"
                        item-text="name"
                        item-value="id"
                    >
                    </v-autocomplete>
                    <span class="font-weight-regular">
               Club
              </span>
                    <v-autocomplete
                        v-model="selectedClub"
                        :items="clubs"
                        no-data-text="Keine Clubs gefunden"
                        outlined
                        color="blue-grey lighten-2"
                        item-text="name"
                        item-value="id"
                    >
                    </v-autocomplete>
                  </v-container>
                  <div class="text-center">
                    <v-btn class="mt-10 mb-5" style="background:#00B51A;color:white"
                           height="45"
                           width="200"
                           elevation="2"
                           :loading="saving"
                           v-on:click="changeClub">
                      <h3 class="font-weight-medium text-capitalize">
                        Speichern
                      </h3>
                    </v-btn>
                  </div>
                  <v-snackbar
                      v-model="snackbar"
                  >
                    {{ snackbarText }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                          color="white"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>

</template>
<script>
import city from "@/api/requests/city";
import SideMenu from "@/components/SideMenu";
import user from "@/api/requests/user";
import CustomFooter from "@/components/CustomFooter";

export default {
  components: {SideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      saving: false,
      cities: [],
      clubs: [],
      selectedCity: null,
      selectedClub: null,
      error: null,
      snackbarText: '',
      snackbar: false,
    }
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchCities()
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    fetchCities() {
      this.loading = true
      city.getAll().then(response => {
        this.cities = [];
        this.cities = response.data.map(city => ({
          name: `${city.name} (${city.state})`,
          id: city.id,
          state: city.state,
        }));
        this.loading = false;
      });
    },
    fetchClubsForCity() {
      city.getClubsForCity(this.selectedCity).then(response => {
        this.clubs = [];
        this.clubs = response.data.map(club => ({
          name: club.name,
          id: club.id,
        }));
      })
    },
    changeClub() {
      if (this.selectedCity != null) {
        if (this.selectedClub != null) {
          user.changeClub(this.$store.state.token, this.selectedClub).then(response =>{
            console.log(response);
            this.$router.go(-1);
          });
        } else {
          this.snackbar = true;
          this.snackbarText = 'Bitte wähle deinen Club aus';
        }
      } else {
        this.snackbar = true;
        this.snackbarText = 'Bitte wähle deine Stadt aus';
      }
    },
    citySelectionChanged() {
      this.selectedClub = null;
      this.clubs = [];
      this.fetchClubsForCity()
    },
  }
}
</script>
