<!-- TODO: paginationBar -->

<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Alle Kunden
              </span>
          </div>

          <v-spacer/>
          <div class="d-flex align-center mr-5">
            <v-btn style="background:white;color:#00B51A"
                   height="45"
                   elevation="2"
                   v-on:click="manageServices()">
              <h3 class="font-weight-medium text-none">
                Dienstleistungen verwalten
              </h3>
            </v-btn>
          </div>
          <div class="d-flex align-center mr-5">
            <v-btn style="background:white;color:#00B51A"
                   height="45"
                   elevation="2"
                   v-on:click="newCustomer">
              <h3 class="font-weight-medium text-none">
                Neuen Kunden anlegen
              </h3>
            </v-btn>
          </div>
          <div class="d-flex align-center mr-5">
            <v-btn style="background:white;color:#00B51A"
                   height="45"
                   elevation="2"
                   v-on:click="newRecipient()">
              <h3 class="font-weight-medium text-none">
                Neuen Empfänger anlegen
              </h3>
            </v-btn>
          </div>
          <div class="d-flex align-center mr-5">
            <v-btn style="background:white;color:#00B51A"
                   height="45"
                   elevation="2"
                   v-on:click="gotoInvoiceRun"> <!-- TODO: startInvoice() -->
              <h3 class="font-weight-medium text-none">
                Rechnungsdurchlauf starten
              </h3>
            </v-btn>
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
      <v-row class="pa-0 ml-2 d-flex align-start">
        <SideMenu/>
        <v-col class="mr-15">
          <v-card class="mr-3 ml-3">
            <v-simple-table class="mt-5">
              <template v-slot:default>
                <thead>
                <tr>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Name
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Kundennummer
                  </h3></th>
                  <th/><th/><th/>
                </tr>
                </thead>
                <tbody id="lbody">
                <tr v-for="customer in customers" :key="customer.id" @mouseover="selectItem(customer)" @mouseleave="unSelectItem()" @click="viewRecipients(customer)">
                  <td>{{ customer.name }}</td>
                  <td>{{ customer.customerNo }}</td>
                  <td style="width: 35px">
                    <v-btn :disabled="selectedItem !== customer"
                           v-on:click.stop="newRecipient(customer)"
                           width="35"
                           plain
                           height="35"
                           class="ma-2"
                           ripple
                           outlined
                           color="#00B51A"
                    >
                      <v-icon size="25">mdi-plus</v-icon></v-btn>
                  </td>
                  <td style="width: 35px">
                    <v-btn :disabled="selectedItem !== customer"
                           v-on:click.stop="editCustomer(customer)"
                           width="35"
                           plain
                           height="35"
                           class="ma-2"
                           ripple
                           outlined
                           color="#00B51A"
                    >
                      <v-icon size="25">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td style="width: 35px">
                    <div class="text-center">
                      <v-btn :disabled="selectedItem !== customer"
                             v-on:click.stop="selectionForDialog"
                             width="35"
                             plain
                             height="35"
                             class="ma-2"
                             ripple
                             outlined
                             color="#00B51A"
                      >
                        <v-icon size="25">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <v-dialog
                    v-model="dialog"
                    width="500"
                >
                  <v-card>
                    <v-card-title class="headline green" style="color: white">
                      Hinweis
                    </v-card-title>

                    <v-card-text class="mt-3">
                      Soll dieser Kunde wirklich gelöscht werden?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="green"
                          text
                          @click="dialog = false"
                      >
                        Nein
                      </v-btn>
                      <v-btn
                          color="green"
                          text
                          @click="deleteCustomer(selectedCustomer.id)"
                      >
                        Ja
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                </tbody>
              </template>
            </v-simple-table>
            <v-spacer/>
            <v-pagination
                color="#00B51A"
                v-model="page"
                :length="lastPage"
                @input="fetchCustomers"
            />
          </v-card>
        </v-col>
      </v-row>
      <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import customer from "@/api/requests/customer";
import Sortable from 'sortablejs';

export default {
  components: {SideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      customers: [],
      page: 1,
      lastPage: 1,
      searchQuery: '',
      error: null,
      selectedItem: null,
      selectedCustomer: null,
      dialog: null,
      headers:[
        {
          text: "",
          align: "left",
          sortable: false
        }
      ]

    }
  },
  async created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchCustomers();
    }
  },
  mounted() {
    let elm = document.getElementById("lbody");
    const _self = this;
    Sortable.create(elm, {
      async onEnd ({newIndex, oldIndex}) {
        const rowSelected = _self.customers.splice(oldIndex, 1)[0];
        _self.customers.splice(newIndex, 0, rowSelected);
        await _self.sendSort(newIndex)
      }
    });
  },
  methods: {
    selectItem(customer) {
      this.selectedItem = customer;
    },
    unSelectItem() {
      this.selectedItem = null;
    },
    selectionForDialog() {
      this.dialog = true;
      this.selectedCustomer = this.selectedItem;
    },
    newCustomer() {
      this.$router.push('/new-customer');
    },
    editCustomer(customer) {
      this.$router.push(`/edit-customer/${customer.id}`);
    },
    viewRecipients(customer) {
      this.$router.push(`/recipients/${customer.id}`);
    },
    newRecipient(customer) {
      if (customer != null) {
        this.$router.push(`/new-recipient/${customer.id}`);
      } else {
        this.$router.push(`/new-recipient/empty`);
      }
    },
    manageServices() {
        this.$router.push(`/all-services`);
    },
    fetchCustomers() {
      this.loading = true;
      customer.getAll(this.$store.state.token, this.page).then(response => {
        this.lastPage = response.meta.last_page
        this.customers = response.data.map(customers => ({
          id: customers.id ?? '',
          customerNo: customers.customerNo ?? '',
          name: customers.name ?? '',
        }));
      }).catch(e => {
        console.log(e.response);
      });
      this.loading = false;
    },
    deleteCustomer(customerID) {
      customer.delete(this.$store.state.token, customerID).then(response =>{
        console.log(response)
        this.dialog = false
        this.fetchCustomers()
      }).catch(e => {
        console.log(e.response);
      })

    },
    async sendSort(index){
      this.loading = true;
      let changeCustomer = this.customers[index].id ;
      let aboveCustomer = this.customers[index - 1] != null ? this.customers[index - 1].id : null;
      let belowCustomer = this.customers[index + 1] != null ? this.customers[index + 1].id : null;
      await customer.sortCustomer(this.$store.state.token, aboveCustomer ?? "",changeCustomer, belowCustomer ?? "").catch(e => {
        console.log(e.response);
      });
    },
    gotoInvoiceRun(){
      this.$router.push(`/invoice-run`);
    }
  }

}
</script>