import Repository from "@/api/repository";

export default {
    async getAll(token, page, filter) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const response = await Repository.get(`clubNewsBackOffice?paginate=25&page=${page}&filter=${filter}`, config);
        return response.data;
    },

    async saveClubNews(token, headline, content, clubID) {
        const clubData = {headline: headline, content: content, clubID: clubID};
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const response = await Repository.post(`clubNews`, clubData, config);
        return response.data;
    },
    async getAllClubsRaw(){
        const response = await Repository.get(`clubs`);
        return response.data;
    },

    async deleteClubNews(token, id){
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const response = await Repository.delete(`clubNews/${id}`, config);
        return response.data;
    },

    async getOneClubNews(token, id){
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const  response = await Repository.get(`one-club-news/${id}`,config)
        return response.data
    },
    async getOneAdminNews(token, id){
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const  response = await Repository.get(`one-admin-news/${id}`,config)
        return response.data
    },

    async updateClubNews(token, id, headline, content){
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const newsData = {headline: headline, content: content}
        const response = await Repository.put(`clubNews/${id}`, newsData, config)
        return response.data
    },
    async updateAdminNews(token, id, headline, content){
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const newsData = {headline: headline, content: content}
        const response = await Repository.put(`update-admin-news/${id}`, newsData, config)
        return response.data
    },

    async deleteAdminNews(token, id){
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const response = await Repository.delete(`adminNews/${id}`, config);
        return response.data;
    },

    async uploadImage(logo, fileName, url) {
        var arr = logo.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        var file = new File([u8arr], fileName, {type: mime});

        var formData = new FormData();
        formData.append('files[]', file);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const response = await Repository.post(url, formData, config);
        return response.data.data;
    }
}