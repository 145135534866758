import Repository from "../repository";

export default {
    async getAll(token, page) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        try {
            let response = null;
            if(page !== null){
                response = await Repository.get(`service?paginate=25&page=${page}`, config);
            }else{
                response = await Repository.get(`service`, config);
            }
            return response.data
        } catch (e) {
            console.log(e);
        }
    },

    async getById(token, id) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        try {
            const response = await Repository.get(`service/${id}`, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    async save(token, id, name, price) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const serviceData = {
            name: name,
            price: price,
        };
        try {
            const response = await Repository.post(`service`, serviceData, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    async update(token, id, name, price) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const serviceData = {
            name: name,
            price: price,
        };
        const response = await Repository.put(`service/${id}`, serviceData, config);
        return response.data;
    },

    async delete(token, id) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        try {
            const response = await Repository.delete(`service/${id}`, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    async sort(token, aboveId, changeId, belowId) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const data = {
            id_above: aboveId,
            id_change: changeId,
            id_below: belowId,
        };
        try {
            const response = await Repository.post(`sortService`, data, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
}