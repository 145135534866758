<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A"
    >
      <div class="d-flex align-center">
          <router-link to="/dashboard">
            <v-img
                :src="require('../assets/logo_club4ever.svg')"
                class="ma-0"
                contain
                max-height="50"
                max-width="190"
            />
          </router-link>
      </div>
      <span class="font-weight-medium" style="color: white;font-size: x-large">
            Profil
              </span>
      <v-spacer/>
      <div class="d-flex align-center mr-5">
        <v-btn style="background:white;color:#00B51A"
               height="45"
               elevation="2"
               v-on:click="changeClub">
          <h3 class="font-weight-medium text-none">
            Vereinszugehörigkeit ändern
          </h3>
        </v-btn>
      </div>
    </v-app-bar>
    <v-layout justify-space-between column>
    <v-row class="pa-0 d-flex align-start ml-2">
      <SideMenu/>
      <EditProfileContainer/>
      </v-row>
    <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import SideMenu from "@/components/SideMenu";
import EditProfileContainer from "@/components/EditProfileContainer";
import CustomFooter from "@/components/CustomFooter";

export default {
  methods: {
    goBack(){
      this.$router.go(-1);
    },
    changeClub(){
      this.$router.push('/change-club');
    }
  },
  components: {SideMenu, CustomFooter, EditProfileContainer},
}
</script>