<template>
  <div class="cursor-pointer flex">
    <input type="file" ref="file" :multiple="multiple" class="hidden pointer-events-none" :accept="accept" @change="chosenUpload" />
  </div>
</template>

<script>
export default {
  name: 'BaseFileUploader',
  props: {
    accept: { default: 'image/png' },
    multiple: { default: false },
    maxfiles: { default: false },
  },
  methods: {
    openUpload() {
      this.$refs.file.click();
    },
    clearTitle(){
      this.$refs.file.value = null;
    },
    chosenUpload() {
      const files = this.$refs.file.files;
      const reader = new FileReader();
      const splitAccept = this.accept.split(',').map(accept => accept.trim());
      const isValid = [];
      const names = [];

      for (let y = 0; y < files.length; y++) {
        names.push(files[y].name);
        for (let i = 0; i < splitAccept.length; i++) {
          if (files[y].type.startsWith(splitAccept[i])) {
            isValid[y] = true;
          }
        }
      }

      if (files.length !== isValid.length) {
        this.$swal('Warnung', 'Die Datei hat nicht das richtige Format und wurde nicht hochgeladen', 'warning');
        return false;
      }

      if (this.maxfiles && files.length > this.maxfiles) {
        this.$swal('Warnung', 'Es dürfen maximal ' + this.maxfiles + ' Dateien hochgeladen werden', 'warning');
        return false;
      }

      reader.onload = e => {
        this.$emit('input', e.target.result);
        this.$emit('update:filename', names.join('<br>'));
      };

      if(files.length>0){
        reader.readAsDataURL(files[0]);
      }else{
        this.$emit('input', null);
      }


    },
  },
};
</script>