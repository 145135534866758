let exports = {};

exports.resizeImage = function(datas, maxSize){
    return new Promise(function(resolve){
        let img = document.createElement('img');

        img.onload = function() {        
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            let width = img.width;
            let height = img.height;
            if (width > height) {
                if (width > maxSize) {
                    height *= maxSize / width;
                    width = maxSize;
                }
            } else {
                if (height > maxSize) {
                    width *= maxSize / height;
                    height = maxSize;
                }
            }
            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(this, 0, 0, width, height);

            let dataURI = canvas.toDataURL("image/jpeg", 0.8);

            resolve(dataURI);
        };

        img.src = datas;
    })
}

export default exports;
