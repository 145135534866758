<template>
  <v-flex>
    <v-container fluid class="d-flex pb-0 align-start fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A"
      >
        <div class="d-flex align-center">
          <v-row>
            <v-icon size="30" class="ml-1" @click="goBack" style="color: white"
            >mdi-arrow-left
            </v-icon>
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </v-row>


        </div>
        <span class="font-weight-medium" style="color: white;font-size: x-large">
            Neuer Club
              </span>
        <v-spacer/>

      </v-app-bar>
      <v-layout justify-space-between column>
        <v-row class="mt-16 ml-14">
          <SideMenu/>
          <v-col>
            <h1 v-if="loading">
              <v-progress-circular class="mt-5"
                                   indeterminate
                                   color="#00B51A"
              ></v-progress-circular>
            </h1>
            <v-card class="mt-2 ml-2" width="1000" elevation="1" v-else>
              <v-col>
             <span class="font-weight-regular ml-3">
                  Logo
                </span>
                <v-row>
                  <v-avatar width="80" height="80" class="mt-4 mb-10 ml-5 mr-4" color="#ededed">
                    <v-img v-if="base64ClubLogo!=null" v-bind:src="(this.base64ClubLogo)"/>
                    <v-icon v-else large
                            color="#00B51A">mdi-soccer
                    </v-icon>
                  </v-avatar>

                  <FileUploader class="mt-10 ml-3"
                                @input="onLoadFile"
                                ref="upload"
                                title="Upload"
                                :accept="accept"
                                :multiple="multiple"
                                :maxfiles="1"
                                @update:filename="
			uploadLogoName = $event;
			$emit('update:filename', uploadLogoName);
		"
                  >

                  </FileUploader>
                </v-row>

                <span class="font-weight-regular ml-2">
                  Hintergrundbild
                </span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="#00B51A"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>Das Hintergrundbild wird Clubmitgliedern nach dem Einloggen in der App angezeigt</span>
                </v-tooltip>
                <v-row>
                  <v-avatar width="80" height="150" tile class="mt-4 mb-10 ml-5 mr-4" color="#ededed">
                    <v-img v-if="base64ClubBackground!=null" min-width="80"
                           max-width="80" min-height="150" v-bind:src="(this.base64ClubBackground)"/>

                    <div v-else class="text-center">
                      <v-icon large color="#00B51A">mdi-image-outline
                      </v-icon>
                    </div>
                  </v-avatar>


                  <FileUploader class="mt-16 ml-3 align-center"
                                @input="onLoadClubBackground"
                                ref="upload"
                                title="Upload"
                                :accept="accept"
                                :multiple="multiple"
                                :maxfiles="1"
                                @update:filename="
			uploadClubBackgroundName = $event;
			$emit('update:filename', uploadClubBackgroundName);
		"
                  >

                  </FileUploader>
                </v-row>

                <v-form ref="form" class="mx-2" lazy-validation>

                  <v-row>
                    <v-col>
     <span class="font-weight-regular">
                  Club Name
                </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="clubName"
                          :rules="[v => !!v || 'Bitte gib einen Namen für deinen Club ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
      <span class="font-weight-regular">
               Club E-Mail-Adresse
              </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="clubEmail"
                          :rules="emailRules"
                          required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>


                  <v-row>
                    <v-col>
                      <span class="font-weight-regular">
                      Club Website
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="clubWebsite"
                          :rules="[v => !!v || 'Bitte gib eine Website für deinen Club ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <span class="font-weight-regular">
                      Club Adresse
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="clubAddress"
                          :rules="[v => !!v || 'Bitte gib eine Anschift für deinen Club ein']"
                          required
                      >
                      </v-text-field>

                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                       <span class="font-weight-regular">
                      Club Postleitzahl
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="clubAddressZip"
                          :rules="[v => !!v || 'Bitte gib eine Postleitzahl für deinen Club ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                       <span class="font-weight-regular">
                      Club Ort
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="clubAddressCity"
                          :rules="[v => !!v || 'Bitte gib einen Ort für deinen Club ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                       <span class="font-weight-regular">
                      Rechnungsempfänger Vorname
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="invoiceRecipientFirstName"
                          :rules="[v => !!v || 'Bitte gib einen Vornamen ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                       <span class="font-weight-regular">
                       Rechnungsempfänger Nachname
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="invoiceRecipientLastName"
                          :rules="[v => !!v || 'Bitte gib einen Nachnamen ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                       <span class="font-weight-regular">
                      Rechnungsempfänger E-Mail-Adresse
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="invoiceRecipientEmail"
                          :rules="emailRules"
                          required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                       <span class="font-weight-regular">
               Land
              </span>
                      <v-autocomplete
                          v-model="selectedCountry"
                          :items="countries"
                          @change="countrySelectionChanged"
                          no-data-text="Keine Länder gefunden"
                          outlined
                          color="blue-grey lighten-2"
                          item-text="name"
                          item-value="id"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                <span class="font-weight-regular">
                  Bundesland
                </span>
                      <v-autocomplete
                          v-model="selectedState"
                          :items="states"
                          no-data-text="Keine Bundeländer gefunden"
                          outlined
                          @change="stateSelectionChanged"
                          color="blue-grey lighten-2"
                          item-text="name"
                          item-value="id"
                      >
                      </v-autocomplete>

                    </v-col>
                    <v-col>
                    <span class="font-weight-regular">
                      Stadt
                    </span>
                      <v-autocomplete
                          v-model="selectedCity"
                          :items="cities"
                          no-data-text="Keine Städte gefunden"
                          outlined
                          color="blue-grey lighten-2"
                          item-text="name"
                          item-value="id"
                      >
                      </v-autocomplete>

                    </v-col>
                  </v-row>


                  <div class="text-center">
                    <v-btn class="mt-10 mb-5" style="background:#00B51A;color:white"
                           height="45"
                           width="200"
                           elevation="2"
                           :loading="saving"
                           v-on:click="saveClub">
                      <h3 class="font-weight-medium text-capitalize">
                        Speichern
                      </h3>
                    </v-btn>
                  </div>

                  <v-snackbar
                      v-model="snackbar"
                  >
                    {{ snackbarText }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                          color="white"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>

                </v-form>
              </v-col>
            </v-card>

          </v-col>

        </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>

</template>
<script>
import city from "@/api/requests/city";
import FileUploader from "@/components/FileUploader";
import club from "@/api/requests/club";
import imageUtils from "@/util/image_utils";
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import country from "@/api/requests/country";

export default {
  components: {FileUploader, SideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      saving: false,
      cities: [],
      selectedCity: null,
      selectedCountry: null,
      countries: [],
      states: [],
      selectedState: null,
      error: null,
      clubName: '',
      clubEmail: '',
      clubWebsite: '',
      clubAddress: '',
      clubAddressZip: '',
      clubAddressCity: '',
      invoiceRecipientFirstName: '',
      invoiceRecipientLastName: '',
      invoiceRecipientEmail: '',
      clubID: '',
      emailRules: [
        v => !!v || 'Bitte gib eine E-Mail-Adresse für deinen Club ein',
        v => /.+@.+\..+/.test(v) || 'E-Mail-Adresse muss valide sein',
      ],
      uploadLogoName: null,
      base64ClubLogo: null,
      uploadClubBackgroundName: null,
      base64ClubBackground: null,
      snackbarText: '',
      snackbar: false
    }
  },
  props: {
    document: {},
    instantUpload: {
      default: false,
    },
    accept: {default: 'image/png, image/jpeg'},
    multiple: {default: false},
    maxfiles: {default: false},
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchCountries()
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onLoadFile($event) {
      this.dialog = false;
      this.base64ClubLogo = $event;
      this.$emit('input', $event);
    },
    onLoadClubBackground($event) {
      this.dialog = false;
      this.base64ClubBackground = $event;
      this.$emit('input', $event);
    },
    fetchCities() {
      this.loading = true
      city.getAll().then(response => {
        this.cities = [];
        this.cities = response.data.map(city => ({
          name: `${city.name} (${city.state})`,
          id: city.id,
          state: city.state,
        }));
        this.loading = false;
      });
    },
    countrySelectionChanged() {
      this.selectedState = null;
      this.selectedCity = null;
      this.cities = [];
      this.states = [];
      this.fetchStatesForCountry()
    },
    stateSelectionChanged() {
      this.selectedCity = null;
      this.cities = [];
      this.fetchCitiesForState()
    },
    fetchStatesForCountry() {
      city.getStatesForCountry(this.selectedCountry).then(response => {
        this.states = [];
        this.states = response.data.map(state => ({
          name: state
        }));
      })
    },
    fetchCitiesForState() {
      country.getCitiesForState(this.selectedState).then(response => {
        this.cities = [];
        this.cities = response.data.map(city => ({
          name: `${city.name} (${city.state})`,
          id: city.id,
        }));
      })
    },
    fetchCountries() {
      this.loading = true
      country.getAll().then(response => {
        this.countries = [];
        this.countries = response.data.map(country => ({
          name: country.name,
          id: country.id,
          code: country.code,
        }));
        this.loading = false;
      });
    },
    async saveClub() {
      if (this.$refs.form.validate()) {
        if (this.selectedCountry != null) {
          if(this.selectedState != null){
            if (this.selectedCity != null) {
              if (this.base64ClubLogo != null) {
                this.saving = true;
                try {
                  let response = await club.saveClub(this.clubName, this.clubEmail, this.clubWebsite, this.selectedCity, this.clubAddress, this.clubAddressZip, this.clubAddressCity,
                    this.invoiceRecipientFirstName, this.invoiceRecipientLastName, this.invoiceRecipientEmail);
                  this.clubID = response.id;
                  let resizedLogo = await imageUtils.resizeImage(this.base64ClubLogo, 250);
                  try {
                    await club.uploadImage(resizedLogo, this.uploadLogoName, `club-image/upload/${this.clubID}`);
                    if (this.base64ClubBackground != null) {
                      let resizedBG = await imageUtils.resizeImage(this.base64ClubBackground, 1000);
                      try {
                        await club.uploadImage(resizedBG, this.uploadClubBackgroundName, `club-background-image/upload/${this.clubID}`)
                        this.snackbarText = 'Club wurde erfolgreich angelegt';
                        this.saving = false;
                        this.snackbar = true;
                        this.$router.push('/all-clubs');
                      } catch(e) {
                        console.log(e.response);
                        this.snackbarText = 'Das Hintergrundbild konnte nicht hochgeladen werden';
                        this.saving = false;
                        this.snackbar = true;
                      }
                    } else {
                      this.snackbarText = 'Club wurde erfolgreich angelegt';
                      this.saving = false;
                      this.snackbar = true;
                      this.$router.push('/all-clubs');
                    }
                  } catch(e) {
                    console.log(e.response);
                    this.snackbarText = 'Das Logo konnte nicht hochgeladen werden';
                    this.saving = false;
                    this.snackbar = true;
                  }
                } catch (e) {
                  console.log(e);
                  this.snackbar = true;
                  this.saving = false;
                  this.snackbarText = 'Club konnte nicht angelegt werden';
                }
              } else {
                this.snackbar = true;
                this.snackbarText = 'Bitte füge ein Logo hinzu';
              }
            } else {
              this.snackbar = true;
              this.snackbarText = 'Bitte wähle deine Stadt aus';
            }
          }else {
            this.snackbar = true;
            this.snackbarText = 'Bitte wähle dein Bundesland aus';
          }
        } else {
          this.snackbar = true;
          this.snackbarText = 'Bitte wähle dein Land aus';
        }
      }

    }
  }
}
</script>
