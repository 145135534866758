<template>
  <v-flex class="background-svg">
    <div class="d-flex align-end">
      <v-col class="mt-15 align-end" cols="12">
        <v-form ref="form" class="mx-2 d-flex flex-column align-center mb-6" lazy-validation>
          <div class="d-flex">
            <v-img
                :src="require('../assets/logo_club4ever.svg')"
                class="my-3"
                contain
                max-height="100"
            />
          </div>

          <v-card class="ma-5" color="white" width="500">
            <v-container>
              <v-col>
                <div class="justify-start">
                 <span class="font-weight-regular">
               Neues Passwort vergeben
              </span>
                </div>

                <v-text-field class="mt-2"
                              color="#00B51A"
                              outlined
                              type="password"
                              v-model="password"
                              :rules="passwordRules"
                >
                </v-text-field>

                <div class="text-center">
                  <v-btn class="mt-5 w-full" style="background:#00B51A;color:white"
                         height="45"
                         elevation="2"
                         v-on:click="resetPassword">
                    <h3 class="font-weight-medium text-none">
                      Speichern
                    </h3>
                  </v-btn>
                </div>

                <v-snackbar
                    v-model="snackbar"
                >
                  {{ snackbarText }}

                  <template v-slot:action="{ attrs }">
                    <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                      OK
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-col>
            </v-container>
          </v-card>
        </v-form>
      </v-col>
    </div>
  </v-flex>
</template>

<style>
.background-svg {
  background-image: url(../assets/industrial.jpeg);
  background-size: cover;
  background-position: center;
}
</style>
<script>
import auth from "@/api/requests/auth";

export default {
  data() {
    return {
      password: '',
      snackbar: false,
      snackbarText: '',
      passwordRules: [
        v => !!v || 'Bitte gib ein neues Passwort ein',
        v => v.length >= 8 || 'Mind. 8 Zeichen lang mit Groß und Kleinbuchstaben',
        v => /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}$/.test(v) || 'Mind. 8 Zeichen lang mit Groß und Kleinbuchstaben',
      ],
    }
  },
  methods: {
    resetPassword() {
      if (this.$refs.form.validate()) {
        auth.resetPassword(this.$route.params.user_ID, this.$route.params.token, this.password).then(() => {
          this.snackbarText = `Passwort erfolgreich geändert`
          this.snackbar = true;
          this.$refs.form.resetValidation();
          this.password = '';
        }).catch(e => {
          if (e.response.status === 404) {
            this.snackbar = true;
            this.snackbarText = e.response.data.message;
          } else {
            this.snackbar = true;
            this.snackbarText = 'Es ist ein Fehler aufgetreten'
            console.log(e.response);
          }

        });
      }
    },
  }
}
</script>