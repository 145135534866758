var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"mt-15",staticStyle:{"position":"fixed","background-color":"#EDEDED"},attrs:{"permanent":"","absolute":"","left":"","expand-on-hover":""}},[_c('div',{staticClass:"mb-15"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"mt-2 mb-2",attrs:{"link":""},on:{"mouseover":function($event){return _vm.selectItem(0)},"mouseleave":_vm.unselectItem,"click":_vm.showDashboard}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"30","disabled":_vm.selectedItem !== 0,"color":"#00B51A"}},[_vm._v("mdi-chart-areaspline-variant ")])],1),_c('v-list-item-title',{class:{ 'drawer-hover-override': hover },staticStyle:{"color":"#999999"}},[_vm._v("Dashboard ")])],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"mt-2 mb-2",attrs:{"link":""},on:{"mouseover":function($event){return _vm.selectItem(1)},"mouseleave":_vm.unselectItem,"click":_vm.showClubNews}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"30","disabled":_vm.selectedItem !== 1,"color":"#00B51A"}},[_vm._v("mdi-text-box ")])],1),_c('v-list-item-title',{class:{ 'drawer-hover-override': hover },staticStyle:{"color":"#999999"}},[_vm._v("Club-News ")])],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"mt-2 mb-2",attrs:{"link":""},on:{"mouseover":function($event){return _vm.selectItem(2)},"mouseleave":_vm.unselectItem,"click":_vm.showClubEvents}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"30","disabled":_vm.selectedItem !== 2,"color":"#00B51A"}},[_vm._v("mdi-calendar ")])],1),_c('v-list-item-title',{class:{ 'drawer-hover-override': hover },staticStyle:{"color":"#999999"}},[_vm._v("Alle Veranstaltungen ")])],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"mt-2 mb-2",attrs:{"link":""},on:{"mouseover":function($event){return _vm.selectItem(3)},"mouseleave":_vm.unselectItem,"click":_vm.showClubs}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"30","disabled":_vm.selectedItem !== 3,"color":"#00B51A"}},[_vm._v("mdi-soccer ")])],1),_c('v-list-item-title',{class:{ 'drawer-hover-override': hover },staticStyle:{"color":"#999999"}},[_vm._v("Alle Clubs ")])],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"mt-2 mb-2",attrs:{"link":""},on:{"mouseover":function($event){return _vm.selectItem(4)},"mouseleave":_vm.unselectItem,"click":_vm.showUsers}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"30","disabled":_vm.selectedItem !== 4,"color":"#00B51A"}},[_vm._v("mdi-account-multiple ")])],1),_c('v-list-item-title',{class:{ 'drawer-hover-override': hover },staticStyle:{"color":"#999999"}},[_vm._v("Alle Nutzer ")])],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"mt-2 mb-2",attrs:{"link":""},on:{"mouseover":function($event){return _vm.selectItem(5)},"mouseleave":_vm.unselectItem,"click":_vm.showAdditionalDeals}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"30","disabled":_vm.selectedItem !== 5,"color":"#00B51A"}},[_vm._v("mdi-tag-text ")])],1),_c('v-list-item-title',{class:{ 'drawer-hover-override': hover },staticStyle:{"color":"#999999"}},[_vm._v("Zusatz-Angebote ")])],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"mt-2 mb-2",attrs:{"link":""},on:{"mouseover":function($event){return _vm.selectItem(6)},"mouseleave":_vm.unselectItem,"click":_vm.showCurrentDeals}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"30","disabled":_vm.selectedItem !== 6,"color":"#00B51A"}},[_vm._v("mdi-tag-arrow-up ")])],1),_c('v-list-item-title',{class:{ 'drawer-hover-override': hover },staticStyle:{"color":"#999999"}},[_vm._v("Alle aktuellen Angebote ")])],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"mt-2 mb-2",attrs:{"link":""},on:{"mouseover":function($event){return _vm.selectItem(7)},"mouseleave":_vm.unselectItem,"click":_vm.showDeals}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"30","disabled":_vm.selectedItem !== 7,"color":"#00B51A"}},[_vm._v("mdi-tag ")])],1),_c('v-list-item-title',{class:{ 'drawer-hover-override': hover },staticStyle:{"color":"#999999"}},[_vm._v("Alle verkauften Deals ")])],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"mt-2 mb-2",attrs:{"link":""},on:{"mouseover":function($event){return _vm.selectItem(8)},"mouseleave":_vm.unselectItem,"click":_vm.changePassword}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"30","disabled":_vm.selectedItem !== 8,"color":"#00B51A"}},[_vm._v("mdi-account-box ")])],1),_c('v-list-item-title',{class:{ 'drawer-hover-override': hover },staticStyle:{"color":"#999999"}},[_vm._v("Profil bearbeiten ")])],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"mt-2 mb-2",attrs:{"link":""},on:{"mouseover":function($event){return _vm.selectItem(9)},"mouseleave":_vm.unselectItem,"click":_vm.showCustomers}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"30","disabled":_vm.selectedItem !== 9,"color":"#00B51A"}},[_vm._v("mdi-tshirt-crew ")])],1),_c('v-list-item-title',{class:{ 'drawer-hover-override': hover },staticStyle:{"color":"#999999"}},[_vm._v("TW Abrechnung ")])],1)]}}])}),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"mt-2 mb-2",attrs:{"link":""},on:{"mouseover":function($event){return _vm.selectItem(10)},"mouseleave":_vm.unselectItem,"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"30","disabled":_vm.selectedItem !== 10,"color":"#00B51A"}},[_vm._v("mdi-logout ")])],1),_c('v-list-item-title',{class:{'drawer-hover-override': hover},staticStyle:{"color":"#999999"}},[_vm._v(" Ausloggen ")])],1)]}}])}),_c('v-divider')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }