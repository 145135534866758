<template>
  <v-form ref="form" class="mx-2 d-flex flex-column align-center mb-6" lazy-validation>
    <v-card class="ml-4 mt-8" color="white" width="490">
      <v-container>
        <v-col>
          <div class="justify-start">
            <v-container v-if="user">
              <v-row no-gutters>
                <v-row cols="4">
                  <v-avatar size="120">
                    <v-img v-if="profileImage!=null" v-bind:src="(this.profileImage)"/>
                    <v-img v-else-if="user.profile_image_url != null" v-bind:src="(user.profile_image_url)"/>
                    <v-icon v-else large style="background: #ededed;"
                            color="#00B51A">mdi-account
                    </v-icon>
                  </v-avatar>
                  <FileUploader class="mt-10 ml-3"
                                @input="onLoadFile"
                                ref="upload"
                                title="Upload"
                                :accept="accept"
                                :multiple="multiple"
                                :maxfiles="1"
                                @update:filename="
                                uploadImageName = $event;
                                $emit('update:filename', uploadImageName);">
                  </FileUploader>
                </v-row>

              </v-row>

            </v-container>
            <v-col class="mb-10 mt-6">
              <div display="flex" style="
                          height: 100%;
                          display: flex;
                          justify-content: space-evenly;
                          flex-direction: column;
                      ">
                      <span class="font-weight-regular">
                        {{ user.first_name }} {{ user.last_name }}
                      </span>
                <span class="font-weight-regular pt-2">
                        {{ user.email }}
                      </span>
                <span v-if="user.club!=null" class="font-weight-regular pt-2">
                        {{ user.club.name }}
                      </span>
              </div>
            </v-col>
            <span class="font-weight-regular">
                Altes Passwort
              </span>
          </div>

          <v-text-field class="mt-2"
                        color="#00B51A"
                        type="password"
                        outlined
                        v-model="oldPassword"
                        :rules="[v => !!v || 'Bitte gib deine altes Passwort ein']"
          >
          </v-text-field>
          <span class="font-weight-regular">
               Neues Passwort
              </span>
          <v-text-field class="mt-2"
                        color="#00B51A"
                        type="password"
                        outlined
                        v-model="newPassword"
                        :rules="passwordRules"
          >
          </v-text-field>
          <span class="font-weight-regular">
               Passwort wiederholen
              </span>
          <v-text-field class="mt-2"
                        color="#00B51A"
                        type="password"
                        outlined
                        v-model="repeatNewPassword"
                        :rules="passwordRules"
          >
          </v-text-field>
          <div class="text-center">
            <v-btn class="mt-5 w-full" style="background:#00B51A;color:white"
                   height="45"
                   width="200"
                   elevation="2"
                   :loading="saving"
                   v-on:click="changePassword">
              <h3 class="font-weight-medium text-capitalize">
                Speichern
              </h3>
            </v-btn>
          </div>

          <v-snackbar
              v-model="snackbar"
          >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                OK
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import auth from "@/api/requests/auth";
import imageUtils from "@/util/image_utils";
import me from "@/api/requests/me";
import FileUploader from "@/components/FileUploader";
import club from "@/api/requests/club";

export default {
  components: {FileUploader},
  data() {
    return {
      user: null,
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
      error: null,
      uploadImageName: null,
      profileImage: null,
      snackbar: false,
      snackbarText: '',
      saving: false,
      passwordRules: [
        v => !!v || 'Bitte gib dein Passwort ein',
        v => v.length >= 8 || 'Mind. 8 Zeichen lang mit Groß- und Kleinbuchstaben',
        v => /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}$/.test(v) || 'Mind. 8 Zeichen lang mit Groß und Kleinbuchstaben',
      ],
    }
  },
  props: {
    document: {},
    instantUpload: {
      default: false,
    },
    accept: {default: 'image/png, image/jpeg'},
    multiple: {default: false},
    maxfiles: {default: false},
  },
  created() {
    this.fetchMe();
  },
  methods: {
    onLoadFile($event) {
      this.dialog = false;
      this.profileImage = $event;
      this.$emit('input', $event);
    },
    async saveImage() {
      let resizedImage = await imageUtils.resizeImage(this.profileImage, 250);
      club.uploadImage(resizedImage, this.uploadLogoName, `user-image/upload/${this.user.id}`).then(() => {
        this.saving = false
        this.snackbar = true
        this.snackbarText = 'Änderungen gespeichert';
        this.oldPassword = '';
        this.newPassword = '';
        this.repeatNewPassword = '';
        this.$refs.form.resetValidation();
      }).catch(e => {
        console.log(e)
      });

    },
    async changePassword() {

      if (this.oldPassword === '' && this.newPassword === '' && this.repeatNewPassword === '' && this.profileImage != null) {
        this.saving = true;
        await this.saveImage();
      } else {
        if (this.$refs.form.validate()) {
          if (this.newPassword === this.repeatNewPassword) {
            this.saving = true
            auth.changePassword(this.oldPassword, this.newPassword, this.$store.state.token).then(response => {
              if (this.profileImage != null) {
                this.saveImage();
              } else {
                this.snackbar = true
                this.snackbarText = 'Änderungen gespeichert';
                this.$store.commit('saveToken', response.token);
                this.oldPassword = '';
                this.newPassword = '';
                this.repeatNewPassword = '';
                this.$refs.form.resetValidation();
              }
            }).catch(e => {
              console.log(e.response);
              if (e.response.status === 401) {
                this.snackbar = true
                this.snackbarText = e.response.data.message;
              }
            });
            this.saving = false;
          } else {
            this.snackbar = true;
            this.snackbarText = 'Die neuen Passwörter stimmen nicht überein';
          }
        }

      }
    },
    fetchMe() {
      this.loading = true
      me.fetchMe(this.$store.state.token).then(response => {
        if (response.data) {
          this.user = response.data;
        }
      }).catch(e => {
        if (e.response.status === 401) {
          this.$router.push('/login');
        }
      });
    },
  }
}
</script>