<template>
  <v-flex class="background-svg">
    <div class="d-flex align-end">
      <v-col class="mt-15 align-end" cols="12">
        <v-form ref="form" class="mx-2 d-flex flex-column align-center mb-6" lazy-validation>
          <div class="d-flex">
            <v-img
                :src="require('../assets/logo_club4ever.svg')"
                class="my-3"
                contain
                max-height="100"
            />
          </div>

          <v-card class="ma-5" color="white" width="500">
            <v-container>
              <v-col>
                <div class="justify-start">
                 <span class="font-weight-regular">
               E-Mail-Adresse
              </span>
                </div>

                <v-text-field class="mt-2"
                              color="#00B51A"
                              outlined
                              v-model="email"
                              :rules="[v => !!v || 'Bitte gib deine E-Mail-Adresse ein']"
                >
                </v-text-field>

                <div class="text-center">
                  <v-btn class="mt-5 w-full" style="background:#00B51A;color:white"
                         height="45"
                         elevation="2"
                         v-on:click="resetPassword">
                    <h3 class="font-weight-medium text-none">
                      Passwort zurücksetzen
                    </h3>
                  </v-btn>
                </div>

                <v-snackbar
                    v-model="snackbar"
                >
                  {{ snackbarText }}

                  <template v-slot:action="{ attrs }">
                    <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                      OK
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-col>
            </v-container>
          </v-card>
        </v-form>
      </v-col>
    </div>
  </v-flex>
</template>

<style>
.background-svg {
  background-image: url(../assets/industrial.jpeg);
  background-size: cover;
  background-position: center;
}
</style>
<script>
import auth from "@/api/requests/auth";

export default {
  data() {
    return {
      email: '',
      snackbar: false,
      snackbarText: ''
    }
  },
  methods: {
    resetPassword() {
      if (this.$refs.form.validate()) {
        auth.forgotPassword(this.email).then(() => {
          this.snackbarText = `Eine E-Mail wurde an ${this.email} geschickt.`
          this.snackbar = true;
          this.$refs.form.resetValidation();
          this.email = '';
          // this.$router.push('/login');
        }).catch(e => {
          if(e.response.status===404){
            this.snackbar = true;
            this.snackbarText = e.response.data.message;
          }else{
            this.snackbar = true;
            this.snackbarText = 'Es ist ein Fehler aufgetreten'
          }

        });
      }
    },
  }
}
</script>