import Repository from "../repository";

export default {
    async getAll(token, page, customerId) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        try {
            if(page !== null){
                const response = await Repository.get(`recipient?paginate=25&page=${page}&customerId=${customerId}`, config);
                return response.data
            }else{
                const response = await Repository.get(`recipient`, config);
                return response.data
            }
        } catch (e) {
            console.log(e);
        }
    },

    async getAllNoPagination(token) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        try {
            const response = await Repository.get(`recipient`, config);
            return response.data
        } catch (e) {
            console.log(e);
        }
    },

    async getRecipientByID(token, recipientID) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        try {
            const response = await Repository.get(`recipient/${recipientID}`, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    async update(token, recipientId, name, email, address, zipcode, city, mandate) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const recipientData = {
            name: name,
            email: email,
            address: address,
            zipcode: zipcode,
            city: city,
            mandate: mandate
        };
        const response = await Repository.put(`recipient/${recipientId}`, recipientData, config);
        return response.data;
    },

    async saveRecipient(token, name, twCustomerId, email, address, zipcode, city, mandate) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const recipientData = {
            name: name,
            twCustomerId: twCustomerId,
            email: email,
            address: address,
            zipcode: zipcode,
            city: city,
            mandate: mandate
        };
        try {
            const response = await Repository.post(`recipient`, recipientData, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    async delete(token, recipientID) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        try {
            const response = await Repository.delete(`recipient/${recipientID}`, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
}