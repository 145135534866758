import Vue from 'vue'
import Router from 'vue-router'
import AllDealsScreen from "@/views/AllDealsScreen";
import AllClubsScreen from "@/views/AllClubsScreen";
import NewClubScreen from "@/views/NewClubScreen";
import Login from "@/views/Login";
import AllUsersScreen from "@/views/AllUsersScreen";
import NewUserScreen from "@/views/NewUserScreen";
import EditDealScreen from "@/views/EditDealScreen";
import ForgotPasswordScreen from "@/views/ForgotPasswordScreen";
import ResetPasswordSceen from "@/views/ResetPasswordSceen";
import ShowClubScreen from "@/views/ShowClubScreen";
import ClubAdminEditClubScreen from "@/views/ClubAdminEditClubScreen";
import AdminEditClubScreen from "@/views/AdminEditClubScreen";
import AdminProfileScreen from "@/views/AdminProfileScreen";
import ClubAdminProfileScreen from "@/views/ClubAdminProfileScreen";
import ClubAdminAllUsersScreen from "@/views/ClubAdminAllUsersScreen";
import ClubAdminDealsScreen from "@/views/ClubAdminDealsScreen";
import CurrentDealsScreen from "@/views/CurrentDealsScreen";
import AdditionalDealsScreen from "@/views/AdditionalDealsScreen";
import DashboardScreen from "@/views/DashboardScreen";
import ImprintScreen from "@/views/ImprintScreen";
import DataProtectionScreen from "@/views/DataProtectionScreen";
import ChangeClubScreen from "@/views/ChangeClubScreen";
import ClubAdminDashboardScreen from "@/views/ClubAdminDashboardScreen";
import ClubAdminNewUserScreen from "@/views/ClubAdminNewUserScreen";
import NewClubNewsScreen from "@/views/ClubNews/NewNews/NewClubNewsScreen";
import ClubAdminAdditionalDealsScreen from "@/views/ClubAdminAdditionalDealsScreen";
import ClubAdminCurrentDeals from "@/views/ClubAdminCurrentDeals";
import AllClubEvents from "@/views/ClubEvents/AllClubEvents";
import NewClubEventScreen from "@/views/ClubEvents/NewClubEventScreen";
import EditClubEventScreen from "@/views/ClubEvents/EditClubEventScreen";
import AdminAllClubEvents from "@/views/ClubEvents/AdminAllClubEvents";
import AllClubNews from "@/views/ClubNews/AllNews/AllClubNews";
import AdminNewClubNewsScreen from "@/views/ClubNews/NewNews/AdminNewClubNewsScreen";
import AdminAllClubNews from "@/views/ClubNews/AllNews/AdminAllClubNews";
import EditClubNewsScreen from "@/views/ClubNews/EditNews/EditClubNewsScreen";
import EditAdminNewsScreen from "@/views/ClubNews/EditNews/EditAdminNewsScreen";
import AllCustomersScreen from "@/views/AllCustomersScreen";
import RecipientsScreen from "@/views/RecipientsScreen";
import BillItemsScreen from "@/views/BillItemsScreen";
import InvoiceRunScreen from "@/views/InvoiceRunScreen";
import NewEditBillItemScreen from "@/views/NewEditBillItemScreen";
import NewEditCustomerScreen from "@/views/NewEditCustomerScreen";
import NewEditRecipientScreen from "@/views/NewEditRecipientScreen";
import AllServicesScreen from "@/views/AllServicesScreen";
import NewEditServiceScreen from "@/views/NewEditServiceScreen";

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: '/login'
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: DashboardScreen
        },
        {
            path: '/club-admin-dashboard',
            name: 'club-admin-dashboard',
            component: ClubAdminDashboardScreen
        },
        {
            path: '/new-club-news',
            name: '/new-club-news',
            component: NewClubNewsScreen
        },
        {
            path: '/all-club-news',
            name: '/all-club-news',
            component: AllClubNews
        },
        {
            path: '/admin-new-club-news',
            name: '/admin-new-club-news',
            component: AdminNewClubNewsScreen
        },
        {
            path: '/admin-all-club-news',
            name: '/admin-all-club-news',
            component: AdminAllClubNews
        },
        {
            path: '/all-club-events',
            name: '/all-club-events',
            component: AllClubEvents
        },
        {
            path: '/admin-all-club-events',
            name: '/admin-all-club-events',
            component: AdminAllClubEvents
        },
        {
            path: '/edit-club-event/:clubEventID',
            name: '/edit-club-event',
            component: EditClubEventScreen,
            props: {doReplicate: false}
        },
        {
          path: '/new-club-event',
          name: '/new-club-event',
          component: NewClubEventScreen
        },
        {
            path: '/replicate-club-event/:clubEventID',
            name: '/replicate-club-event',
            component: EditClubEventScreen,
            props: {doReplicate: true}

        },
        {
            path: '/imprint',
            name: 'imprint',
            component: ImprintScreen
        },
        {
            path: '/data-protection',
            name: 'data-protection',
            component: DataProtectionScreen
        },
        {
            path: '/all-deals',
            name: 'all-deals',
            component: AllDealsScreen
        },
        {
            path: '/current-deals',
            name: 'current-deals',
            component: CurrentDealsScreen
        },
        {
            path: '/club-admin-current-deals',
            name: 'club-admin-current-deals',
            component: ClubAdminCurrentDeals
        },
        {
            path: '/club-admin-additional-deals',
            name: 'club-admin-additional-deals',
            component: ClubAdminAdditionalDealsScreen
        },
        {
            path: '/additional-deals',
            name: 'additional-deals',
            component: AdditionalDealsScreen
        },
        {
            path: '/all-clubs',
            name: 'all-clubs',
            component: AllClubsScreen
        },
        {
            path: '/new-club',
            name: 'new-club',
            component: NewClubScreen
        },
        {
            path: '/admin-edit-club/:clubID',
            name: 'admin-edit-club',
            component: AdminEditClubScreen
        },
        {
            path: '/club-admin-edit-club/:clubID',
            name: 'club-admin-edit-club',
            component: ClubAdminEditClubScreen
        },
        {
            path: '/club-admin-deals',
            name: 'club-admin-deals',
            component: ClubAdminDealsScreen
        },
        {
            path: '/my-club/:clubID',
            name: 'my-club',
            component: ShowClubScreen
        },
        {
            path: '/club-admin-users',
            name: 'club-admin-users',
            component: ClubAdminAllUsersScreen
        },
        {
            path: '/club-admin-profile',
            name: 'club-admin-profile',
            component: ClubAdminProfileScreen
        },
        {
            path: '/all-users',
            name: 'all-users',
            component: AllUsersScreen
        },
        {
            path: '/new-user',
            name: 'new-user',
            component: NewUserScreen
        },
        {
            path: '/club-admin-new-user',
            name: 'club-admin-new-user',
            component: ClubAdminNewUserScreen
        },
        {
            path: '/edit-deal/:id',
            name: 'edit-deal',
            component: EditDealScreen
        },
        {
            path: '/admin-profile',
            name: 'admin-profile',
            component: AdminProfileScreen
        },
        {
            path: '/change-club',
            name: 'change-club',
            component: ChangeClubScreen
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: ForgotPasswordScreen
        },
        {
            path: '/reset-password/:user_ID/:token',
            name: 'reset-password',
            component: ResetPasswordSceen
        },
        {
            path: '/edit-club-news/:id',
            name: 'edit-club-news',
            component: EditClubNewsScreen
        },
        {
            path: '/edit-admin-news/:id',
            name: 'edit-admin-news',
            component: EditAdminNewsScreen
        },
        {
            path: '/recipients/:customerId',
            name: 'recipients',
            component: RecipientsScreen
        },
        {
            path: '/new-recipient/:customerId',
            name: 'new-recipient',
            component: NewEditRecipientScreen
        },
        {
            path: '/edit-recipient/:recipientId',
            name: 'edit-recipient',
            component: NewEditRecipientScreen
        },
        {
            path: '/bill-items/:recipientId',
            name: 'bill-items',
            component: BillItemsScreen
        },
        {
            path: '/invoice-run',
            name: 'invoice-run',
            component: InvoiceRunScreen
        },
        {
            path: '/all-customers',
            name: 'all-customers',
            component: AllCustomersScreen
        },
        {
            path: '/new-customer',
            name: 'new-customer',
            component: NewEditCustomerScreen
        },
        {
            path: '/edit-customer/:twCustomerId',
            name: 'edit-customer',
            component: NewEditCustomerScreen
        },
        {
            //empty: 0 = false | 1 = true
            path: '/new-bill-item/:recipientId/:empty',
            name: 'new-bill-item',
            component: NewEditBillItemScreen
        },
        {
            path: '/edit-bill-item/:recipientId/:billItemId',
            name: 'edit-bill-item',
            component: NewEditBillItemScreen
        },
        {
            path: '/all-services',
            name: 'all-services',
            component: AllServicesScreen
        },
        {
            path: '/new-service',
            name: 'new-service',
            component: NewEditServiceScreen
        },
        {
            path: '/edit-service/:serviceId',
            name: 'edit-service',
            component: NewEditServiceScreen
        },

        // {
        //     path: '/about',
        //     name: 'about',
        //     // route level code-splitting
        //     // this generates a separate chunk (about.[hash].js) for this route
        //     // which is lazy-loaded when the route is visited.
        //     component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
        // }
    ]
})