import Repository from "../repository";

export default {
    async getAll() {
        const response = await Repository.get(`cities`);
        return response.data;
    },
    async getClubsForCity(cityID){
        const response = await Repository.get(`clubs?city-id=${cityID}`);
        return response.data;
    },
    async getStatesForCountry(countryID){
        return await Repository.get(`states-for-country?country-id=${countryID}`);
    }
}