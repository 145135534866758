<template>
  <v-navigation-drawer class="mt-15"
                       style="position:fixed; background-color: #EDEDED;"
                       permanent
                       absolute
                       left
                       expand-on-hover
  >
    <div class="mb-15">
      <v-hover v-slot="{ hover }">
        <v-list-item
            class="mt-2 mb-2"
            @mouseover="selectItem(0)"
            @mouseleave="unselectItem"
            link
            @click="showDashboard"
        >
          <v-list-item-icon>
            <v-icon size="30" :disabled="selectedItem !== 0" color="#00B51A"
            >mdi-chart-areaspline-variant
            </v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
              :class="{ 'drawer-hover-override': hover }"
              style="color: #999999"
          >Dashboard
          </v-list-item-title
          >
        </v-list-item>
      </v-hover>
      <v-divider/>

      <v-hover v-slot="{ hover }">
        <v-list-item
            class="mt-2 mb-2"
            @mouseover="selectItem(1)"
            @mouseleave="unselectItem"
            link
            @click="showClubNews"
        >
          <v-list-item-icon>
            <v-icon size="30" :disabled="selectedItem !== 1" color="#00B51A"
            >mdi-text-box

            </v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
              :class="{ 'drawer-hover-override': hover }"
              style="color: #999999"
          >Club-News
          </v-list-item-title
          >
        </v-list-item>
      </v-hover>

      <v-divider/>

      <v-hover v-slot="{ hover }">
        <v-list-item
            class="mt-2 mb-2"
            @mouseover="selectItem(2)"
            @mouseleave="unselectItem"
            link
            @click="showClubEvents"
        >
          <v-list-item-icon>
            <v-icon size="30" :disabled="selectedItem !== 2" color="#00B51A"
            >mdi-calendar

            </v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
              :class="{ 'drawer-hover-override': hover }"
              style="color: #999999"
          >Alle Veranstaltungen
          </v-list-item-title
          >
        </v-list-item>
      </v-hover>

      <v-divider/>

      <v-hover v-slot="{ hover }">
        <v-list-item
            class="mt-2 mb-2"
            @mouseover="selectItem(3)"
            @mouseleave="unselectItem"
            link
            @click="showClubs"
        >
          <v-list-item-icon>
            <v-icon size="30" :disabled="selectedItem !== 3" color="#00B51A"
            >mdi-soccer
            </v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
              :class="{ 'drawer-hover-override': hover }"
              style="color: #999999"
          >Alle Clubs
          </v-list-item-title
          >
        </v-list-item>
      </v-hover>
      <v-divider/>
      <v-hover v-slot="{ hover }">
        <v-list-item
            class="mt-2 mb-2"
            @mouseover="selectItem(4)"
            @mouseleave="unselectItem"
            link
            @click="showUsers"
        >
          <v-list-item-icon>
            <v-icon size="30" :disabled="selectedItem !== 4" color="#00B51A"
            >mdi-account-multiple
            </v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
              :class="{ 'drawer-hover-override': hover }"
              style="color: #999999"
          >Alle Nutzer
          </v-list-item-title
          >
        </v-list-item>
      </v-hover>

      <v-divider/>

      <v-hover v-slot="{ hover }">
        <v-list-item
            class="mt-2 mb-2"
            @mouseover="selectItem(5)"
            @mouseleave="unselectItem"
            link
            @click="showAdditionalDeals"
        >
          <v-list-item-icon>
            <v-icon size="30" :disabled="selectedItem !== 5" color="#00B51A"
            >mdi-tag-text
            </v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
              :class="{ 'drawer-hover-override': hover }"
              style="color: #999999"
          >Zusatz-Angebote
          </v-list-item-title
          >
        </v-list-item>
      </v-hover>
      <v-divider/>
      <v-hover v-slot="{ hover }">
        <v-list-item
            class="mt-2 mb-2"
            @mouseover="selectItem(6)"
            @mouseleave="unselectItem"
            link
            @click="showCurrentDeals"
        >
          <v-list-item-icon>
            <v-icon size="30" :disabled="selectedItem !== 6" color="#00B51A"
            >mdi-tag-arrow-up
            </v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
              :class="{ 'drawer-hover-override': hover }"
              style="color: #999999"
          >Alle aktuellen Angebote
          </v-list-item-title
          >
        </v-list-item>
      </v-hover>

      <v-divider/>
      <v-hover v-slot="{ hover }">
        <v-list-item
            class="mt-2 mb-2"
            @mouseover="selectItem(7)"
            @mouseleave="unselectItem"
            link
            @click="showDeals"
        >
          <v-list-item-icon>
            <v-icon size="30" :disabled="selectedItem !== 7" color="#00B51A"
            >mdi-tag
            </v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
              :class="{ 'drawer-hover-override': hover }"
              style="color: #999999"
          >Alle verkauften Deals
          </v-list-item-title
          >
        </v-list-item>
      </v-hover>

      <v-divider/>

      <v-hover v-slot="{ hover }">
        <v-list-item
            class="mt-2 mb-2"
            @mouseover="selectItem(8)"
            @mouseleave="unselectItem"
            link
            @click="changePassword"
        >
          <v-list-item-icon>
            <v-icon size="30" :disabled="selectedItem !== 8" color="#00B51A"
            >mdi-account-box
            </v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
              :class="{ 'drawer-hover-override': hover }"
              style="color: #999999"
          >Profil bearbeiten
          </v-list-item-title
          >
        </v-list-item>
      </v-hover>
      <v-divider/>

      <v-hover v-slot="{ hover }">
        <v-list-item
            class="mt-2 mb-2"
            @mouseover="selectItem(9)"
            @mouseleave="unselectItem"
            link
            @click="showCustomers"
        >
          <v-list-item-icon>
            <v-icon size="30" :disabled="selectedItem !== 9" color="#00B51A"
            >mdi-tshirt-crew
            </v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
              :class="{ 'drawer-hover-override': hover }"
              style="color: #999999"
          >TW Abrechnung
          </v-list-item-title
          >
        </v-list-item>
      </v-hover>

      <v-divider/>
      <v-hover v-slot="{ hover }">
        <v-list-item class="mt-2 mb-2" @mouseover="selectItem(10)"
                     @mouseleave="unselectItem"
                     link @click="logout">

          <v-list-item-icon>
            <v-icon size="30" :disabled="selectedItem !== 10" color="#00B51A"
            >mdi-logout
            </v-icon
            >
          </v-list-item-icon>
          <v-list-item-title
              :class="{'drawer-hover-override': hover}"
              style="color: #999999">
            Ausloggen
          </v-list-item-title>
        </v-list-item>
      </v-hover>
      <v-divider/>
    </div>

  </v-navigation-drawer>
</template>
<script>

export default {
  name: 'NavigationAppBar',
  data() {
    return {
      hover: null,
      selectedItem: null,
      offset: true,
    }
  },
  methods: {
    showDeals() {
      if (this.$route.path !== '/all-deals') {
        this.$router.push('/all-deals');
      }
    },
    showClubs() {
      if (this.$route.path !== '/all-clubs') {
        this.$router.push('/all-clubs');
      }
    },
    showUsers() {
      if (this.$route.path !== '/all-users') {
        this.$router.push('/all-users');
      }
    },
    showCustomers() {
      if (this.$route.path !== '/all-customers') {
        this.$router.push('/all-customers');
      }
    },
    showDashboard() {
      if (this.$route.path !== '/dashboard') {
        this.$router.push('/dashboard');
      }
    },
    showClubNews() {
      if (this.$route.path !== '/admin-all-club-news') {
        this.$router.push('/admin-all-club-news');
      }
    },
    showClubEvents() {
      if (this.$route.path !== '/admin-all-club-events') {
        this.$router.push('/admin-all-club-events');
      }
    },
    showCurrentDeals() {
      if (this.$route.path !== '/current-deals') {
        this.$router.push('/current-deals');
      }
    },
    showAdditionalDeals() {
      if (this.$route.path !== '/additional-deals') {
        this.$router.push('/additional-deals');
      }
    },
    changePassword() {
      if (this.$route.path !== '/admin-profile') {
        this.$router.push('/admin-profile');
      }
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    unselectItem() {
      this.selectedItem = null
    },
    logout() {
      this.$store.commit('saveToken', '');
      this.$router.push('/login');
    }
  }
}
</script>