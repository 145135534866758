<template>
    <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
        <v-app-bar
            app
            color="#00B51A">
            <v-flex>
                <v-row>
                    <div class="pa-0 d-flex align-center">
                        <v-icon size="30" class="ml-1" @click="goBack" style="color: white"
                        >mdi-arrow-left
                        </v-icon>
                        <router-link to="/dashboard">
                            <v-img
                                :src="require('../assets/logo_club4ever.svg')"
                                class="ma-0"
                                contain
                                max-height="50"
                                max-width="190"
                            />
                        </router-link>
                    </div>
                    <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
                Rechnungspositionen von {{ recipient.name }}
              </span>
                    </div>

                    <v-spacer/>
                    <div class="d-flex align-center mr-5">
                        <v-select
                            v-model="month"
                            :items="months"
                            placeholder="Monat"
                            no-data-text="Keine Monate gefunden"
                            solo
                            dense
                            height="45px"
                            style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; height: 45px; border-radius: 5px"
                            color="green lighten-2"
                            item-color="green"
                            item-text="name"
                            item-value="id"
                            @change="fetchBillItems"
                        />
                    </div>
                    <div class="d-flex align-center mr-5">
                        <v-select
                            v-model="year"
                            :items="years"
                            placeholder="Jahre"
                            no-data-text="Keine Jahre gefunden"
                            solo
                            dense
                            height="45px"
                            style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; height: 45px; border-radius: 5px"
                            color="green lighten-2"
                            item-color="green"
                            @change="fetchBillItems"
                        />
                    </div>
                    <div class="d-flex align-center mr-5">
                        <v-btn style="background:white;color:#00B51A"
                               height="45"
                               elevation="2"
                               v-on:click="newBillItem">
                            <h3 class="font-weight-medium text-none">
                                Neue Rechnungsposition anlegen
                            </h3>
                        </v-btn>
                    </div>
                </v-row>
            </v-flex>
        </v-app-bar>
        <v-layout justify-space-between column>
            <v-row class="pa-0 ml-2 d-flex align-start">
                <SideMenu/>
                <v-col class="mr-15">
                    <v-card class="mr-3 ml-3">
                        <v-simple-table class="mt-5">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th><h3 class="font-weight-bold text-capitalize">
                                        Dienstleistung
                                    </h3></th>
                                    <th><h3 class="font-weight-bold text-capitalize">
                                        Preis pro
                                    </h3></th>
                                    <th><h3 class="font-weight-bold text-capitalize">
                                        Menge
                                    </h3></th>
                                    <th><h3 class="font-weight-bold text-capitalize">
                                        Gesamtpreis
                                    </h3></th>
                                    <th/>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(billItem, index ) in billItems" :key="billItem.id"
                                    @mouseover="selectItem(billItem)" @mouseleave="unSelectItem()">
                                    <td>
                                        <v-autocomplete
                                            v-model="billItem.service"
                                            :items="services"
                                            no-data-text="Keine Dienstleistungen gefunden"
                                            :disabled="!billItem.edit"
                                            color="blue-grey lighten-2"
                                            item-text="name"
                                            return-object
                                        >
                                        </v-autocomplete>
                                    </td>
                                    <td v-if="billItem.service != null">{{
                                            parseFloat(billItem.service.price).toLocaleString('de-DE', {minimumFractionDigits: 2})
                                        }}€
                                    </td>
                                    <td v-else>{{
                                            parseFloat(0).toLocaleString('de-DE', {minimumFractionDigits: 2})
                                        }}€
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="billItem.quantity"
                                            :disabled="!billItem.edit"
                                            :rules="[v => !!v,
                          v => {
                              const pattern =  new RegExp('^[0-9]*$');
                              var validation = pattern.test(v);
                              if (validation) return true
                              else return 'Bitte gib einen korrekten Menge an'
                            }
                          ]"
                                        >
                                        </v-text-field>
                                    </td>
                                    <td v-if="billItem.service != null">{{
                                            (parseFloat(billItem.service.price) * parseFloat(billItem.quantity)).toLocaleString('de-DE', {minimumFractionDigits: 2})
                                        }}€
                                    </td>
                                    <td v-else>{{
                                            (parseFloat(0) * parseFloat(billItem.quantity)).toLocaleString('de-DE', {minimumFractionDigits: 2})
                                        }}€
                                    </td>
                                    <td v-if="billItem.edit" style="width: 35px">
                                        <v-btn :disabled="selectedItem !== billItem"
                                               v-on:click.stop="saveBillItem(billItem)"
                                               width="35"
                                               plain
                                               height="35"
                                               class="ma-2"
                                               ripple
                                               outlined
                                               color="#00B51A"
                                        >
                                            <v-icon size="25">mdi-check</v-icon>
                                        </v-btn>
                                    </td>
                                    <td v-else style="width: 35px">
                                        <v-btn :disabled="selectedItem !== billItem"
                                               v-on:click.stop="billItem.edit = !billItem.edit"
                                               width="35"
                                               plain
                                               height="35"
                                               class="ma-2"
                                               ripple
                                               outlined
                                               color="#00B51A"
                                        >
                                            <v-icon size="25">mdi-pencil</v-icon>
                                        </v-btn>
                                    </td>
                                    <td v-if="!billItem.edit" style="width: 35px">
                                        <div class="text-center"
                                        >
                                            <v-btn :disabled="selectedItem !== billItem"
                                                   @click.stop="selectionForDialog"
                                                   width="35"
                                                   plain
                                                   height="35"
                                                   class="ma-2"
                                                   ripple
                                                   outlined
                                                   color="#00B51A"
                                            >
                                                <v-icon size="25">mdi-delete</v-icon>
                                            </v-btn>
                                        </div>
                                    </td>
                                    <td v-else-if="billItem.previous" style="width: 35px">
                                        <div class="text-center"
                                        >
                                            <v-btn :disabled="selectedItem !== billItem"
                                                   @click.stop="removeBillItem(index)"
                                                   width="35"
                                                   plain
                                                   height="35"
                                                   class="ma-2"
                                                   ripple
                                                   outlined
                                                   color="#00B51A"
                                            >
                                                <v-icon size="25">mdi-delete</v-icon>
                                            </v-btn>
                                        </div>
                                    </td>
                                    <td v-else style="width: 35px">
                                        <div class="text-center"
                                        >
                                            <v-btn :disabled="selectedItem !== billItem"
                                                   @click.stop="cancelEdit(billItem)"
                                                   width="35"
                                                   plain
                                                   height="35"
                                                   class="ma-2"
                                                   ripple
                                                   outlined
                                                   color="#00B51A"
                                            >
                                                <v-icon size="25">mdi-cancel</v-icon>
                                            </v-btn>
                                        </div>
                                    </td>
                                </tr>
                                <v-dialog
                                    v-model="dialog"
                                    width="500"
                                >
                                    <v-card>
                                        <v-card-title class="headline green" style="color: white">
                                            Hinweis
                                        </v-card-title>

                                        <v-card-text class="mt-3">
                                            Soll diese Rechnungsposition wirklich gelöscht werden?
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="green"
                                                text
                                                @click="dialog = false"
                                            >
                                                Nein
                                            </v-btn>
                                            <v-btn
                                                color="green"
                                                text
                                                @click="deleteBillItem()"
                                            > <!-- TODO: BUGFIX - move dialog outside of foreach loop -->
                                                Ja
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-spacer/>
                        <v-pagination
                            color="#00B51A"
                            v-model="page"
                            :length="lastPage"
                            @input="fetchBillItems"
                        />
                    </v-card>
                    <v-snackbar
                        v-model="snackbar"
                    >
                        {{ snackbarText }}

                        <template v-slot:action="{ attrs }">
                            <v-btn
                                color="white"
                                text
                                v-bind="attrs"
                                @click="snackbar = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-col>
            </v-row>
            <CustomFooter/>
        </v-layout>
    </v-container>
</template>

<script>
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import recipient from "@/api/requests/recipient";
import billItem from "@/api/requests/billItem";
import customer from "@/api/requests/customer";
import service from "@/api/requests/service";

export default {
    components: {SideMenu, CustomFooter},
    data() {
        return {
            year: null,
            years: [],
            month: null,
            months: [
                {id: 1, name: "Januar"},
                {id: 2, name: "Februar"},
                {id: 3, name: "März"},
                {id: 4, name: "April"},
                {id: 5, name: "Mai"},
                {id: 6, name: "Juni"},
                {id: 7, name: "Juli"},
                {id: 8, name: "August"},
                {id: 9, name: "September"},
                {id: 10, name: "Oktober"},
                {id: 11, name: "November"},
                {id: 12, name: "Dezember"}
            ],
            services: [],
            recipient: {},
            loading: false,
            billItems: [],
            page: 1,
            lastPage: 1,
            searchQuery: '',
            error: null,
            selectedItem: null,
            selectedBillItem: null,
            dialog: null,
            empty: 0,
            snackbarText: '',
            snackbar: false
        }
    },
    created() {
        if (this.$store.state.token.length === 0) {
            this.$router.push('/login');
        } else {
            this.setDates();
            this.fetchServices();
            this.fetchRecipient();
            this.fetchBillItems();
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        setDates() {
            const date = new Date();
            this.month = date.getMonth() + 1;
            this.year = date.getFullYear();

            for (let year = 2020; year <= date.getFullYear(); year++) {
                this.years.unshift(year);
            }
        },
        formatBillItemTime(datetime) {
            return new Date(datetime).toLocaleDateString(
                'de-de',
                {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }
            );
        },
        fetchRecipient() {
            recipient.getRecipientByID(this.$store.state.token, this.$route.params.recipientId)
                .then((result) => {
                    this.recipient = result.data;
                })
        },
        async fetchServices() {
            service.getAll(this.$store.state.token)
                .then(response => {
                    this.services = response.data.map(service => ({
                        id: service.id ?? '',
                        name: service.name ?? '',
                        price: service.price.toFixed(2).toString().replace('.', ',')
                    }));
                })
        },
        async saveBillItem(item) {
            //this.saving = true;
            if (item.id === null) {
                billItem.saveBillItem(
                    this.$store.state.token,
                    this.recipient.id,
                    item.service.id,
                    item.quantity,
                ).then((result) => {
                    this.snackbarText = 'Rechnungsposition wurde erfolgreich angelegt';
                    this.saving = false;
                    this.snackbar = true;
                    //this.$router.push(`/bill-items/` + this.recipient.id);
                    item.id = result.data.id;
                    item.service = result.data.service;
                    item.quantity = result.data.quantity;
                    item.memoryService = result.data.service;
                    item.memoryQuantity = result.data.quantity;
                    item.edit = false;
                    item.previous = false;
                })
                    .catch(() => {
                        this.snackbar = true;
                        this.snackbarText = 'Empfänger konnte nicht angelegt werden';
                    });
            } else {
                if (item.service.id === item.memoryService.id && item.quantity === item.memoryQuantity) {
                    item.edit = false;
                    return;
                }
                billItem.update(
                    this.$store.state.token,
                    item.id,
                    item.service.id,
                    item.quantity,
                )
                    .then(() => {
                        this.snackbarText = 'Rechnungsposition wurde erfolgreich angelegt';
                        this.saving = false;
                        this.snackbar = true;
                        //this.$router.push(`/bill-items/` + this.recipient.id);
                        item.edit = false;
                        item.previous = false;
                    })
                    .catch(() => {
                        this.snackbar = true;
                        this.snackbarText = 'Empfänger konnte nicht angelegt werden';
                    });
            }

        },
        fetchPreviousBillItems() {
            let date = new Date();
            let month = date.getMonth();
            let year = date.getFullYear();
            billItem.getAll(this.$store.state.token, 1, this.$route.params.recipientId, month, year).then(response => {
                this.billItems = response.data.map(billItem => ({
                    id: null,
                    service: billItem.service ?? '',
                    memoryService: billItem.service ?? '',
                    quantity: billItem.quantity ?? '',
                    memoryQuantity: billItem.quantity ?? '',
                    edit: true,
                    previous: true,
                }));

                if (this.billItems.length === 0) {
                    this.billItems.push({
                        id: null,
                        service: null,
                        quantity: 1,
                        edit: true,
                        previous: false,
                        memoryService: null,
                        memoryQuantity: null
                    })
                }
            });


        },
        selectItem(billItem) {
            this.selectedItem = billItem;
        },
        unSelectItem() {
            this.selectedItem = null;
        },
        selectionForDialog() {
            this.dialog = true;
            this.selectedBillItem = this.selectedItem;
        },
        newBillItem() {
            if (this.billItems.length >= 10) {
                this.snackbarText = 'Maximal 10 Rechnungspositionen sind erlaubt.';
                this.snackbar = true;
                return;
            }

            if (this.billItems.length === 0) {
                this.fetchPreviousBillItems();
            } else {
                this.billItems.push({
                    id: null,
                    service: null,
                    quantity: 1,
                    edit: true,
                    previous: false,
                    memoryService: null,
                    memoryQuantity: null
                })
            }
            //  this.$router.push(`/new-bill-item/${this.recipient.id}/${this.empty}`);
        },

        editBillItem(billItem) {
            this.$router.push(`/edit-bill-item/${this.recipient.id}/${billItem.id}`);
        },
        viewBillItems(billItem) {
            this.$router.push(`/bill-items/${billItem.id}`);
        },
        fetchBillItems(runAutoFetch = true) {
            this.loading = true;
            billItem.getAll(this.$store.state.token, this.page, this.$route.params.recipientId, this.month, this.year).then(response => {
                this.lastPage = response.meta.last_page
                this.billItems = response.data.map(billItem => ({
                    id: billItem.id ?? '',
                    recipientId: billItem.recipientId ?? '',
                    service: billItem.service ?? '',
                    memoryService: billItem.service ?? '',
                    quantity: billItem.quantity ?? '',
                    memoryQuantity: billItem.quantity ?? '',
                    edit: false,
                    previous: false,
                }));

                if (!this.billItems.length && runAutoFetch) this.loadPreviousMonth();
            }).catch(e => {
                console.log(e.response);
            });
            this.loading = false;
        },
        async loadPreviousMonth() {
            const data = await billItem.autoLoadBilling(this.$store.state.token, this.$route.params.recipientId, this.month, this.year);
            if(data.status === 'success')
                this.fetchBillItems(false)
        },
        deleteBillItem() {
            billItem.delete(this.$store.state.token, this.selectedBillItem.id).then(() => {
                this.fetchBillItems();
                this.dialog = false;
                this.snackbarText = 'Rechnungsposition erfolgreich gelöscht.'
                this.snackbar = true;
            }).catch(() => {
                this.dialog = false;
                this.snackbarText = 'Rechnungsposition konnte nicht gelöscht werden.'
                this.snackbar = true;
            });
        },
        fetchCustomer() {
            customer.getCustomerByID(this.$store.state.token, this.$route.params.customerId)
                .then((result) => {
                    this.customer = result.data;
                })
        },
        cancelEdit(item) {
            item.edit = false;
            if (item.service === null || item.memoryService === null) {
                this.billItems.pop();
            }
            item.service = item.memoryService;
            item.quantity = item.memoryQuantity;
        },
        removeBillItem(index) {
            this.billItems.splice(index, 1);
        },
    }
}
</script>