<template>
  <v-flex>
    <v-container fluid class="d-flex pb-0 align-start fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A"
      >
        <div class="d-flex align-center">
          <v-row>
            <v-icon size="30" class="ml-1" @click="goBack" style="color: white"
            >mdi-arrow-left
            </v-icon>
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </v-row>
        </div>
        <span class="font-weight-medium" style="color: white;font-size: x-large">
            {{this.title}}
              </span>
        <v-spacer/>

      </v-app-bar>
      <v-layout justify-space-between column>
        <v-row class="mt-16 ml-14">
          <SideMenu/>
          <v-col>
            <h1 v-if="loading">
              <v-progress-circular class="mt-5"
                                   indeterminate
                                   color="#00B51A"
              ></v-progress-circular>
            </h1>
            <v-card class="mt-2 ml-2" width="1000" elevation="1" v-else>
              <v-col>
                <v-form ref="form" class="mx-2" lazy-validation>

                  <v-row>
                    <v-col>
                      <span class="font-weight-regular">
                      Bezeichnung
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="name"
                          :rules="[v => !!v || 'Bitte gib den Namen des Empfängers ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <span class="font-weight-regular">
                      Preis
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="price"
                          :rules="[
                            v => !!v || 'Bitte gib den Preis der Dienstleistung ein',
                            v => {
                              const pattern =  new RegExp('^[0-9]*,[0-9][0-9]$|^[0-9]*,[0-9]$|^[0-9]*$');
                              var validation = pattern.test(v);
                              if (validation) return true
                              else return 'Bitte gib einen korrekten Preis an'
                            }
                          ]"
                          suffix="€"
                          locale="de-De"
                          type="text"
                          required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <div class="text-center">
                    <v-btn class="mt-10 mb-5" style="background:#00B51A;color:white"
                           height="45"
                           width="200"
                           elevation="2"
                           :loading="saving"
                           v-on:click="saveService">
                      <h3 class="font-weight-medium text-capitalize">
                        Speichern
                      </h3>
                    </v-btn>
                  </div>

                  <v-snackbar
                      v-model="snackbar"
                  >
                    {{ snackbarText }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                          color="white"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>

                </v-form>
              </v-col>
            </v-card>

          </v-col>

        </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>

</template>
<script>
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import service from "@/api/requests/service";

export default {
  components: {SideMenu, CustomFooter},
  data() {
    return {
      title: '',
      serviceId: null,
      edit: false,
      loading: false,
      saving: false,
      error: null,
      name: '',
      price: '',
      snackbarText: '',
      snackbar: false
    }
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.serviceId = this.$route.params.serviceId

      if(this.serviceId == null ) {
        this.title = "Dienstleistung bearbeiten";
        this.edit = false;
      } else {
        this.fetchService();
        this.edit = true;
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async saveService() {
      // form validation
      if (!this.$refs.form.validate()) {
        return false;
      }
      //edit existing recipient - pass if not edit
      if(this.edit) {
        this.saving = true;
        service.update(this.$store.state.token, this.serviceId, this.name, this.price.replace(/,/g, '.'))
          .then((result) => {
            console.log("saved");
            console.log(result);
            this.snackbarText = 'Dienstleistung wurde erfolgreich gespeichert';
            this.saving = false;
            this.snackbar = true;
            this.goBack();
            return true;
          })
          .catch(e => {
            console.log(e);
            this.snackbar = true;
            this.snackbarText = 'Dienstleistung konnte nicht gespeichert werden';
            return false;
          })
      } else {
        this.saving = true;
        service.save(this.$store.state.token, this.serviceId, this.name, this.price.replace(/,/g, '.'))
            .then((result) => {
              console.log(result);
              this.snackbarText = 'Dienstleistung wurde erfolgreich angelegt';
              this.saving = false;
              this.snackbar = true;
              this.goBack();
            })
            .catch((e) => {
              console.log(e);
              this.snackbar = true;
              this.snackbarText = 'Dienstleistung konnte nicht angelegt werden';
            })
        ;
      }
    },
    async fetchService() {
      service.getById(this.$store.state.token, this.serviceId)
        .then(response => {
          this.name = response.data.name ?? '';
          this.price = response.data.price.toFixed(2).toString().replace('.', ',') ?? '';
        })
        .catch(e => {console.log(e)})
      ;
    }
  }
}
</script>
