<template>
  <v-footer class="mb-0 pa-0" height="50" style="background-color: #00B51A;">
    <v-layout justify-center>
      <v-btn class="text-none" @click="showImprint"
             text
             color="white"
      >
        Impressum
      </v-btn>
      <v-btn class="text-none" @click="showDataProtection"
             text
             color="white"
      >
            <span>
              Datenschutzerklärung
            </span>

      </v-btn>
    </v-layout>
  </v-footer>

</template>


<script>

export default {
  methods: {
    showImprint(){
      this.$router.push('/imprint');
    },
    showDataProtection(){
      this.$router.push('/data-protection');
    },
  },
}
</script>