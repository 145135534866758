import Repository from "../repository";

export default {
    async getAll() {
        const response = await Repository.get(`countries`);
        return response.data;
    },
    async getCitiesForCountry(countryID){
        const response = await Repository.get(`cities?country-id=${countryID}`);
        return response;
    },
    async getCitiesForState(state){
        const response = await Repository.get(`cities-for-state?state=${state}`);
        return response;
    }
}