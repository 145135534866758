<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/club-admin-dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Zusatz-Angebote
              </span>
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
      <v-row class="pa-0 ml-2 d-flex align-start">
        <ClubAdminSideMenu/>
        <v-col class="mr-15">
          <v-card class="mr-3 ml-3">
            <v-simple-table class="mt-6">
              <template v-slot:default>
                <thead>
                <tr>
                  <th>
                    <h3 class="font-weight-bold text-none" @click="changeSorting">
                      Livegang in
                      <v-icon v-if="sorting === 'asc'" size="15">mdi-arrow-down-bold</v-icon>
                      <v-icon v-else size="15">mdi-arrow-up-bold</v-icon>
                    </h3>
                  </th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Land
                  </h3></th>
                  <th>
                    <h3 class="font-weight-bold text-capitalize">
                      Bundesland
                    </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Stadt
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Logo
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Club Name
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Deal ID
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Titel
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Preis
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Firmenname
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Verkäufer Nachname, Vorname
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Verkäufer E-Mail
                  </h3></th>
                  <th><h3 class="font-weight-bold text-none">
                    Livegang stoppen
                  </h3></th>
                  <th/>
                </tr>
                </thead>
                <tbody>
                <tr v-for="deal in deals" :key="deal.id" @mouseover="selectItem(deal)" @mouseleave="unSelectItem()">
                  <td>
                    <v-icon size="22"
                    >mdi-timer-sand
                    </v-icon
                    >
                    {{ `Tage: ${calculateDays(deal.liveIn)} Stunden: ${calculateHours(deal.liveIn)}` }}
                  </td>
                  <td>{{ deal.country }}</td>
                  <td>{{ deal.state }}</td>
                  <td>{{ deal.city }}</td>
                  <td>
                    <v-avatar class="mt-3 mb-3">
                      <v-img :src="(deal.clubLogo)" max-height="100" width="60"></v-img>
                    </v-avatar>
                  </td>
                  <td>{{ deal.clubName }}</td>
                  <td>{{ deal.id }}</td>
                  <td>{{ deal.title }}</td>
                  <td>{{ deal.price }}€</td>
                  <td>{{ deal.companyName }}</td>
                  <td>{{ deal.userName }}</td>
                  <td>{{ deal.userEmail }}</td>
                  <td>
                    <v-dialog
                        v-model="dialog"
                        width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="selectedRow !== deal"
                               v-on="on"
                               v-bind="attrs"
                               width="35"
                               plain
                               height="35"
                               class="ma-2"
                               ripple
                               outlined
                               color="red"
                        >
                          <v-icon size="25">mdi-close-thick</v-icon>
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="headline green" style="color: white">
                          Hinweis
                        </v-card-title>

                        <v-card-text class="mt-3">
                          Soll dieses Zusatz-Angebot wirklich abgelehnt werden?
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              color="green"
                              text
                              @click="dialog = false"
                          >
                            Nein
                          </v-btn>
                          <v-btn
                              color="green"
                              text
                              @click="deleteOffer(deal.id)"
                          >
                            Ja
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-pagination
                color="#00B51A"
                v-model="page"
                :length="lastPage"
                @input="fetchDeals(page)"
            ></v-pagination>
          </v-card>
          <v-snackbar
              v-model="snackbar"
          >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Schließen
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
      <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import deal from "@/api/requests/deal.js";
import CustomFooter from "@/components/CustomFooter";
import ClubAdminSideMenu from "@/components/ClubAdminSideMenu";

export default {
  components: {CustomFooter, ClubAdminSideMenu},
  data() {
    return {
      loading: false,
      deals: [],
      page: 1,
      lastPage: 1,
      error: null,
      dialog: null,
      snackbarText: '',
      snackbar: false,
      sorting: 'asc',
      selectedRow: null,
    }
  },
  created() {
    this.fetchDeals(1)
  },
  methods: {
    deleteOffer(dealID){
      deal.declineDeal(this.$store.state.token, dealID).then(response=>{
        console.log(response);
        this.fetchDeals(1);
        this.dialog = false;
      }).catch(e => {
        console.log(e.response);
        this.dialog = false;
        this.snackbar = true;
        this.snackbarText = 'Angebot konnte nicht abgelehnt werden.'
        if (e.response.status === 401) {
          this.$router.push('/login');
        }
      });
    },
    calculateDays(hours) {
      let days = hours / 24;
      if (days <= 1) {
        return '< 1';
      } else return Math.floor(days);
    },
    calculateHours(hours) {
      let restHours = hours % 24;
      if (restHours <= 1) {
        return '< 1';
      } else return restHours;
    },
    changeSorting() {
      if (this.sorting === 'asc') {
        this.sorting = 'desc';
        this.fetchDeals(1);
      } else {
        this.sorting = 'asc';
        this.fetchDeals(1);
      }
    },
    editDeal(deal) {
      this.$router.push({name: 'edit-deal', params: {id: deal.id}});
    },
    selectItem(deal) {
      this.selectedRow = deal
    },
    unSelectItem() {
      this.selectedRow = null
    },
    fetchDeals(page) {
      this.loading = true
      deal.getAdditional(this.$store.state.token, page, this.sorting).then(response => {
        console.log(response);
        this.lastPage = response.meta.last_page;
        this.deals = response.data.map(deal => ({
          title: deal.title,
          id: deal.id,
          price: deal.price.toFixed(2).replace('.', ','),
          userID: deal.user.id,
          userEmail: deal.user.email,
          userName: deal.user.last_name + ', ' + deal.user.first_name,
          companyName: deal.user.company_name,
          country: 'Deutschland',
          state: deal.user.club.city.state,
          city: deal.user.club.city.name,
          clubLogo: deal.user.club.logo_url,
          clubName: deal.user.club.name,
          liveIn: this.calculateLiveIn(deal.created_at),
          donationClub: deal.donation_club.toFixed(2).replace('.', ','),
        }));
      }).catch(e => {
        console.log(e.response);
        if (e.response.status === 401) {
          this.$router.push('/login');
        }
      });
    },
    calculateLiveIn(createdAt) {
      let createdAtDate = new Date(createdAt);
      let liveDate = new Date(createdAtDate.getTime() + (5 * 24 * 60 * 60 * 1000));
      let now = new Date();
      return Math.floor(Math.abs(now - liveDate) / 36e5);
    }
  }

}
</script>