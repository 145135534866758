import Repository from "../repository";

export default {
    async getAll(token, page, status, countryID, state, cityID, searchQuery, sorting) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const response = await Repository.get(`dealIndexAdmin?paginate=25&page=${page}&status=${status}&country-id=${countryID}&state=${state}&city-id=${cityID}&query=${searchQuery}&sort=${sorting}`, config);
        return response.data;
    },
    async delete(token, dealID) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const response = await Repository.delete(`deals/${dealID}`, config);
        return response.data;
    },
    async getAdditional(token, page, sorting, countryID, state, cityID, searchQuery ) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const response = await Repository.get(`additional-offers?paginate=25&page=${page}&sort=${sorting}&country-id=${countryID}&state=${state}&city-id=${cityID}&query=${searchQuery}`, config);
        return response.data;
    },
    async getAllForClub(token, page, filter) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const response = await Repository.get(`get-deals-for-club?paginate=25&page=${page}&filter=${filter}`, config);
        return response.data;
    },
    async dealDetail(token, dealID) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const response = await Repository.get(`dealShowAdmin/${dealID}`, config);
        return response.data;
    },
    async markAsPaid(token, dealID, paidAt) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const dealData = {paid_at: paidAt};
        const response = await Repository.post(`mark-paid/${dealID}`, dealData, config);
        return response.data;
    },
    async markAsBilled(token, dealID, billedAt) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const dealData = {billed_at: billedAt};
        const response = await Repository.post(`mark-billed/${dealID}`, dealData, config);
        return response.data;
    },
    async discharge(token, dealID, dischargeItem) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const dealData = {};
        const response = await Repository.post(`${dischargeItem}/${dealID}`, dealData, config);
        return response.data;
    },
    async setGlobal(token, dealID) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const dealData = {};
        const response = await Repository.post(`toggleDealGlobal/${dealID}`, dealData, config);
        return response.data;
    },
    async declineDeal(token, dealID) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const dealData = {deal_id: dealID};
        const response = await Repository.post(`decline-deal`, dealData, config);
        return response.data;
    },
}