var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('v-container',{staticClass:"d-flex pb-0 align-start fill-height",staticStyle:{"background":"#EDEDED"},attrs:{"fluid":""}},[_c('v-app-bar',{attrs:{"app":"","color":"#00B51A"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-row',[_c('v-icon',{staticClass:"ml-1",staticStyle:{"color":"white"},attrs:{"size":"30"},on:{"click":_vm.goBack}},[_vm._v("mdi-arrow-left ")]),_c('router-link',{attrs:{"to":"/dashboard"}},[_c('v-img',{staticClass:"ma-0",attrs:{"src":require('../assets/logo_club4ever.svg'),"contain":"","max-height":"50","max-width":"190"}})],1)],1)],1),_c('span',{staticClass:"font-weight-medium",staticStyle:{"color":"white","font-size":"x-large"}},[_vm._v(" "+_vm._s(this.title)+" ")]),_c('v-spacer')],1),_c('v-layout',{attrs:{"justify-space-between":"","column":""}},[_c('v-row',{staticClass:"mt-16 ml-14"},[_c('SideMenu'),_c('v-col',[(_vm.loading)?_c('h1',[_c('v-progress-circular',{staticClass:"mt-5",attrs:{"indeterminate":"","color":"#00B51A"}})],1):_c('v-card',{staticClass:"mt-2 ml-2",attrs:{"width":"1000","elevation":"1"}},[_c('v-col',[_c('v-form',{ref:"form",staticClass:"mx-2",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"font-weight-regular"},[_vm._v(" Bezeichnung ")]),_c('v-text-field',{attrs:{"outlined":"","color":"#00B51A","rules":[function (v) { return !!v || 'Bitte gib den Namen des Empfängers ein'; }],"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"font-weight-regular"},[_vm._v(" Preis ")]),_c('v-text-field',{attrs:{"outlined":"","color":"#00B51A","rules":[
                          function (v) { return !!v || 'Bitte gib den Preis der Dienstleistung ein'; },
                          function (v) {
                            var pattern =  new RegExp('^[0-9]*,[0-9][0-9]$|^[0-9]*,[0-9]$|^[0-9]*$');
                            var validation = pattern.test(v);
                            if (validation) { return true }
                            else { return 'Bitte gib einen korrekten Preis an' }
                          }
                        ],"suffix":"€","locale":"de-De","type":"text","required":""},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1)],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mt-10 mb-5",staticStyle:{"background":"#00B51A","color":"white"},attrs:{"height":"45","width":"200","elevation":"2","loading":_vm.saving},on:{"click":_vm.saveService}},[_c('h3',{staticClass:"font-weight-medium text-capitalize"},[_vm._v(" Speichern ")])])],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)],1)],1)],1)],1),_c('CustomFooter')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }