import Repository from "../repository";

export default {
    async fetchDashboardData(token, filter, clubID) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        let response = null;
        if(clubID!=null){
            response = await Repository.get(`dashboard-data?filter=${filter}&clubID=${clubID}`, config);
        }else {
            response = await Repository.get(`dashboard-data?filter=${filter}`, config);
        }

        return response.data;
    },
}