<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Alle Nutzer
              </span>
          </div>

          <v-spacer/>
          <div class="d-flex align-center mr-5">
            <v-text-field
                solo
                dense
                clearable
                color="#00B51A"
                height="45px"
                style="background-color: white; max-width: 200px; max-height: 40px; min-height: 40px; border-radius: 5px"
                placeholder="Suche"
                v-model="searchQuery"
                @keyup="search"
                @click:clear="fetchUsers();"
                required
            />
          </div>
          <div class="d-flex align-center mr-5">
            <v-autocomplete
                v-model="selectedCountry"
                :items="countries"
                placeholder="Land"
                no-data-text="Keine Länder gefunden"
                solo
                clearable
                dense
                height="45px"
                class="pa-0 ma-0"
                style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; border-radius: 5px"
                item-text="name"
                color="green lighten-2"
                item-color="green"
                item-value="id"
                @change="countrySelectionChanged"
            />
          </div>
          <div class="d-flex align-center mr-5">
            <v-autocomplete
                v-model="selectedState"
                :items="states"
                placeholder="Bundesland"
                no-data-text="Keine Bundesländer gefunden"
                solo
                clearable
                dense
                height="45px"
                style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; height: 45px; border-radius: 5px"
                color="green lighten-2"
                item-color="green"
                item-text="name"
                item-value="id"
                @change="stateSelectionChanged"
            />
          </div>

          <div class="d-flex align-center mr-5">
            <v-autocomplete
                v-model="selectedCity"
                :items="cities"
                placeholder="Stadt"
                no-data-text="Keine Städte gefunden"
                solo
                clearable
                dense
                height="45px"
                style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; height: 45px; border-radius: 5px"
                color="green lighten-2"
                item-color="green"
                item-text="name"
                item-value="id"
                @change="citySelectionChanged"
            />
          </div>
          <div class="d-flex align-center mr-5">
            <v-sheet width="200" height="45" color="#00B51A">
              <v-select color="white" item-color="#00B51A" height="45" style="color: white; border-color: white"
                        :items="items"
                        dense
                        solo
                        v-model="selectedFilter"
                        @change="selectionChanged"
              ></v-select>
            </v-sheet>
          </div>
          <div class="d-flex align-center mr-5">
            <v-btn style="background:white;color:#00B51A"
                   height="45"
                   elevation="2"
                   v-on:click="newUser">
              <h3 class="font-weight-medium text-none">
                Neuen Nutzer anlegen
              </h3>
            </v-btn>
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
      <v-row class="pa-0 ml-2 d-flex align-start">
        <SideMenu/>
        <v-col class="mr-15">
          <v-card class="mr-3 ml-3">
            <v-simple-table class="mt-5">
              <template v-slot:default>
                <thead>
                <tr>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Land
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Bundesland
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Stadt
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Logo
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Club Name
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Nutzer Rolle
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Nutzer Profilbild
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Nutzer Firmenname
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Nutzer Nachname, Vorname
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    E-Mail
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Nutzer-ID
                  </h3></th>
                  <th v-if="selectedFilter === items[2]"><h3 class="font-weight-bold text-capitalize">
                    Dessaso Kontakt
                  </h3></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="user in users" :key="user.id" @mouseover="selectItem(user)" @mouseleave="unSelectItem()">
                  <td>{{ user.country }}</td>
                  <td>{{ user.state }}</td>
                  <td>{{ user.city }}</td>
                  <td>
                    <v-avatar class="mt-3 mb-3">
                      <v-img :src="(user.clubLogo)" max-height="100" width="60"></v-img>
                    </v-avatar>
                  </td>
                  <td>{{ user.clubName }}</td>
                  <td>{{ user.role }}</td>
                  <td>
                    <v-avatar size="45"  color="#EDEDED" class="mt-3 mb-3">
                      <v-img v-if="user.profileImage !=null" :src="(user.profileImage)"></v-img>
                      <v-icon v-else size="30" color="#C0BFBA">mdi-account</v-icon>
                    </v-avatar>
                  </td>
                  <td>{{ user.companyName }}</td>
                  <td>{{ user.name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.id }}</td>
                  <td v-if="selectedFilter === items[2]">
                    <v-checkbox color="green"
                                v-model="user.dessasoContact"
                                @click="markDessasoContact(user.id)"
                    ></v-checkbox>
                  </td>
                  <td style="width: 35px">
                    <div class="text-center">
                      <v-btn :disabled="selectedRow !== user" @click="selectUserForDialog"
                             width="35"
                             plain
                             height="35"
                             class="ma-2"
                             ripple
                             outlined
                             color="#00B51A"
                      >
                        <v-icon size="25">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <v-dialog
                    v-model="dialog"
                    width="500"
                >
                  <v-card>
                    <v-card-title class="headline green" style="color: white">
                      Hinweis
                    </v-card-title>

                    <v-card-text class="mt-3">
                      Soll dieser Nutzer wirklich gelöscht werden?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="green"
                          text
                          @click="dialog = false"
                      >
                        Nein
                      </v-btn>
                      <v-btn
                          color="green"
                          text
                          @click="deleteUser()"
                      >
                        Ja
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                </tbody>
              </template>
            </v-simple-table>
            <v-spacer/>
            <v-pagination
                color="#00B51A"
                v-model="page"
                :length="lastPage"
                @input="fetchUsers()"
            />
          </v-card>
          <v-snackbar
              v-model="snackbar"
          >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
      <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import SideMenu from "@/components/SideMenu";
import user from "@/api/requests/user";
import CustomFooter from "@/components/CustomFooter";
import city from "@/api/requests/city";
import country from "@/api/requests/country";

export default {
  components: {SideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      users: [],
      page: 1,
      lastPage: 1,
      searchQuery: '',
      selectedCountry: 83,
      countries: [],
      selectedState: null,
      states: [],
      selectedCity: null,
      cities: [],
      error: null,
      selectedItem: null,
      snackbarText: '',
      snackbar: false,
      selectedRow: null,
      selectedUser: null,
      dialog: null,
      selectedFilter: 'Alle',
      items: ['Alle', 'Admins', 'Club Admins', 'Nutzer']
    }
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchCountries();
      this.fetchUsers();
      this.fetchStatesForCountry()
    }
  },
  methods: {
    deleteUser() {
      user.delete(this.$store.state.token, this.selectedUser.id).then(response => {
        console.log(response);
        this.selectedCountry = 83;
        this.countrySelectionChanged();
        this.dialog = false;
        this.snackbarText = 'Nutzer erfolgreich gelöscht.'
        this.snackbar = true;
      }).catch(e => {
        console.log(e.response);
        this.dialog = false;
        this.snackbarText = 'Nutzer konnte nicht gelöscht werden.'
        this.snackbar = true;
      });
    },
    selectUserForDialog() {
      this.dialog = true;
      this.selectedUser = this.selectedRow;
    },
    selectItem(user) {
      this.selectedRow = user
    },
    unSelectItem() {
      this.selectedRow = null
    },
    markDessasoContact(userID) {
      user.update(userID).then(response => {
        this.fetchUsers();
        console.log(response);
      });
    },
    search() {
      this.fetchUsers();
    },
    countrySelectionChanged() {
      this.selectedCity = null;
      this.cities = [];
      this.selectedState = null;
      this.states = [];
      this.fetchUsers();
      this.fetchStatesForCountry()
    },
    stateSelectionChanged() {
      if (this.selectedState == null) {
        this.countrySelectionChanged();
      } else {
        this.cities = [];
        this.selectedCity = null;
        this.fetchUsers();
        this.fetchCitiesForState()
      }
    },
    citySelectionChanged() {
      if (this.selectedCity == null) {
        this.stateSelectionChanged();
      } else {
        this.clubs = [];
        this.fetchUsers();
      }
    },
    fetchStatesForCountry() {
      city.getStatesForCountry(this.selectedCountry).then(response => {
        this.states = [];
        this.states = response.data.map(state => ({
          name: state
        }));
      })
    },
    fetchCitiesForState() {
      country.getCitiesForState(this.selectedState).then(response => {
        this.cities = [];
        this.cities = response.data.map(city => ({
          name: city.name,
          id: city.id,
        }));
      })
    },
    fetchCountries() {
      this.loading = true
      country.getAll().then(response => {
        this.countries = [];
        this.countries = response.data.map(country => ({
          name: country.name,
          id: country.id,
          code: country.code,
        }));
        this.loading = false;
      });
    },
    stringToRole() {
      switch (this.selectedFilter) {
        case this.items[0]:
          return '';
        case this.items[1]:
          return '1';
        case this.items[2]:
          return '2';
        case this.items[3]:
          return '3';
      }
    },
    fetchUsers() {
      this.loading = true // TODO: does this have to be false after this function?
      if(this.searchQuery==null){
        this.searchQuery = '';
      }
      user.getAll(this.page, this.stringToRole(), this.selectedCountry, this.selectedState, this.selectedCity, this.searchQuery).then(response => {
        console.log(response);
        this.lastPage = response.meta.last_page;
        this.users = response.data.map(user => ({
          country: 'Deutschland',
          state: user.club == null ? '' : user.club.city.state,
          city: user.club == null ? '' : user.club.city.name,
          clubLogo: user.club == null ? '' : user.club.logo_url,
          clubName: user.club == null ? '' : user.club.name,
          profileImage: user.profile_image_url,
          name: user.last_name + ', ' + user.first_name,
          companyName: user.company_name == null ? '' : user.company_name,
          id: user.id,
          role: this.parseRole(user.role),
          dessasoContact: user.role === 4,
          email: user.email
        }));
      }).catch(e => {
        console.log(e.response);
      });
    },
    parseRole(role) {
      switch (role) {
        case 1:
          return 'Admin';
        case 2:
          return 'Club Admin';
        case 3:
          return 'Nutzer';
        case 4:
          return 'dessaso Kontakt';
      }
    },
    selectionChanged(item) {
      this.selectedFilter = item;
      switch (item) {
        case this.items[0]:
          this.fetchUsers(this.page, '');
          break;
        case this.items[1]:
          this.fetchUsers(this.page, '1');
          break;
        case this.items[2]:
          this.fetchUsers(this.page, '2');
          break;
        case this.items[3]:
          this.fetchUsers(this.page, '3');
          break;
      }
    },
    newUser() {
      this.$router.push('/new-user');
    }
  }

}
</script>