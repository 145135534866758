<template>
  <v-flex>
    <v-container fluid class="d-flex align-start pb-0 fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A"
      >
        <div class="d-flex align-center">
          <v-row>
            <v-icon size="30" class="ml-1" @click="goBack" style="color: white"
            >mdi-arrow-left
            </v-icon>
            <router-link to="/club-admin-dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </v-row>

        </div>
        <span class="font-weight-medium" style="color: white;font-size: x-large">
            Neuen Club Admin anlegen
              </span>
        <v-spacer/>

      </v-app-bar>
      <v-layout justify-space-between column>
        <v-row class="mt-16 ml-14">
          <ClubAdminSideMenu/>
          <v-col>
            <h1 v-if="loading">
              <v-progress-circular class="mt-5"
                                   indeterminate
                                   color="#00B51A"
              ></v-progress-circular>
            </h1>
            <v-card class="mt-2 ml-2" width="800" elevation="1" v-else>
              <v-col>
                <span class="font-weight-regular ml-3">
                  Geschlecht
                </span>
                <v-col
                    class="d-flex pa-0 pr-4 ml-2"
                >
                  <v-select
                      item-color="#00B51A"
                      color="#00B51A"
                      :items="genders"
                      label="Outlined style"
                      outlined
                      solo
                      v-model="selectedGender"
                  ></v-select>
                </v-col>

                <v-form ref="form" class="mx-2" lazy-validation>
                <span class="font-weight-regular">
                  Vorname
                </span>
                  <v-text-field
                      outlined
                      color="#00B51A"
                      v-model="firstName"
                      :rules="[v => !!v || 'Bitte gib einen Vornamen ein']"
                      required
                  />
                  <span class="font-weight-regular">
                  Nachname
                </span>
                  <v-text-field
                      outlined
                      color="#00B51A"
                      v-model="lastName"
                      :rules="[v => !!v || 'Bitte gib einen Nachnamen ein']"
                      required
                  >
                  </v-text-field>
                  <span class="font-weight-regular">
               E-Mail-Adresse
              </span>
                  <v-text-field
                      outlined
                      color="#00B51A"
                      v-model="email"
                      :rules="emailRules"
                      required
                  >
                  </v-text-field>
                  <div class="text-center">
                    <v-btn class="mt-10 mb-5" style="background:#00B51A;color:white"
                           height="45"
                           width="200"
                           elevation="2"
                           :loading="saving"
                           v-on:click="saveUser">
                      <h3 class="font-weight-medium text-capitalize">
                        Speichern
                      </h3>
                    </v-btn>
                  </div>
                  <v-snackbar
                      v-model="snackbar"
                  >
                    {{ snackbarText }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                          color="white"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                </v-form>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>

</template>
<script>
import user from "@/api/requests/user";
import CustomFooter from "@/components/CustomFooter";
import ClubAdminSideMenu from "@/components/ClubAdminSideMenu";

export default {
  components: {ClubAdminSideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      saving: false,
      error: null,
      firstName: '',
      lastName: '',
      email: '',
      selectedGender: 'Männlich',
      snackbarText: '',
      snackbar: false,
      gender: null,
      emailRules: [
        v => !!v || 'Bitte gib eine E-Mail-Adresse ein',
        v => /.+@.+\..+/.test(v) || 'E-Mail-Adresse muss valide sein',
      ],
      genders: ['Männlich', 'Weiblich', 'Divers']
    }
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchCities()
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    sendRequest() {
      this.saving = true;
      switch (this.selectedGender) {
        case "Männlich":
          this.gender = '1';
          break;
        case "Weiblich":
          this.gender = '2';
          break;
        case "Divers":
          this.gender = '3';
          break;
      }
      user.saveUser(this.$store.state.token, this.firstName, this.lastName, this.email, null, this.gender, '2').then(() => {
        this.snackbarText = 'User wurde erfolgreich angelegt';
        this.saving = false;
        this.snackbar = true;
        this.goBack();
      }).catch(e => {
        if (e.response.status === 418) {
          this.saving = false;
          this.snackbar = true;
          this.snackbarText = 'E-Mail-Adresse bereits vergeben';
        } else {
          this.saving = false;
          this.snackbar = true;
          this.snackbarText = 'User konnte nicht angelegt werden';
        }

      });
    },
    saveUser() {
      if (this.$refs.form.validate()) {
        this.sendRequest()
      }
    }
  }
}
</script>
