<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/club-admin-dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Alle aktuellen Angebote
              </span>
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
      <v-row class="pa-0 ml-2 d-flex align-start">
        <ClubAdminSideMenu/>
        <v-col class="mr-15">
          <v-card class="mr-3 ml-3">
            <v-simple-table class="mt-6">
              <template v-slot:default>
                <thead>
                <tr>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Land
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Bundesland
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Stadt
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Logo
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Club Name
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Ablaufdatum
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Deal ID
                  </h3></th>
                  <th>
                    <h3 class="font-weight-bold text-capitalize">
                      Titel
                    </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Preis
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Betrag Club
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Betrag dessaso
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Verkäufer Firmenname
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Verkäufer Nachname, Vorname
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Verkäufer E-Mail
                  </h3></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="deal in deals" :key="deal.id" @mouseover="selectItem(deal)" @mouseleave="unSelectItem()">
                  <td>{{ deal.country }}</td>
                  <td>{{ deal.state }}</td>
                  <td>{{ deal.city }}</td>
                  <td>
                    <v-avatar class="mt-3 mb-3">
                      <v-img :src="(deal.clubLogo)" max-height="100" width="60"></v-img>
                    </v-avatar>
                  </td>
                  <td>{{ deal.clubName }}</td>
                  <td>{{ deal.expirationTime }}</td>
                  <td>{{ deal.id }}</td>
                  <td>{{ deal.title }}</td>
                  <td>{{ deal.price }}€</td>
                  <td>{{ deal.donationClub }}€</td>
                  <td>{{deal.donationSystem }}€</td>
                  <td>{{ deal.companyName }}</td>
                  <td>{{ deal.vendorName }}</td>
                  <td>{{ deal.userEmail }}</td>
                  <td style="width: 35px">
                    <div class="text-center">
                      <v-dialog
                          v-model="dialog"
                          width="500"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="selectedRow !== deal" @click="selectDealForDialog"
                                 v-on="on"
                                 v-bind="attrs"
                                 width="35"
                                 plain
                                 height="35"
                                 class="ma-2"
                                 ripple
                                 outlined
                                 color="#00B51A"
                          >
                            <v-icon size="25">mdi-delete</v-icon>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title class="headline green" style="color: white">
                            Hinweis
                          </v-card-title>

                          <v-card-text class="mt-3">
                            Soll dieses Angebot wirklich gelöscht werden?
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green"
                                text
                                @click="dialog = false"
                            >
                              Nein
                            </v-btn>
                            <v-btn
                                color="green"
                                text
                                @click="deleteOffer"
                            >
                              Ja
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </td>

                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-pagination
                color="#00B51A"
                v-model="page"
                :length="lastPage"
                @input="fetchDeals(page)"
            ></v-pagination>
          </v-card>
          <v-snackbar
              v-model="snackbar"
          >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>

        </v-col>
      </v-row>
      <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import deal from "@/api/requests/deal.js";
import CustomFooter from "@/components/CustomFooter";
import ClubAdminSideMenu from "@/components/ClubAdminSideMenu";

export default {
  components: {ClubAdminSideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      deals: [],
      page: 1,
      lastPage: 1,
      snackbarText: '',
      snackbar: false,
      dialog: null,
      searchQuery: '',
      selectedCountry: 83,
      countries: [],
      selectedState: null,
      states: [],
      selectedCity: null,
      cities: [],
      error: null,
      selectedRow: null,
      selectedDeal: null,
    }
  },
  created() {
    this.fetchDeals(1);
  },
  methods: {
    selectDealForDialog() {
      this.selectedDeal = this.selectedRow;
    },
    deleteOffer(){
      deal.declineDeal(this.$store.state.token, this.selectedDeal.id).then(response=>{
        console.log(response);
        this.fetchDeals(1);
        this.dialog = false;
      }).catch(e => {
        console.log(e.response);
        this.dialog = false;
        this.snackbar = true;
        this.snackbarText = 'Angebot konnte nicht abgelehnt werden.'
        if (e.response.status === 401) {
          this.$router.push('/login');
        }
      });
    },
    selectItem(deal) {
      this.selectedRow = deal
    },
    unSelectItem() {
      this.selectedRow = null
    },
    formatDateTime(date){
      if(date==null){
        return null;
      }
      return new Date(date).toLocaleDateString(
        'de-de',
        {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }
      );
    },
    fetchDeals(page) {
      this.loading = true
      deal.getAllForClub(this.$store.state.token, page, 'active').then(response => {
        this.lastPage = response.meta.last_page;
        this.deals = response.data.map(deal => ({
          country: 'Deutschland',
          state: deal.user.club.city.state,
          city: deal.user.club.city.name,
          clubName: deal.user.club.name,
          clubLogo: deal.user.club.logo_url,
          title: deal.title,
          id: deal.id,
          price: deal.price.toFixed(2).replace('.', ','),
          donationClub: deal.donation_club.toFixed(2).replace('.', ','),
          donationSystem: deal.donation_system.toFixed(2).replace('.', ','),
          vendorName: deal.user.last_name + ', ' + deal.user.first_name,
          companyName: deal.user.company_name == null ? '' : deal.user.company_name,
          expirationTime: this.formatDateTime(deal.expiration_time),
          userID: deal.user.id,
          userEmail: deal.user.email,
        }));
      }).catch(e => {
        console.log(e.response);
        if (e.response.status === 401) {
          this.$router.push('/login');
        }
      });
    },
  }

}
</script>