<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Datenschutzerkärung
              </span>
          </div>

        </v-row>
      </v-flex>
    </v-app-bar>

    <v-layout justify-space-between column>
      <v-row class="pa-0 d-flex align-start">
        <SideMenu/>
        <html class="ml-13 mt-10 mb-10">
        <head>
          <title>New Page</title>
        </head>
        <body>
        <p><strong>&nbsp;</strong></p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>DATENSCHUTZ NACH DSGVO</strong></p>
        <p>Wir freuen uns, dass Sie die club4ever App anwenden und bedanken uns für Ihr Interesse. Der Schutz Ihrer
          Privatsphäre bei der Nutzung der club4ever App ist uns wichtig. Nach der EU-Datenschutz-Grundverordnung
          (DSGVO) sind wir verpflichtet, Sie darüber zu informieren, zu welchem Zwecke wir Ihre Daten erheben, speichern
          oder weiterleiten. Der Information können Sie auch entnehmen, welche Rechte Sie bezüglich des Datenschutzes
          haben.</p>
        <p>&nbsp;</p>
        <p><strong>ZWECK DER DATENVERARBEITUNG</strong></p>
        <p>Die Datenverarbeitung erfolgt aufgrund gesetzlicher Vorgaben. Hierzu verarbeiten wir Ihre personenbezogenen
          Daten, insbesondere Ihre persönlichen Angaben sowie die uns übermittelten Bildinhalte.</p>
        <p>&nbsp;</p>
        <p><strong>EMPFÄNGER IHRER DATEN</strong></p>
        <p>Wir übermitteln Ihre personenbezogenen Daten nur dann an Dritte, wenn dies gesetzlich erlaubt ist oder Sie
          eingewilligt haben.</p>
        <p>&nbsp;</p>
        <p><strong>SPEICHERUNG IHRER DATEN</strong></p>
        <p>Ihre personenbezogenen Daten werden nur so lange aufbewahrt, wie dies für die Durchführung der
          Dienstleistung, der Produktentwicklung, -erweiterung und -erhaltung erforderlich ist.</p>
        <p>&nbsp;</p>
        <p><strong>EXTERNE LINKS</strong></p>
        <p>Zu Ihrer optimalen Information finden Sie auf unseren Seiten Links, die auf Seiten Dritter verweisen. Soweit
          diese nicht offensichtlich erkennbar sind, weisen wir Sie darauf hin, dass es sich um einen externen Link
          handelt. Wir haben keinerlei Einfluss auf den Inhalt und die Gestaltung dieser Seiten anderer Anbieter. Die
          Garantien dieser Datenschutzerklärung gelten daher selbstverständlich dort nicht.</p>
        <p>&nbsp;</p>
        <p><strong>IHRE RECHTE</strong></p>
        <p>Sie haben das Recht, über die Sie betreffenden personenbezogenen Daten Auskunft zu erhalten. Auch können Sie
          die Berichtigung unrichtiger Daten verlangen. Darüber hinaus steht Ihnen unter bestimmten Voraussetzungen das
          Recht auf Löschung von Daten, das Recht auf Einschränkung der Datenverarbeitung sowie das Recht auf
          Datenübertragbarkeit zu. Die Verarbeitung Ihrer Daten erfolgt auf Basis von gesetzlichen Regelungen. In jedem
          Fall der Überlassung ihrer personenbezogenen Daten benötigen wir Ihr Einverständnis. In diesen Fällen haben
          Sie das Recht, die Einwilligung für die zukünftige Verarbeitung zu widerrufen. Sie haben ferner das Recht,
          sich bei der zuständigen Aufsichtsbehörde für den Datenschutz zu beschweren, wenn Sie der Ansicht sind, dass
          die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.</p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>INHALTLICH VERANTWORTLICHER GEMÄß ART. 24 DSGVO</strong></p>
        <p>dessaso UG (haftungsbeschränkt), Frillendorfer Str. 150 c, 45139 Essen, Email: info@dessaso.de</p>
        <p>&nbsp;</p>
        </body>
        </html>
      </v-row>
      <v-footer class="mb-0 pa-0" height="50" style="background-color: #00B51A;">
        <v-layout justify-center>
          <v-btn class="text-none" @click="showImprint"
                 text
                 color="white"
          >
            Impressum
          </v-btn>
          <v-btn class="text-none" @click="showDataProtection"
                 text
                 color="white"
          >
            <span>
              Datenschutzerklärung
            </span>

          </v-btn>
        </v-layout>
      </v-footer>
    </v-layout>

  </v-container>
</template>

<script>
import SideMenu from "@/components/SideMenu";

export default {
  components: {SideMenu},
  data() {
    return {
      loading: false,
      clubs: [],
      page: 1,
      lastPage: 1,
      error: null,
      selectedItem: null
    }
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchClubs(1)
    }
  },
  methods: {
    showImprint() {
      this.$router.push('/imprint');
    },
  }

}
</script>