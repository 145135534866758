<template>
  <v-flex>
    <v-container fluid class="d-flex pb-0 align-start fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A">
        <v-flex>
          <v-row>
            <div class="pa-0 d-flex align-start">
              <router-link to="/club-admin-dashboard">
                <v-img
                    :src="require('../../../assets/logo_club4ever.svg')"
                    class="ma-0"
                    contain
                    max-height="50"
                    max-width="190"
                />
              </router-link>
            </div>
            <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Club-News bearbeiten
              </span>
            </div>
          </v-row>
        </v-flex>
      </v-app-bar>
      <v-layout justify-space-between column>
        <v-row class="mt-16 ml-14">
          <SideMenu v-if="role===1"/>
          <ClubAdminSideMenu v-else/>
          <v-col>
            <h1 v-if="loading">
              <v-progress-circular class="mt-5"
                                   indeterminate
                                   color="#00B51A"
              ></v-progress-circular>
            </h1>
            <v-card class="mt-2 ml-2" width="800" elevation="1" v-else>
              <v-col>
                <span class="font-weight-regular ml-2">
                  Bild
                </span>
                <v-row>
                  <v-avatar width="80" height="150" tile class="mt-4 mb-10 ml-5 mr-4" color="#ededed">
                    <v-img v-if="base64NewsImage!=null" min-width="80"
                           max-width="80" min-height="150" v-bind:src="(this.base64NewsImage)"/>
                    <v-img v-else-if="base64NewsImage_URL!=null" min-width="80"
                           max-width="80" min-height="150" v-bind:src="(this.base64NewsImage_URL)"/>
                    <div v-else class="text-center">
                      <v-icon large color="#00B51A">mdi-image-outline
                      </v-icon>
                    </div>
                  </v-avatar>


                  <FileUploader class="mt-16 ml-3 align-center"
                                @input="onLoadImage"
                                ref="upload"
                                title="Upload"
                                :accept="accept"
                                :multiple="multiple"
                                :maxfiles="1"
                                @update:filename="
			uploadNewsImageName = $event;
			$emit('update:filename', uploadNewsImageName);
		"
                  >

                  </FileUploader>
                </v-row>

                <v-form ref="form" class="mx-2" lazy-validation>


                <span class="font-weight-regular">
                  Titel
                </span>
                  <v-text-field
                      outlined
                      color="#00B51A"
                      v-model="headline"
                      :rules="[v => !!v || 'Bitte gib einen Titel für deine Club News ein']"
                      required
                  >
                  </v-text-field>


                  <span class="font-weight-regular">
               Beschreibung
              </span>
                  <v-col class="pa-0"
                         cols="12"
                  >
                    <v-textarea
                        outlined
                        color="#00B51A"
                        v-model="content"
                        :rules="[v => !!v || 'Bitte gib eine Beschreibung für deine Club News ein']"
                        required
                    />
                  </v-col>

                  <div class="text-center">
                    <v-btn class="mt-10 mb-5" style="background:#00B51A;color:white"
                           height="45"
                           width="200"
                           elevation="2"
                           :loading="saving"
                           v-on:click="saveClub">
                      <h3 class="font-weight-medium text-capitalize">
                        Speichern
                      </h3>
                    </v-btn>
                  </div>

                  <v-snackbar
                      v-model="snackbar"
                  >
                    {{ snackbarText }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                          color="white"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>

                </v-form>
              </v-col>
            </v-card>

          </v-col>

        </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>

</template>
<script>
import FileUploader from "@/components/FileUploader";
import club from "@/api/requests/club";
import imageUtils from "@/util/image_utils";
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import ClubAdminSideMenu from "@/components/ClubAdminSideMenu";
import news from "@/api/requests/news";

export default {
  components: {FileUploader, SideMenu, CustomFooter, ClubAdminSideMenu},
  data() {
    return {
      loading: false,
      saving: false,
      error: null,
      newsID: '',
      headline: '',
      content: '',
      uploadNewsImageName: null,
      base64NewsImage: null,
      base64NewsImage_URL: null,
      snackbarText: '',
      snackbar: false,
      role: null,
      routerLink: null,
    }
  },
  props: {
    document: {},
    instantUpload: {
      default: false,
    },
    accept: {default: 'image/png, image/jpeg'},
    multiple: {default: false},
    maxfiles: {default: false},
  },
  created() {
    this.role = this.$store.state.role
    if(this.role===1){
      this.routerLink = "/dashboard"
    }else{
      this.routerLink = "/club-admin-dashboard"
    }
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    }
    this.getNews(this.$route.params.id)
  },
  methods: {
    getNews(newsID){
      this.loading = true
      news.getOneClubNews(this.$store.state.token,newsID).then( response => {
        console.log(response.data)
        this.newsID = response.data.id;
        this.headline = response.data.headline;
        this.content = response.data.content;
        this.base64NewsImage_URL = response.data.image_url;
        this.loading = false;
          }).catch(e => {
            console.log(e.response)
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    onLoadImage($event) {
      this.dialog = false;
      this.base64NewsImage = $event;
      this.$emit('input', $event);
    },
    async saveClub() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        try {
          let response = await news.updateClubNews(
            this.$store.state.token,
            this.newsID,
            this.headline,
            this.content
          );
          this.saving = false;
          this.snackbarText = "Club News erfolgreich geändert";
          this.snackbar = true;
          this.headline = "";
          this.content = "";
          this.$refs.form.resetValidation();
          if (this.base64NewsImage != null) {
            let url = `club-news-image/upload/${response.data.id}`;
            try {
              let resizedImage = await imageUtils.resizeImage(this.base64NewsImage, 1000);
              try {
                await club.uploadImage(
                  resizedImage,
                  this.uploadNewsImageName,
                  url
                );
                this.base64NewsImage = null;
                this.uploadNewsImageName = null;
                this.$refs.upload.clearTitle();
              } catch (e) {
                console.error(e);
              }
            } catch (err) {
              console.error(err);
            }
          }
          this.goBack();
        } catch (e) {
          console.log(e);
          this.snackbar = true;
          this.saving = false;
          this.snackbarText = "Club News konnten nicht geändert werden";
        }
      }
    },
  },
};
</script>
