import Repository from "../repository";

export default {
    async getAll(token, page) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const response = await Repository.get(`get-club-events?paginate=25&page=${page}`, config);
        return response.data;
    },
    async saveClubEvent(token, headline, content, location, start, end, min_member, max_member, ) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const body = {title: headline, body: content, location: location, starts_at: start, ends_at: end, min_member: min_member, max_member: max_member};
        const response = await Repository.post(`new-club-event`, body, config);
        return response.data;
    },
    async copyClubEvent(token, id, headline, content, location, start, end, min_member, max_member) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const body = {title: headline, body: content, location: location, starts_at: start, ends_at: end, min_member: min_member, max_member: max_member};
        const response = await Repository.post(`duplicate-event/${id}`, body, config);
        return response.data;
    },
    async updateClubEvent(token, eventID, headline, content, location, start, end, min_member, max_member, ) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const body = {title: headline, body: content, location: location, starts_at: start, ends_at: end, min_member: min_member, max_member: max_member};
        const response = await Repository.post(`change-club-event/${eventID}`, body, config);
        return response.data;
    },
    async fetchClubEventByID(token, id){
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const response = await Repository.get(`get-club-event/${id}`, config);
        return response.data;
    },
    async deleteClubEvent(token, id){
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const response = await Repository.delete(`delete-club-event/${id}`, config);
        return response.data;
    },
    async uploadClubEventImg(logo, fileName, url){
            var arr = logo.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            var file = new File([u8arr], fileName, {type: mime});

            var formData = new FormData();
            formData.append('files[]', file);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            const response = await Repository.post(url, formData, config);
            return response.data.data;
        }
}