<template>
  <v-flex>
    <v-container fluid class="d-flex align-start pb-0 fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A"
      >
        <div class="d-flex align-center">
          <router-link to="/club-admin-dashboard">
            <v-img
                :src="require('../assets/logo_club4ever.svg')"
                class="ma-0"
                contain
                max-height="50"
                max-width="190"
            />
          </router-link>
        </div>
        <span class="font-weight-medium" style="color: white;font-size: x-large">
            Club bearbeiten
              </span>
        <v-spacer/>

      </v-app-bar>
      <v-layout justify-space-between column>
      <v-row class="mt-16 ml-14">
        <ClubAdminSideMenu/>
        <EditClubContainer/>
      </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>

</template>
<script>

import EditClubContainer from "@/components/EditClubContainer";
import ClubAdminSideMenu from "@/components/ClubAdminSideMenu";
import CustomFooter from "@/components/CustomFooter";

export default {
  components: {EditClubContainer, ClubAdminSideMenu, CustomFooter},
}
</script>