<template>
  <v-container fluid class="mt-14 ml-10 mb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/club-admin-dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Profil
              </span>
          </div>

          <v-spacer/>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
    <v-row class="pa-0 ml-2 d-flex align-start">
      <ClubAdminSideMenu/>
      <EditProfileContainer/>
    </v-row>
    <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import EditProfileContainer from "@/components/EditProfileContainer";
import ClubAdminSideMenu from "@/components/ClubAdminSideMenu";
import CustomFooter from "@/components/CustomFooter";

export default {
  components: {ClubAdminSideMenu, EditProfileContainer, CustomFooter},
}
</script>