<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Impressum
              </span>
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>

    <v-layout justify-space-between column>
      <v-row class="pa-0 d-flex align-start">
        <SideMenu/>
        <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align ml-13 mt-10 mb-10"><div class="gdlr-core-text-box-item-content" style="text-transform: none ;"><p><strong>Angaben gemäß § 5 TMG:</strong></p>
        <p>dessaso UG (haftungsbeschränkt)<br>Frillendorfer Straße 150 c<br>45139 Essen</p>
        <p>Website: <a href="https://www.dessaso.de">www.dessaso.de</a><br>E-Mail: <a href="mailto:info@dessaso.de">info@dessaso.de</a></p>
        <p>Registergericht: Amtsgericht Essen<br>Registernummer: HRB 31592</p>
        <p>Vertretungsberechtigter Geschäftsführer: Sascha Thomas<br>Telefonnummer: +49 (0)176-64914000</p>
        <p>Vertretungsberechtigte Geschäftsführerin: Despina Mörsch<br>Telefonnummer: +49 (0)173-8114027</p>
        <p>Umsatzsteuer-Identifikationsnummer<br>gemäß § 27 UStG: DE335984696</p>
        <p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong></p>
        <p>Sascha Thomas &amp; Despina Mörsch<br>Frillendorfer Straße 150 c<br>45139 Essen</p>
        <p><strong>Haftung für Inhalte:</strong></p>
        <p>Als Diensteanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte auf diesen Seiten<br>nach den allgemeinen Gesetzen verantwortlich. Nach § 8 bis 10 TMG sind wir als Diensteanbieter<br>jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen<br>oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen<br>Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt<br>der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden<br>Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
        <p><strong>Haftung für Links:</strong></p>
        <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.<br>Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden<br>zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren&nbsp;<br>zum Zeitpunkt der Verlinkung nicht erkennbar.<br>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte<br>einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir<br>derartige Links umgehend entfernen.</p>
        <p><strong>Urheberrecht:</strong></p>
        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der<br>Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.<br>Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter<br>beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine<br>Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis.<br>Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.&nbsp;</p>
        <p><strong>Verweis auf Streitschlichtungsbehörde</strong>:</p>
        <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit.<br>Die Plattform finden Sie unter <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>.<br>Unsere E-mail-Adresse finden Sie oben im Impressum.</p>
        <p><strong>Urhebernachweis der verwendeten Bilder</strong>:</p>
        <p>Urheber: Adobe Stock #98941904<br>Urheber: Adobe Stock #157422032<br>Urheber: Adobe Stock #168029537<br>Urheber: Adobe Stock #305898516<br>Urheber: Adobe Stock #344782137</p>
      </div></div>">
      </v-row>
      <v-footer class="mb-0 pa-0" height="50" style="background-color: #00B51A;">
        <v-layout justify-center>
          <v-btn class="text-none" @click="showImprint"
                 text
                 color="white"
          >
            Impressum
          </v-btn>
          <v-btn class="text-none" @click="showDataProtection"
                 text
                 color="white"
          >
            <span>
              Datenschutzerklärung
            </span>

          </v-btn>
        </v-layout>
      </v-footer>
    </v-layout>

  </v-container>
</template>

<script>
import SideMenu from "@/components/SideMenu";

export default {
  components: {SideMenu},
  data() {
    return {
      loading: false,
      clubs: [],
      page: 1,
      lastPage: 1,
      error: null,
      selectedItem: null
    }
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchClubs(1)
    }
  },
  methods: {
    showDataProtection(){
      this.$router.push('/data-protection');
    },
  }

}
</script>