<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/club-admin-dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Alle verkauften Deals
              </span>
          </div>

        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
    <v-row class="pa-0 ml-2 d-flex align-start">
      <ClubAdminSideMenu/>
      <v-col class="mr-15">
        <v-card class="mr-3 ml-3">
          <v-simple-table class="mt-6">
            <template v-slot:default>
              <thead>
              <tr>
                <th><h3 class="font-weight-bold text-capitalize">
                  ID
                </h3></th>
                <th>
                  <h3 class="font-weight-bold text-capitalize">
                    Titel
                  </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Preis
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Verkaufsdatum
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Verkäufer Firmenname
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Verkäufer Nachname, Vorname
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Verkäufer E-Mail
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Typ
                </h3></th>
                <th/>
              </tr>
              </thead>
              <tbody>
              <tr v-for="deal in deals" :key="deal.id">
                <td>{{ deal.id }}</td>
                <td>{{ deal.title }}</td>
                <td>{{ deal.price }}€</td>
                <td>{{ deal.sold }}</td>
                <td>{{ deal.companyName }}</td>
                <td>{{ deal.userName }}</td>
                <td>{{ deal.userEmail }}</td>
                <td>{{ deal.dealType }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-pagination
              color="#00B51A"
              v-model="page"
              :length="lastPage"
              @input="fetchDeals()"
          ></v-pagination>
        </v-card>
      </v-col>
    </v-row>
    <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import deal from "@/api/requests/deal.js";
import ClubAdminSideMenu from "@/components/ClubAdminSideMenu";
import CustomFooter from "@/components/CustomFooter";

export default {
  components: {ClubAdminSideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      deals: [],
      page: 1,
      lastPage: 1,
      error: null,
    }
  },
  created() {
    this.fetchDeals()
  },
  methods: {
    formatDate(date){
      if(date==null){
        return null;
      }
      return new Date(date).toLocaleDateString(
        'de-de',
        {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }
      );
    },
    fetchDeals() {
      this.loading = true
      deal.getAllForClub(this.$store.state.token, this.page, 'sold').then(response => {
        this.lastPage = response.meta.last_page;
        this.deals = response.data.map(deal => ({
          title: deal.title,
          id: deal.id,
          price: deal.price.toFixed(2).replace('.', ','),
          sold: this.formatDate(deal.sold),
          userID: deal.user.id,
          userEmail: deal.user.email,
          companyName: deal.user.company_name == null ? '' : deal.user.company_name,
          userName: deal.user.last_name + ', ' + deal.user.first_name,
          dealType: deal.deal_type === 1 ? 'Angebot' : 'Gesuch',
        }));
      }).catch(e => {
        console.log(e.response);
        if (e.response.status === 401) {
          this.$router.push('/login');
        }
      });
    },
  }

}
</script>