<template>
  <v-flex>
    <v-container fluid class="d-flex align-start pb-0 fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A"
      >
        <div class="d-flex align-center">
          <router-link to="/dashboard">
            <v-img
                :src="require('../assets/logo_club4ever.svg')"
                class="ma-0"
                contain
                max-height="50"
                max-width="190"
            />
          </router-link>
        </div>
        <span class="font-weight-medium" style="color: white;font-size: x-large">
            Neuer User
              </span>
        <v-spacer/>
      </v-app-bar>
      <v-layout justify-space-between column>
      <v-row class="mt-16 ml-14">
        <SideMenu/>
        <v-col class="mt-4">
          <h1 v-if="loading">
            <v-progress-circular class="mt-5"
                                 indeterminate
                                 color="#00B51A"
            ></v-progress-circular>
          </h1>
          <v-card class="ma-2 ml-2" width="800" elevation="1" v-else>
            <v-row>
              <v-col class="ml-6 mt-3">
                <div class="pa-2">
                <span class="font-weight-bold">
                  ID
                </span>
                </div>
                <div class="pa-2">
                <span class="font-weight-bold">
                  Titel
                </span>
                </div>
                <div class="pa-2">
                 <span class="font-weight-bold">
                  Eingestellt am
                </span>
                </div>
                <div class="pa-2">
                 <span class="font-weight-bold">
                  Verkäufer Name
                </span>
                </div>
                <div class="pa-2">
                 <span class="font-weight-bold">
                  Verkäufer E-Mail
                </span>
                </div>
                <div class="pa-2">
                 <span class="font-weight-bold">
                  Systemgebühr
                </span>
                </div>
                <div class="pa-2 mt-16">
                <span class="font-weight-bold">
                  Bezahlt am
                </span>
                </div>
              </v-col>
              <v-col class="ml-6 mt-3">
                <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                  {{ deal.id }}
                </span>
                </div>
                <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                  {{ deal.title }}
                </span>
                </div>
                <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                  {{ deal.created_at }}
                </span>
                </div>
                <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                  {{ deal.user.first_name }} {{ deal.user.last_name }}
                </span>
                </div>
                <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                  {{ deal.user.email }}
                </span>
                </div>
                <div class="pa-2 ml-1">
                <span class="font-weight-regular">
                  {{ deal.platform_fee }} €
                </span>
                </div>
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="pa-2 mr-7 mt-14"
                                  v-model="dateFormatted"
                                  color="#00B51A"
                                  prepend-icon="mdi-calendar"
                                  v-bind="attrs"
                                  v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="date"
                      no-title
                      color="#00B51A"
                      locale="de-GR"
                      @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <div class="text-center">
              <v-btn class="mt-10 mb-10" style="background:#00B51A;color:white"
                     height="45"
                     width="200"
                     elevation="2"
                     :loading="saving"
                     v-on:click="updateDeal">
                <h3 class="font-weight-medium text-capitalize">
                  Speichern
                </h3>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>

</template>
<script>
import SideMenu from "@/components/SideMenu";
import deal from "@/api/requests/deal";
import CustomFooter from "@/components/CustomFooter";

export default {
  components: {SideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      saving: false,
      deal: null,
      snackbarText: '',
      snackbar: false,
      paidAt: null,
      modal: false,
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      menu1: false,
    }
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchDetail()
    }
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    fetchDetail() {
      this.loading = true
      deal.dealDetail(this.$store.state.token, this.$route.params.id).then(response => {
        this.deal = response.data;
        this.deal.created_at = new Intl.DateTimeFormat('de-de', {
          month: "numeric",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric"
        }).format(deal.created_at);
        if(response.data.paid!=null){
          this.dateFormatted = this.formatDate(response.data.paid.substr(0, 10))
          this.date = response.data.paid.substr(0, 10);
        }
        this.loading = false;
      }).catch(e =>{
        console.log(e);
        this.snackbar = true;
        this.snackbarText = 'Es ist ein Fehler aufgetreten';
      });
    },
    updateDeal() {
      this.saving = true;
      let isoDate = new Date(this.date).toISOString();
      deal.markAsPaid(this.$store.state.token, this.$route.params.id, isoDate).then(() =>{
        this.saving = true;
        this.$router.push('/all-deals');
      }).catch(e =>{
        console.log(e);
        this.saving = true;
        this.snackbar = true;
        this.snackbarText = 'Es ist ein Fehler aufgetreten';
      });
    }
  }
}
</script>
