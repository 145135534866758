<template>
  <v-flex>
    <v-container fluid class="d-flex pb-0 align-start fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A"
      >
        <div class="d-flex align-center">
          <v-row>
            <v-icon size="30" class="ml-1" @click="goBack" style="color: white"
            >mdi-arrow-left
            </v-icon>
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </v-row>
        </div>
        <span class="font-weight-medium" style="color: white;font-size: x-large">
          Rechnungspositionen für {{recipient.name}}
        </span>
        <v-spacer/>
      </v-app-bar>
      <v-layout justify-space-between column>
        <v-row class="mt-16 ml-14">
          <SideMenu/>
          <v-col>
            <h1 v-if="loading">
              <v-progress-circular class="mt-5"
                                   indeterminate
                                   color="#00B51A"
              ></v-progress-circular>
            </h1>
            <v-card class="mt-2 ml-2" width="720" elevation="1" v-else>
              <v-row class="mt-2 ml-2">
                <v-col class="mt-3">
                  <h3>
                    {{ setTitle() }}
                  </h3>
                </v-col>
                <v-col>
                  <v-btn class="mt-2 mb-5" style="background:#00B51A;color:white"
                         height="45"
                         elevation="2"
                         v-on:click="single = !single">
                    <h3 class="font-weight-medium text-capitalize">
                    {{ setBtnTitle() }}
                    </h3>
                  </v-btn>
                </v-col>

              </v-row>
              <v-col>

                <v-col v-if="single">
                  <v-form ref="form" class="mx-2" lazy-validation>

                    <v-row>
                      <v-col>
                      <span class="font-weight-regular">
                        Dienstleistung
                      </span>
                        <v-autocomplete
                            v-model="selectedService"
                            :items="services"
                            no-data-text="Keine Dienstleistungen gefunden"
                            outlined
                            color="blue-grey lighten-2"
                            item-text="name"
                            return-object
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                      <span class="font-weight-regular">
                      Preis
                      </span>
                        <v-text-field
                            outlined
                            color="#00B51A"
                            v-model="selectedService.price"
                            :disabled = "true"
                            suffix="€"
                            locale="de-De"
                            type="text"
                            required
                        >
                        </v-text-field>
                      </v-col>

                    </v-row>

                    <v-row>
                      <v-col>
                      <span class="font-weight-regular">
                        Menge
                      </span>
                        <v-text-field
                            outlined
                            color="#00B51A"
                            v-model="quantity"
                            :rules="[v => !!v,
                          v => {
                              const pattern =  new RegExp('^[0-9]*$');
                              var validation = pattern.test(v);
                              if (validation) return true
                              else return 'Bitte gib einen korrekten Menge an'
                            }
                          ]"
                            required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <div class="text-center">
                          <v-btn class="mt-7 mb-5" style="background:#00B51A;color:white"
                                 height="45"
                                 width="200"
                                 elevation="2"
                                 :loading="saving"
                                 v-on:click="saveBillItem">
                            <h3 class="font-weight-medium text-capitalize">
                              Speichern
                            </h3>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>

                    <v-snackbar
                        v-model="snackbar"
                    >
                      {{ snackbarText }}

                      <template v-slot:action="{ attrs }">
                        <v-btn
                            color="white"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                          Close
                        </v-btn>
                      </template>
                    </v-snackbar>

                  </v-form>
                </v-col>
                <v-col v-else>
                  <v-simple-table class="mt-5">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th><h3 class="font-weight-bold text-capitalize">
                          Dienstleistung
                        </h3></th>
                        <th><h3 class="font-weight-bold text-capitalize">
                          Preis Pro
                        </h3></th>
                        <th><h3 class="font-weight-bold text-capitalize">
                          Gesamtpreis
                        </h3></th>
                        <th><h3 class="font-weight-bold text-capitalize">
                          Menge
                        </h3></th>
                        <th/>
                      </tr>
                      </thead>
                      <tbody id="lbody">
                      <tr v-for="(billItem, index) in billItems" :key="billItem.id" @mouseover="selectItem(billItem)" @mouseleave="unSelectItem()">
                        <td>{{ billItem.service.name }}</td>
                        <td>{{ parseFloat(billItem.service.price).toLocaleString('de-DE', { minimumFractionDigits: 2 }) }}€</td>
                        <td>{{ (parseFloat(billItem.service.price) * parseFloat(billItem.quantity)).toLocaleString('de-DE', { minimumFractionDigits: 2 }) }}€</td>
                        <td>
                          <v-text-field
                              v-model="billItem.quantity"
                              :rules="[v => !!v,
                          v => {
                              const pattern =  new RegExp('^[0-9]*$');
                              var validation = pattern.test(v);
                              if (validation) return true
                              else return 'Bitte gib einen korrekten Menge an'
                            }
                          ]"
                          >
                          </v-text-field>
                        </td>
                        <td style="width: 35px">
                          <div class="text-center"
                          >
                            <v-btn :disabled="selectedItem !== billItem" @click.stop="removeBillItem(index)"
                                   width="35"
                                   plain
                                   height="35"
                                   class="ma-2"
                                   ripple
                                   outlined
                                   color="#00B51A"
                            >
                              <v-icon size="25">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-col>
                    <div class="text-center">
                      <v-btn class="mt-7 mb-5" style="background:#00B51A;color:white"
                             height="45"
                             width="200"
                             elevation="2"
                             :loading="saving"
                             v-on:click="sendBulkBillItems">
                        <h3 class="font-weight-medium text-capitalize">
                          Speichern
                        </h3>
                      </v-btn>
                    </div>
                  </v-col>
                </v-col>
              </v-col>


            </v-card>

          </v-col>

        </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>

</template>
<script>
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import recipient from "@/api/requests/recipient";
import service from "@/api/requests/service";
import billItem from "@/api/requests/billItem";

export default {
  components: {SideMenu, CustomFooter},
  data() {
    return {
      edit: false,
      single: true,
      billItemId: null,
      loading: false,
      saving: false,
      error: null,
      recipient: {},
      services: [],
      billItems: [],
      selectedService: '',
      quantity: '',
      selectedBillItem:null,
      selectedItem: null,
      snackbarText: '',
      snackbar: false
    }
  },
  created() {
    // token verification
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
      return false;
    }

    this.single = parseInt(this.$route.params.empty) !== 1;

    // init
    this.fetchServices();
    this.billItemId = this.$route.params.billItemId ?? null
    this.fetchRecipient();
    this.edit = false;
    // use component to edit a customer
    if (this.billItemId !== null) {
      this.fetchBillItem();
      this.edit = true;
    }

    if(this.edit === false){
      this.fetchBillItems();
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async fetchBillItems() {
      let date = new Date();
      let month = date.getMonth();
      let year = date.getFullYear();
      console.log(month);
      console.log(year);
      billItem.getAll(this.$store.state.token, 1, this.$route.params.recipientId, month, year).then(response => {
        console.log(response.data);
        this.billItems = response.data.map(billitem => ({
          service: billitem.service,
          quantity: billitem.quantity
        }));
      });

      if(this.billItems.length === 0){
        this.single = true;
      }
    },
    async fetchServices() {
      service.getAll(this.$store.state.token)
          .then(response => {
            this.services = response.data.map(service => ({
              id: service.id ?? '',
              name: service.name ?? '',
              price: service.price.toFixed(2).toString().replace('.', ',')
            }));
          })
    },
    async fetchRecipient() {
      recipient.getRecipientByID(this.$store.state.token, this.$route.params.recipientId)
          .then((result) => {
            this.recipient = result.data;
          })
    },
    async fetchBillItem() {
      billItem.getBillItemByID(this.$store.state.token, this.billItemId)
          .then(response => {
            console.log(response);
            this.selectedService = response.data.service ?? null;
            this.quantity = response.data.quantity ?? '';
          })
          .catch(e => {console.log(e)})
      ;
    },
    async saveBillItem() {
      //validation
      if (!this.$refs.form.validate()) return false;
      if (this.selectedService === '') {
        this.snackbar = true;
        this.snackbarText = 'Bitte wähle eine Dienstleistung aus';
        return false;
      }
      //edit existing customer - pass if not edit
      if(this.edit) {
        this.saving = true;
        billItem.update(
            this.$store.state.token,
            this.billItemId,
            this.selectedService.id,
            this.quantity,
        )
          .then((result) => {
            console.log("saved");
            console.log(result);
            this.snackbarText = 'Rechnungsposition wurde erfolgreich gespeichert';
            this.saving = false;
            this.snackbar = true;
            this.goBack();
            return true;
          })
          .catch(e => {
            console.log(e);
            this.snackbar = true;
            this.snackbarText = 'Rechnungsposition konnte nicht gespeichert werden';
            return false;
          })
      } else {
        //saving new bill item
        this.saving = true;
        billItem.saveBillItem(
          this.$store.state.token,
          this.recipient.id,
          this.selectedService.id,
          this.quantity,
        )
          .then((result) => {
            console.log(result);
            this.snackbarText = 'Rechnungsposition wurde erfolgreich angelegt';
            this.saving = false;
            this.snackbar = true;
            this.$router.push(`/bill-items/` + this.recipient.id);
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;
            this.snackbarText = 'Empfänger konnte nicht angelegt werden';
          });
      }

    },
    async sendBulkBillItems(){
      if(this.billItems.length === 0){
        console.log("billItems are empty");
        return;
      }
      let bulkBillItems = [];
      this.billItems.forEach(billItem => {

        let item = {
          recipientId: parseInt(this.$route.params.recipientId),
          serviceId: billItem.service.id,
          quantity: billItem.quantity,
        };

        bulkBillItems.push(item)
      })
      console.log(bulkBillItems);
    billItem.saveBulkBillItems(this.$store.state.token, bulkBillItems).then(response => {
          console.log(response);
          this.goBack();
        }
    )
    },
    selectItem(billItem) {
      this.selectedItem = billItem;
    },
    unSelectItem() {
      this.selectedItem = null;
    },
    selectionForDialog() {
      this.dialog = true;
      this.selectedBillItem = this.selectedItem;
    },
    removeBillItem(index){
      this.billItems.splice(index, 1);
    },
    setBtnTitle(){
      return this.single ? "vom Vormonat" : "Einzel";
    },
    setTitle(){
      return this.single ? "Einzelne Rechnungsposition" :  "Rechnungspositionen vom Vormonat";
    }
  }
}
</script>
