<template>
  <v-flex>
    <v-container fluid class="d-flex pb-0 align-start fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A"
      >
        <div class="d-flex align-center">
          <v-row>
            <v-icon size="30" class="ml-1" @click="goBack" style="color: white"
            >mdi-arrow-left
            </v-icon>
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </v-row>


        </div>
        <span class="font-weight-medium" style="color: white;font-size: x-large">{{title}}</span>
        <v-spacer/>

      </v-app-bar>
      <v-layout justify-space-between column>
        <v-row class="mt-16 ml-14">
          <SideMenu/>
          <v-col>
            <h1 v-if="loading">
              <v-progress-circular class="mt-5"
                                   indeterminate
                                   color="#00B51A"
              ></v-progress-circular>
            </h1>
            <v-card class="mt-2 ml-2" width="1000" elevation="1" v-else>
              <v-col>
                <v-form ref="form" class="mx-2" lazy-validation>

                  <v-row>
                    <v-col>
                      <span class="font-weight-regular">
                        Kundennummer
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="customerNo"
                          :rules="[v => !!v || 'Bitte gib die Kundennummer des Kunden ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <span class="font-weight-regular">
                      Name des Kunden
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="customerName"
                          :rules="[v => !!v || 'Bitte gib eine Website für deinen Club ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>



                  <div class="text-center">
                    <v-btn class="mt-10 mb-5" style="background:#00B51A;color:white"
                           height="45"
                           width="200"
                           elevation="2"
                           :loading="saving"
                           v-on:click="saveCustomer">
                      <h3 class="font-weight-medium text-capitalize">
                        Speichern
                      </h3>
                    </v-btn>
                  </div>

                  <v-snackbar
                      v-model="snackbar"
                  >
                    {{ snackbarText }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                          color="white"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>

                </v-form>
              </v-col>
            </v-card>

          </v-col>

        </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>

</template>
<script>
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import customer from "@/api/requests/customer";

export default {
  components: {SideMenu, CustomFooter},
  data() {
    return {
      title: '',
      edit: false,
      twCustomerId: null,
      loading: false,
      saving: false,
      cities: [],
      selectedCity: null,
      selectedCountry: null,
      countries: [],
      states: [],
      selectedState: null,
      error: null,
      customerNo: '',
      customerName: '',
      clubID: '',
      emailRules: [
        v => !!v || 'Bitte gib eine E-Mail-Adresse für deinen Kunden ein',
        v => /.+@.+\..+/.test(v) || 'E-Mail-Adresse muss valide sein',
      ],
      snackbarText: '',
      snackbar: false
    }
  },
  created() {
    // token verification
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
      return false;
    }
    // get customer ID
    this.twCustomerId = this.$route.params.twCustomerId

    // use component to create new customer
    if(this.twCustomerId == null) {
      this.title = "Neuen Kunde hinzufügen";
      this.edit = false;
      return true;
    }
    // use component to edit a customer
    this.fetchCustomer();
    this.edit = true;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async fetchCustomer() {
      customer.getCustomerByID(this.$store.state.token, this.twCustomerId)
        .then(response => {
          console.log(response);
          this.customerName = response.data.name ?? '';
          this.customerNo = response.data.customerNo ?? '';
          this.title = this.customerName;
        })
        .catch(e => {console.log(e)})
      ;
    },
    async saveCustomer() {
      // form validation
      if (!this.$refs.form.validate()) {
        return false;
      }
      //edit existing customer - pass if not edit
      if(this.edit) {
        this.saving = true;
        customer.update(
            this.$store.state.token,
            this.twCustomerId,
            this.customerNo,
            this.customerName,
        )
          .then((result) => {
            console.log("saved");
            console.log(result);
            this.snackbarText = 'Kunde wurde erfolgreich gespeichert';
            this.saving = false;
            this.snackbar = true;
            this.goBack();
          })
          .catch(e => {
            console.log(e);
            this.snackbar = true;
            this.snackbarText = 'Kunde konnte nicht gespeichert werden';
          })
      } else {
        //save as new customer
        this.saving = true;
        try {
          await customer.saveCustomer(
              this.$store.state.token,
              this.customerNo,
              this.customerName,
          );
          this.snackbarText = 'Kunde wurde erfolgreich angelegt';
          this.saving = false;
          this.snackbar = true;
          await this.$router.push('/all-customers');
        } catch (e) {
          console.log(e);
          this.snackbar = true;
          this.snackbarText = 'Kunde konnte nicht angelegt werden';
        }
      }
    }

  }
}
</script>
