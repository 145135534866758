import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import vuetify from './plugins/vuetify';
import router from "@/router";
import createPersistedState from 'vuex-persistedstate'
import Cookies from "js-cookie"
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'


Vue.config.productionTip = false

Vue.use(router);
Vue.use(Vuex);
Vue.use(Datetime);

const store = new Vuex.Store({
  plugins:[
      createPersistedState({
        key: "Club4EverState",
        storage: {
          getItem: (key) => Cookies.get(key),
          // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
          setItem: (key, value) => Cookies.set(key, value, { secure: true }),
          removeItem: (key) => Cookies.remove(key),
        },
      }),
  ],
  state: {
    token: '',
    role: ''
  },
  mutations: {
    saveToken (state, payload) {
      state.token = payload;
    },
    saveRole (state, payload){
      state.role = payload;
    }
  }
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
