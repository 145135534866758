import Repository from "../repository";

export default {
    async getAll(page, countryID, state, cityID, searchQuery) {
        const response = await Repository.get(`clubs?paginate=25&page=${page}&country-id=${countryID}&state=${state}&city-id=${cityID}&query=${searchQuery}`);
        return response.data;
    },
    async getAllRaw(){
        const response = await Repository.get(`clubs`);
        return response.data;
    },
    async clubDetail(clubID) {
        const response = await Repository.get(`clubs/${clubID}`);
        return response.data;
    },
    async clubDetailByToken(token) {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const response = await Repository.get(`club-by-token`, config);
        return response.data;
    },
    async saveClubNews(token, headline, content) {
        const clubData = {headline: headline, content: content};
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const response = await Repository.post(`clubNews`, clubData, config);
        return response.data;
    },
    async updateClub(name, email, website, clubID, mailingAddress, clubZipCode, addressCity, invoiceRecipientFirstName, invoiceRecipientLastName, invoiceRecipientEmail, token) {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const clubData = {name: name, email: email, website: website, mailing_address: mailingAddress, zip_code: clubZipCode, address_city: addressCity,
            invoice_recipient_first_name: invoiceRecipientFirstName, invoice_recipient_last_name: invoiceRecipientLastName, invoice_recipient_email: invoiceRecipientEmail};
            const response = await Repository.put(`clubs/${clubID}`, clubData, config);
            return response.data;
    },
    async saveClub(name, email, website, cityID, mailingAddress, clubZipCode, addressCity, invoiceRecipientFirstName, invoiceRecipientLastName, invoiceRecipientEmail) {
        const clubData = {name: name, email: email, city_id: cityID, website: website, mailing_address: mailingAddress, zip_code: clubZipCode,
            address_city: addressCity, invoice_recipient_first_name: invoiceRecipientFirstName, invoice_recipient_last_name: invoiceRecipientLastName, invoice_recipient_email: invoiceRecipientEmail};
        try {
            const response = await Repository.post('clubs', clubData);
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    },
    async deleteClub(token, clubID) {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        try {
            const response = await Repository.delete(`delete-club/${clubID}`, config);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    },
    async uploadImage(logo, fileName, url) {
        var arr = logo.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        var file = new File([u8arr], fileName, {type: mime});

        var formData = new FormData();
        formData.append('files[]', file);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const response = await Repository.post(url, formData, config);
        return response.data.data;
    }
}