<template>
  <v-flex>
    <v-container fluid class="d-flex pb-0 align-start fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A"
      >
        <div class="d-flex align-center">
          <v-row>
            <v-icon size="30" class="ml-1" @click="goBack" style="color: white"
            >mdi-arrow-left
            </v-icon>
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </v-row>
        </div>
        <span class="font-weight-medium" style="color: white;font-size: x-large">
            {{this.title}}
              </span>
        <v-spacer/>

      </v-app-bar>
      <v-layout justify-space-between column>
        <v-row class="mt-16 ml-14">
          <SideMenu/>
          <v-col>
            <h1 v-if="loading">
              <v-progress-circular class="mt-5"
                                   indeterminate
                                   color="#00B51A"
              ></v-progress-circular>
            </h1>
            <v-card class="mt-2 ml-2" width="1000" elevation="1" v-else>
              <v-col>
                <v-form ref="form" class="mx-2" lazy-validation>

                  <v-row v-if="!edit" >
                    <v-col>
                      <span class="font-weight-regular">
                        Kunde
                      </span>
                      <v-autocomplete
                          v-model="selectedCustomer"
                          :items="customers"
                          no-data-text="Keine Kunden gefunden"
                          outlined
                          color="blue-grey lighten-2"
                          item-text="name"
                          item-value="id"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col>
                      <span class="font-weight-regular">
                        Kundennummer
                      </span>
                      <v-autocomplete
                          v-model="selectedCustomer"
                          :items="customers"
                          no-data-text="Keine Kunden gefunden"
                          outlined
                          color="blue-grey lighten-2"
                          item-text="customerNo"
                          item-value="id"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <span class="font-weight-regular">
                      Name des Empfängers
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="recipientName"
                          :rules="[v => !!v || 'Bitte gib den Namen des Empfängers ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <span class="font-weight-regular">
                        E-Mail-Adresse
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="recipientEmail"
                          :rules="emailRules"
                          required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <span class="font-weight-regular">
                        Strasse, Nr.
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="recipientAddress"
                          :rules="[v => !!v || 'Bitte gib eine Strasse und Nummer für den Kunden ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <span class="font-weight-regular">
                        Postleitzahl
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="recipientZipcode"
                          :rules="[v => !!v || 'Bitte gib eine Postleitzahl für den Kunde ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <span class="font-weight-regular">
                      Stadt
                      </span>
                      <v-text-field
                          outlined
                          color="#00B51A"
                          v-model="recipientCity"
                          :rules="[v => !!v || 'Bitte gib eine Stadt für den Kunden ein']"
                          required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox
                          v-model="recipientMandate"
                          v-bind:true-value="1"
                          v-bind:false-value="0"
                          label="Ist eine Einzugsbemächtigung vorhanden?"
                          value=true
                          color="#00B51A"
                      ></v-checkbox>
                    </v-col>

                  </v-row>
                  <div class="text-center">
                    <v-btn class="mt-10 mb-5" style="background:#00B51A;color:white"
                           height="45"
                           width="200"
                           elevation="2"
                           :loading="saving"
                           v-on:click="saveRecipient">
                      <h3 class="font-weight-medium text-capitalize">
                        Speichern
                      </h3>
                    </v-btn>
                  </div>

                  <v-snackbar
                      v-model="snackbar"
                  >
                    {{ snackbarText }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                          color="white"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>

                </v-form>
              </v-col>
            </v-card>

          </v-col>

        </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>

</template>
<script>
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import recipient from "@/api/requests/recipient";
import customer from "@/api/requests/customer";

export default {
  components: {SideMenu, CustomFooter},
  data() {
    return {
      title: '',
      recipientId: null,
      edit: false,
      loading: false,
      saving: false,
      error: null,
      customers: [],
      selectedCustomer: null,
      recipientName: '',
      recipientEmail: '',
      recipientAddress: '',
      recipientCity: '',
      recipientZipcode: '',
      recipientMandate: 0,
      emailRules: [
        v => !!v || 'Bitte gib eine E-Mail-Adresse für deinen Kunden ein',
        v => /.+@.+\..+/.test(v) || 'E-Mail-Adresse muss valide sein',
      ],
      snackbarText: '',
      snackbar: false
    }
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.recipientId = this.$route.params.recipientId

      if(this.recipientId == null) {
        this.fetchCustomers()
          .then(()=> {this.setCustomer(this.$route.params.customerId);})
        ;
        this.title = "Neuer Empfänger";
        this.edit = false;
      } else {
        this.fetchRecipient();
        this.edit = true;
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async saveRecipient() {
      // form validation
      if (!this.$refs.form.validate()) {
        return false;
      }
      //edit existing recipient - pass if not edit
      if(this.edit) {
        this.saving = true;
        recipient.update(this.$store.state.token,
            this.recipientId,
            this.recipientName,
            this.recipientEmail,
            this.recipientAddress,
            this.recipientZipcode,
            this.recipientCity,
            this.recipientMandate
        )
          .then((result) => {
            console.log("saved");
            console.log(result);
            this.snackbarText = 'Empfänger wurde erfolgreich gespeichert';
            this.saving = false;
            this.snackbar = true;
            this.goBack();
            return true;
          })
          .catch(e => {
            console.log(e);
            this.snackbar = true;
            this.snackbarText = 'Empfänger konnte nicht gespeichert werden';
            return false;
          })
      } else {
        //autocomplete validation - only when not edit
        if(this.selectedCustomer == null) {
          this.snackbar = true;
          this.snackbarText = 'Bitte wähle den Kunden aus';
          return false
        }
        //save as new recipient
        this.saving = true;
        recipient.saveRecipient(this.$store.state.token,
            this.recipientName,
            this.selectedCustomer,
            this.recipientEmail,
            this.recipientAddress,
            this.recipientZipcode,
            this.recipientCity,
            this.recipientMandate
        )
            .then((result) => {
              console.log(result);
              this.snackbarText = 'Empfänger wurde erfolgreich angelegt';
              this.saving = false;
              this.snackbar = true;
              this.$router.push(`/recipients/` + this.selectedCustomer);
            })
            .catch((e) => {
              console.log(e);
              this.snackbar = true;
              this.snackbarText = 'Empfänger konnte nicht angelegt werden';
            })
        ;
      }
    },
    async fetchRecipient() {
      recipient.getRecipientByID(this.$store.state.token, this.recipientId)
        .then(response => {
          this.recipientName = response.data.name ?? '';
          this.recipientEmail = response.data.email ?? '';
          this.recipientAddress = response.data.address ?? '';
          this.recipientZipcode = response.data.zipcode ?? '';
          this.recipientCity = response.data.city ?? '';
          this.recipientMandate = response.data.mandate ?? '';
          this.title = this.recipientName;
        })
        .catch(e => {console.log(e)})
      ;
    },
    async fetchCustomers() {
      customer.getAllID(this.$store.state.token).then(response => {
        this.customers = response.data.map(user => ({
          id: user.id ?? '',
          customerNo: user.customerNo ?? '',
          name: user.name ?? '',
        }));
      }).catch(e => {
        console.log(e.response);
      });
    },
    setCustomer(id) {
      if(id === 'empty') {
        this.selectedCustomer = null;
        return true;
      }
      customer.getCustomerByID(this.$store.state.token, id)
        .then((result) => {this.selectedCustomer = result.data.id;})
        .catch((e) => {
          console.log(e);
          this.snackbar = true;
          this.snackbarText = 'Kunde konnte nicht gefunden werden' + id;
        })
      ;
    }
  }
}
</script>
