<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-center">
            <v-icon size="30" class="ml-1" @click="goBack" style="color: white"
            >mdi-arrow-left
            </v-icon>
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
                Empfänger von {{customer.name}}
              </span>
          </div>

          <v-spacer/>
          <div class="d-flex align-center mr-5">
            <v-btn style="background:white;color:#00B51A"
                   height="45"
                   elevation="2"
                   v-on:click="newRecipient">
              <h3 class="font-weight-medium text-none">
                Neuen Empfänger anlegen
              </h3>
            </v-btn>
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
      <v-row class="pa-0 ml-2 d-flex align-start">
        <SideMenu/>
        <v-col class="mr-15">
          <v-card class="mr-3 ml-3">
            <v-simple-table class="mt-5">
              <template v-slot:default>
                <thead>
                <tr>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Name
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    E-Mail
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Adresse
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Stadt
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    PLZ
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Einzugsermächtigung
                  </h3></th>
                  <th/><th/>
                </tr>
                </thead>
                <tbody>
                <tr v-for="recipient in recipients" :key="recipient.id" @mouseover="selectItem(recipient)" @mouseleave="unSelectItem()" @click="viewBillItems(recipient)">
                  <td>{{ recipient.name }}</td>
                  <td>{{ recipient.email }}</td>
                  <td>{{ recipient.address }}</td>
                  <td>{{ recipient.zipcode }}</td>
                  <td>{{ recipient.city }}</td>
                  <td>{{ recipient.mandate }}</td>
                  <td style="width: 35px">
                    <v-btn :disabled="selectedItem !== recipient"
                           v-on:click.stop="viewBillItems()"
                           v-on:click="editRecipient(recipient)"
                           width="35"
                           plain
                           height="35"
                           class="ma-2"
                           ripple
                           outlined
                           color="#00B51A"
                    >
                      <v-icon size="25">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td style="width: 35px">
                    <div class="text-center"
                         v-on:click.stop="viewBillItems()"
                    >
                      <v-btn :disabled="selectedItem !== recipient" @click="selectionForDialog"
                             width="35"
                             plain
                             height="35"
                             class="ma-2"
                             ripple
                             outlined
                             color="#00B51A"
                      >
                        <v-icon size="25">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <v-dialog
                    v-model="dialog"
                    width="500"
                >
                  <v-card>
                    <v-card-title class="headline green" style="color: white">
                      Hinweis
                    </v-card-title>

                    <v-card-text class="mt-3">
                      Soll dieser Empfänger wirklich gelöscht werden?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="green"
                          text
                          @click="dialog = false"
                      >
                        Nein
                      </v-btn>
                      <v-btn
                          color="green"
                          text
                          @click="deleteRecipient()"
                      >
                        Ja
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                </tbody>
              </template>
            </v-simple-table>
            <v-spacer/>
            <v-pagination
                color="#00B51A"
                v-model="page"
                :length="lastPage"
                @input="fetchRecipients"
            />
          </v-card>
        </v-col>
      </v-row>
      <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import recipient from "@/api/requests/recipient";
import customer from "@/api/requests/customer";

export default {
  components: {SideMenu, CustomFooter},
  data() {
    return {
      customer: {},
      loading: false,
      recipients: [],
      page: 1,
      lastPage: 1,
      searchQuery: '',
      error: null,
      selectedItem: null,
      selectedRecipient: null,
      dialog: null
    }
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchCustomer();
      this.fetchRecipients();
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    fetchCustomer() {
      customer.getCustomerByID(this.$store.state.token, this.$route.params.customerId)
          .then((result) => {
            this.customer = result.data;
          })
    },
    selectItem(recipient) {
      this.selectedItem = recipient;
    },
    unSelectItem() {
      this.selectedItem = null;
    },
    selectionForDialog() {
      this.dialog = true;
      this.selectedRecipient = this.selectedItem;
    },
    newRecipient() {
      this.$router.push(`/new-recipient/${this.customer.id}`);
    },
    editRecipient(recipient) {
      this.$router.push(`/edit-recipient/${recipient.id}`);
    },
    viewBillItems(recipient) {
      this.$router.push(`/bill-items/${recipient.id}`);
    },
    fetchRecipients() {
      this.loading = true;
      recipient.getAll(this.$store.state.token, this.page, this.$route.params.customerId).then(response => {
        console.log(response);
        this.lastPage = response.meta.last_page
        this.recipients = response.data.map(recipients => ({
          id: recipients.id ?? '',
          name: recipients.name ?? '',
          customerNo: recipients.twCustomerId ?? '',
          email: recipients.email ?? '',
          address: recipients.address ?? '',
          zipcode: recipients.zipcode ?? '',
          city: recipients.city ?? '',
          mandate: recipients.mandate ? 'Ja' : 'Nein',
          pos: recipients.pos ?? ''
        }));
      }).catch(e => {
        console.log(e.response);
      });
      this.loading = false;
    },
    deleteRecipient() {
      recipient.delete(this.$store.state.token, this.selectedRecipient.id).then(response => {
        console.log(response);
        this.fetchRecipients();
        this.dialog = false;
        this.snackbarText = 'Empfänger erfolgreich gelöscht.'
        this.snackbar = true;
      }).catch(e => {
        console.log(e.response);
        this.dialog = false;
        this.snackbarText = 'Empfänger konnte nicht gelöscht werden.'
        this.snackbar = true;
      });
    }
  }

}
</script>