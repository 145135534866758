<template>
  <v-flex>
    <v-container fluid class="d-flex pb-0 align-start fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A">
        <v-flex>
          <v-row>
            <div class="pa-0 d-flex align-start">
              <router-link :to="routerLink">
                <v-img
                    :src="require('../../assets/logo_club4ever.svg')"
                    class="ma-0"
                    contain
                    max-height="50"
                    max-width="190"
                />
              </router-link>
            </div>
            <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Veranstaltung starten
              </span>
            </div>
          </v-row>
        </v-flex>
      </v-app-bar>
      <v-layout justify-space-between column>
        <v-row class="mt-16 ml-14">
          <SideMenu v-if="role===1"/>
          <ClubAdminSideMenu v-else/>
          <v-col>
            <h1 v-if="loading">
              <v-progress-circular class="mt-5"
                                   indeterminate
                                   color="#00B51A"
              ></v-progress-circular>
            </h1>
            <v-card class="mt-2 ml-2" width="800" elevation="1" v-else>
              <v-col>
                <span class="font-weight-regular ml-2">
                  Bild
                </span>
                <v-row>
                  <v-avatar width="150" height="150" tile class="mt-4 mb-10 ml-5 mr-4" color="#ededed">
                    <v-img v-if="base64EventImage!=null" min-width="150"
                           max-width="150" min-height="150" v-bind:src="(this.base64EventImage)"/>

                    <div v-else class="text-center">
                      <v-icon large color="#00B51A">mdi-image-outline
                      </v-icon>
                    </div>
                  </v-avatar>


                  <FileUploader class="mt-16 ml-3 align-center"
                                @input="onLoadImage"
                                ref="upload"
                                title="Upload"
                                :accept="accept"
                                :multiple="multiple"
                                :maxfiles="1"
                                @update:filename="
			uploadEventImageName = $event;
			$emit('update:filename', uploadEventImageName);
		"
                  >

                  </FileUploader>
                </v-row>

                <v-form ref="form" class="mx-2" lazy-validation>


                <span class="font-weight-regular">
                  Titel
                </span>
                  <v-text-field
                      outlined
                      color="#00B51A"
                      maxlength="50"
                      counter
                      v-model="headline"
                      :rules="[v => !!v || 'Bitte gib einen Titel für deine Veranstaltung ein']"
                      required
                  >
                  </v-text-field>


                  <span class="font-weight-regular">
               Beschreibung
              </span>
                  <v-col class="pa-0"
                         cols="12"
                  >
                    <v-textarea
                        outlined
                        color="#00B51A"
                        maxlength="1000"
                        counter
                        v-model="content"
                        :rules="[v => !!v || 'Bitte gib eine Beschreibung für deine Veranstaltung ein']"
                        required
                    />
                  </v-col>

                  <span class="font-weight-regular">
               Ort der Veranstaltung
              </span>
                  <v-text-field
                      outlined
                      color="#00B51A"
                      v-model="location"
                      maxlength="50"
                      counter
                      :rules="[v => !!v || 'Bitte gib einen Ort für deine Veranstaltung ein']"
                      required
                  >
                  </v-text-field>

                  <span class="font-weight-regular">
               Min. Teilnehmer
              </span>
                  <v-text-field
                      outlined
                      color="#00B51A"
                      v-model="min_member"
                      type="number"
                      :rules="[numberRule]"
                  >
                  </v-text-field>
                  <span class="font-weight-regular">
               Max. Teilnehmer
              </span>
                  <v-text-field
                      outlined
                      color="#00B51A"
                      v-model="max_member"
                      type="number"
                      :rules="[numberRule]"
                  >
                  </v-text-field>

                  <v-row class="mx-1"
                      justify="space-around"
                  >
                       <span class="font-weight-regular">
               Veranstaltungsbeginn
                          <datetime class="theme-club4ever"
                                    type="datetime"
                                    v-model="start_date"
                                    input-class="my-class"
                                    :format="{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' }"
                                    :phrases="{ok: 'Bestätigen', cancel: 'Abbrechen'}"
                                    :hour-step="1"
                                    :minute-step="15"
                                    :min-datetime="start_date_min"
                                    required
                                    width: auto
                                    title="Datum und Uhrzeit wählen"
                                    :backdrop-click="false"
                                    :flow="['date', 'time']"
                          ></datetime>
              </span>
                    <v-checkbox class="ml-auto"
                        v-model="checkedEventEnd"
                        color="#00B51A"
                        @click="setEndDateStart"
                    ></v-checkbox>
                    <span class="font-weight-regular">
                      Veranstaltungsende
                          <datetime class="theme-club4ever"
                                    type="datetime"
                                    v-model="end_date"
                                    input-class="my-class"
                                    :format="{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' }"
                                    :phrases="{ok: 'Bestätigen', cancel: 'Abbrechen'}"
                                    :hour-step="1"
                                    :minute-step="15"
                                    :min-datetime="start_date"
                                    width: auto
                                    :disabled="!checkedEventEnd"
                                    title="Datum und Uhrzeit wählen"
                                    :backdrop-click="false"
                                    :flow="['date', 'time']"
                          ></datetime>
              </span>


                  </v-row>
                  <div class="text-center">
                    <v-btn class="mt-10 mb-5" style="background:#00B51A;color:white"
                           height="45"
                           width="200"
                           elevation="2"
                           :loading="saving"
                           v-on:click="saveClubEvent">
                      <h3 class="font-weight-medium text-capitalize">
                        Speichern
                      </h3>
                    </v-btn>
                  </div>

                  <v-snackbar
                      v-model="snackbar"
                  >
                    {{ snackbarText }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                          color="white"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>

                </v-form>
              </v-col>
            </v-card>

          </v-col>

        </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>

</template>
<script>
import FileUploader from "@/components/FileUploader";
import imageUtils from "@/util/image_utils";
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import ClubAdminSideMenu from "@/components/ClubAdminSideMenu";
import events from "@/api/requests/events";
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

const {DateTime} = require("luxon");

export default {
  components: {FileUploader, SideMenu, CustomFooter, ClubAdminSideMenu, datetime: Datetime},
  data() {
    return {
      loading: false,
      saving: false,
      error: null,
      start_date: DateTime.now().toString(),
      end_date: null,
      start_date_min: DateTime.now().toString(),
      headline: '',
      content: '',
      location: '',
      min_member: '',
      max_member: '',
      uploadEventImageName: null,
      base64EventImage: null,
      snackbarText: '',
      snackbar: false,
      role: null,
      routerLink: null,
      checkedEventEnd: false,
      numberRule: v => {
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999) return true;
        return 'Zahl muss zwischen 0 und 9999 sein';
      },
    }
  },
  props: {
    document: {},
    instantUpload: {
      default: false,
    },
    accept: {default: 'image/png, image/jpeg'},
    multiple: {default: false},
    maxfiles: {default: false},
  },
  created() {
    this.role = this.$store.state.role
    if (this.role === 1) {
      this.routerLink = "/dashboard"
    } else {
      this.routerLink = "/club-admin-dashboard"
    }
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onLoadImage($event) {
      this.dialog = false;
      this.base64EventImage = $event;
      this.$emit('input', $event);
    },
    async saveClubEvent() {

      if (this.$refs.form.validate()) {
        if (this.base64EventImage != null) {
          if(parseInt(this.min_member) < parseInt(this.max_member)) {
            if (this.start_date !== '') {
              if (this.checkStartEndDay() !== false) {
                this.saving = true;
                try {
                  let response = await events.saveClubEvent(
                      this.$store.state.token,
                      this.headline,
                      this.content,
                      this.location,
                      DateTime.fromISO(this.start_date).toFormat('yyyy.MM.dd HH:mm'),
                      this.isThereEndTime(),
                      this.min_member,
                      this.max_member,
                  );
                  this.saving = false;
                  this.snackbarText = "Club Veranstaltung erfolgreich veröffentlicht";
                  this.snackbar = true;
                  this.headline = "";
                  this.content = "";
                  this.location = "";
                  this.min_member = "";
                  this.max_member = "";

                  this.$refs.form.resetValidation();
                  if (this.base64EventImage != null) {
                    let url = `club-event-image/upload/${response.data.id}`;
                    try {
                      let resizedImage = await imageUtils.resizeImage(this.base64EventImage, 1000);
                      try {
                        await events.uploadClubEventImg(
                            resizedImage,
                            this.uploadEventImageName,
                            url
                        );
                        this.base64EventImage = null;
                        this.uploadEventImageName = null;
                        this.$refs.upload.clearTitle();
                      } catch (e) {
                        console.error(e);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }
                  this.goBack()
                } catch (e) {
                  console.log(e);
                  this.snackbar = true;
                  this.saving = false;
                  this.snackbarText = "Club Veranstatlung konnte nicht veröffentlicht werden";
                }
              } else {
                this.snackbar = true;
                this.snackbarText = 'Ungültiges Veranstaltungsende!';
              }

            } else {
              this.snackbar = true;
              this.snackbarText = 'Bitte gibt ein Veranstaltungsbeginn an!';
            }
          } else{
            this.snackbar = true;
            this.snackbarText = 'Die Mindestanzahl der Teilnehmer ist größer als die Maximalanzahl!';
          }
        } else {
          this.snackbar = true;
          this.snackbarText = 'Bitte ein Foto für die Veranstaltung hochladen!';
        }
      }


    },
    setEndDateStart() {
      if (this.checkedEventEnd) {
        if (this.start_date !== '') {
          this.end_date = DateTime.fromISO(this.start_date).plus({hours: 1}).toString();
        } else {
          this.start_date = DateTime.now().toString();
          this.end_date = DateTime.now().plus({hours: 1}).toString();
        }
      } else
        this.end_date = null;
    },
    isThereEndTime() {
      if (this.checkedEventEnd)
        return DateTime.fromISO(this.end_date).toFormat('yyyy.MM.dd HH:mm');
      else
        return null;
    },
    checkStartEndDay() {
      if (this.end_date !== '' || this.end_date !== null) {
        return !(DateTime.fromISO(this.start_date) >= DateTime.fromISO(this.end_date));
      }
      return false;
    }
  },
};
</script>
