<template>
  <v-col>
    <h1 v-if="loading">
      <v-progress-circular class="mt-5"
                           indeterminate
                           color="#00B51A"
      ></v-progress-circular>
    </h1>
    <v-card class="mt-2 ml-2" width="800" elevation="1" v-else>
      <v-col>
             <span class="font-weight-regular ml-2">
                  Logo
                </span>
        <v-row>
          <v-avatar width="80" height="80" class="mt-4 mb-10 ml-5 mr-4" color="#ededed">
            <v-img v-if="base64ClubLogo!=null" v-bind:src="(this.base64ClubLogo)"/>
            <v-img v-else-if="club_logo_url!=null" v-bind:src="(this.club_logo_url)"/>
            <v-icon v-else large
                    color="#00B51A">mdi-soccer
            </v-icon>
          </v-avatar>

          <FileUploader class="mt-10 ml-3"
                        @input="onLoadClubLogo"
                        ref="upload"
                        title="Upload"
                        :accept="accept"
                        :multiple="multiple"
                        :maxfiles="1"
                        @update:filename="
			uploadClubLogoName = $event;
			$emit('update:filename', uploadClubLogoName);
		"
          >

          </FileUploader>
        </v-row>

        <span class="font-weight-regular ml-2">
                  Hintergrundbild
                </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                color="#00B51A"
                dark
                v-bind="attrs"
                v-on="on"
            >
              mdi-information
            </v-icon>
          </template>
          <span>Das Hintergrundbild wird Clubmitgliedern nach dem Einloggen in der App angezeigt</span>
        </v-tooltip>
        <v-row>
          <v-avatar width="80" height="150" tile class="mt-4 mb-10 ml-5 mr-4" color="#ededed">
            <v-img v-if="base64ClubBackground" min-width="80"
                   max-width="80" min-height="150" v-bind:src="this.base64ClubBackground"/>
            <v-img v-else-if="club_background_url" min-width="80"
                   max-width="80" min-height="150" v-bind:src="this.club_background_url"/>
            <div v-else class="text-center">
              <v-icon large color="#00B51A">mdi-image-outline
              </v-icon>
            </div>
          </v-avatar>


          <FileUploader class="mt-16 ml-3 align-center"
                        @input="onLoadClubBackground"
                        ref="upload"
                        title="Upload"
                        :accept="accept"
                        :multiple="multiple"
                        :maxfiles="1"
                        @update:filename="
			uploadClubBackgroundName = $event;
			$emit('update:filename', uploadClubBackgroundName);
		"
          >

          </FileUploader>
        </v-row>

        <v-form ref="form" class="mx-2" lazy-validation>


                <span class="font-weight-regular">
                  Name
                </span>
          <v-text-field
              outlined
              color="#00B51A"
              v-model="clubName"
              :rules="[v => !!v || 'Bitte gib einen Namen für deinen Club ein']"
              required
          >
          </v-text-field>


          <span class="font-weight-regular">
               E-Mail-Adresse
              </span>
          <v-text-field
              outlined
              color="#00B51A"
              v-model="clubEmail"
              :rules="[v => !!v || 'Bitte gib eine E-Mail-Adresse für deinen Club ein']"
              required
          >
          </v-text-field>
          <span class="font-weight-regular">
                Website
              </span>
          <v-text-field
              outlined
              color="#00B51A"
              v-model="clubWebsite"
              :rules="[v => !!v || 'Bitte gib eine Website für deinen Club ein']"
              required
          >
          </v-text-field>
          <span class="font-weight-regular">
                Club Adresse
              </span>
          <v-text-field
              outlined
              color="#00B51A"
              v-model="clubAddress"
              :rules="[v => !!v || 'Bitte gib eine Anschrift für deinen Club ein']"
              required
          >
          </v-text-field>
             <span class="font-weight-regular">
                Club Postleitzahl
              </span>
            <v-text-field
                outlined
                color="#00B51A"
                v-model="clubAddressZip"
                :rules="[v => !!v || 'Bitte gib eine Postleitzahl für deinen Club ein']"
                required
            >
            </v-text-field>
            <span class="font-weight-regular">
                Club Ort
              </span>
            <v-text-field
                outlined
                color="#00B51A"
                v-model="clubAddressCity"
                :rules="[v => !!v || 'Bitte gib einen Ort für deinen Club ein']"
                required
            >
            </v-text-field>
          <span class="font-weight-regular">
               Rechnungsempfänger Vorname
              </span>
          <v-text-field
              outlined
              color="#00B51A"
              v-model="invoiceRecipientFirstName"
              :rules="[v => !!v || 'Bitte gib einen Vornamen für den Rechnungsempfänger deines Clubs ein']"
              required
          >
          </v-text-field>
          <span class="font-weight-regular">
               Rechnungsempfänger Nachname
              </span>
          <v-text-field
              outlined
              color="#00B51A"
              v-model="invoiceRecipientLastName"
              :rules="[v => !!v || 'Bitte gib einen Nachnamen für den Rechnungsempfänger deines Clubs ein']"
              required
          >
          </v-text-field>
          <span class="font-weight-regular">
               Rechnungsempfänger E-Mail-Adresse
              </span>
          <v-text-field
              outlined
              color="#00B51A"
              v-model="invoiceRecipientEmail"
              :rules="[v => !!v || 'Bitte gib eine E-Mail-Adresse für den Rechnungsempfänger deines Clubs ein']"
              required
          >
          </v-text-field>
            <div class="text-center">
              <v-btn class="mt-10 mb-5" style="background:#00B51A;color:white"
                     height="45"
                     width="200"
                     elevation="2"
                     :loading="saving"
                     v-on:click="saveClub">
                <h3 class="font-weight-medium text-capitalize">
                  Speichern
                </h3>
              </v-btn>
            </div>

            <v-snackbar
                v-model="snackbar"
            >
              {{ snackbarText }}

              <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>

        </v-form>
      </v-col>
    </v-card>

  </v-col>
</template>
<script>
import FileUploader from "@/components/FileUploader";
import club from "@/api/requests/club";

export default {
  components: {FileUploader},
  data() {
    return {
      loading: false,
      saving: false,
      clubName: '',
      clubEmail: '',
      clubWebsite: '',
      clubID: '',
      clubAddress: '',
      clubAddressZip: '',
      clubAddressCity: '',
      invoiceRecipientFirstName: '',
      invoiceRecipientLastName: '',
      invoiceRecipientEmail: '',
      uploadedLogo: false,
      uploadedBackground: false,
      uploadClubLogoName: null,
      base64ClubLogo: null,
      uploadClubBackgroundName: null,
      base64ClubBackground: null,
      club_logo_url: null,
      club_background_url: null,
      snackbarText: '',
      snackbar: false
    }
  },
  props: {
    document: {},
    instantUpload: {
      default: false,
    },
    accept: {default: 'image/png, image/jpeg'},
    multiple: {default: false},
    maxfiles: {default: false},
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchClub(this.$route.params.clubID);
    }
  },
  methods: {
    onLoadClubLogo($event) {
      this.uploadedLogo = true;
      this.dialog = false;
      this.base64ClubLogo = $event;
      this.$emit('input', $event);
    },
    onLoadClubBackground($event) {
      this.uploadedBackground = true;
      this.dialog = false;
      this.base64ClubBackground = $event;
      this.$emit('input', $event);
    },
    fetchClub(clubID) {
      this.loading = true;
      club.clubDetail(clubID).then(response => {
        this.clubName = response.data.name
        this.clubEmail = response.data.email
        this.clubWebsite = response.data.website
        this.club_background_url = response.data.background_url;
        this.club_logo_url = response.data.logo_url;
        this.clubAddress = response.data.mailing_address;
        this.clubAddressZip = response.data.zip_code;
        this.clubAddressCity = response.data.address_city;
        this.invoiceRecipientFirstName = response.data.invoice_recipient_first_name;
        this.invoiceRecipientLastName = response.data.invoice_recipient_last_name;
        this.invoiceRecipientEmail = response.data.invoice_recipient_email;
        this.loading = false;
      }).catch(e => {
        this.loading = false;
        this.snackbar = true;
        this.snackbarText = 'Ein Fehler ist aufgetreten'
        console.log(e.response);
      })
    },
    saveClub() {
      if (this.$refs.form.validate()) {
        if (this.base64ClubLogo != null || this.club_logo_url != null) {
          this.saving = true;
          club.updateClub(this.clubName, this.clubEmail, this.clubWebsite, this.$route.params.clubID, this.clubAddress, this.clubAddressZip, this.clubAddressCity,
              this.invoiceRecipientFirstName, this.invoiceRecipientLastName, this.invoiceRecipientEmail, this.$store.state.token).then(() => {
            if (this.uploadedLogo) {
              club.uploadImage(this.base64ClubLogo, this.uploadClubLogoName, `club-image/upload/${this.$route.params.clubID}`).then(() => {
                if (this.base64ClubBackground != null && this.uploadedBackground) {
                  club.uploadImage(this.base64ClubBackground, this.uploadClubBackgroundName, `club-background-image/upload/${this.$route.params.clubID}`).then(() => {
                    this.snackbarText = 'Club wurde erfolgreich gespeichert';
                    this.saving = false;
                    this.snackbar = true;
                    this.$router.go(-1);
                  }).catch(e => {
                    console.log(e.response);
                    this.snackbarText = 'Der Hintergrund konnte nicht hochgeladen werden';
                    this.saving = false;
                    this.snackbar = true;
                  });
                } else {
                  this.snackbarText = 'Club wurde erfolgreich gespeichert';
                  this.saving = false;
                  this.snackbar = true;
                  this.$router.go(-1);
                }
              }).catch(e => {
                (e.response);
                this.snackbarText = 'Das Logo konnte nicht hochgeladen werden';
                this.saving = false;
                this.snackbar = true;
              });
            } else if (this.base64ClubBackground != null && this.uploadedBackground) {
              club.uploadImage(this.base64ClubBackground, this.uploadClubBackgroundName, `club-background-image/upload/${this.$route.params.clubID}`).then(() => {
                this.snackbarText = 'Club wurde erfolgreich gespeichert';
                this.saving = false;
                this.snackbar = true;
                this.$router.go(-1);
              }).catch(e => {
                console.log(e.response);
                this.snackbarText = 'Der Hintergrund konnte nicht hochgeladen werden';
                this.saving = false;
                this.snackbar = true;
              });
            } else {
              this.snackbarText = 'Club wurde erfolgreich gespeichert';
              this.saving = false;
              this.snackbar = true;
              this.$router.go(-1);
            }

          }).catch(e => {
            console.log(e.response);
            this.snackbar = true;
            this.saving = false;
            this.snackbarText = 'Änderungen konnten nicht gespeichert werden';
          });
        } else {
          this.snackbar = true;
          this.snackbarText = 'Bitte füge ein Logo hinzu';
        }
      }
    }
  }
}
</script>
