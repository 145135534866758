import Repository from "../repository";

export default {
    async login(email, password) {
        const loginData = {email: email, password: password}
        const response = await Repository.post('auth/loginBackoffice', loginData);
        return response.data.data;
    },
    async changePassword(oldPassword, newPassword, token) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const loginData = {oldPassword: oldPassword, newPassword: newPassword}
        const response = await Repository.post('auth/change-password', loginData, config);
        return response.data;
    },
    async forgotPassword(userEmail) {
        const data = {userEmail: userEmail}
        const response = await Repository.post('auth/forgot-password', data);
        return response.data;
    },
    async resetPassword(userID, token, password) {
        console.log(userID);
        const data = {user_ID: userID, token: token, newPassword: password}
        console.log(data);
        const response = await Repository.post('auth/reset-password', data);
        return response.data;
    },

}