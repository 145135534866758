<template>
    <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
        <v-app-bar
            app
            color="#00B51A">
            <v-flex>
                <v-row>
                    <div class="pa-0 d-flex align-start">
                        <router-link to="/dashboard">
                            <v-img
                                :src="require('../assets/logo_club4ever.svg')"
                                class="ma-0"
                                contain
                                max-height="50"
                                max-width="190"
                            />
                        </router-link>
                    </div>
                    <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Alle Clubs
              </span>
                    </div>

                    <v-spacer/>

                    <div class="d-flex align-center mr-5">
                        <v-text-field
                            solo
                            dense
                            clearable
                            color="#00B51A"
                            height="45px"
                            style="background-color: white; max-width: 200px; max-height: 40px; min-height: 40px; border-radius: 5px"
                            placeholder="Suche"
                            v-model="searchQuery"
                            @keyup="fetchClubs()"
                            @click:clear="clearQuery()"
                            required
                        />
                    </div>
                    <div class="d-flex align-center mr-5">
                        <v-autocomplete
                            v-model="selectedCountry"
                            :items="countries"
                            placeholder="Land"
                            no-data-text="Keine Länder gefunden"
                            solo
                            clearable
                            dense
                            height="45px"
                            class="pa-0 ma-0"
                            style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; border-radius: 5px"
                            item-text="name"
                            color="green lighten-2"
                            item-color="green"
                            item-value="id"
                            @change="countrySelectionChanged"
                            @click:clear="countrySelectionChanged"
                        />
                    </div>
                    <div class="d-flex align-center mr-5">
                        <v-autocomplete
                            v-model="selectedState"
                            :items="states"
                            placeholder="Bundesland"
                            no-data-text="Keine Bundesländer gefunden"
                            solo
                            clearable
                            dense
                            height="45px"
                            style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; height: 45px; border-radius: 5px"
                            color="green lighten-2"
                            item-color="green"
                            item-text="name"
                            item-value="id"
                            @change="stateSelectionChanged"
                            @click:clear="stateSelectionChanged"
                        />
                    </div>

                    <div class="d-flex align-center mr-5">
                        <v-autocomplete
                            v-model="selectedCity"
                            :items="cities"
                            placeholder="Stadt"
                            no-data-text="Keine Städte gefunden"
                            solo
                            clearable
                            dense
                            height="45px"
                            style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; height: 45px; border-radius: 5px"
                            color="green lighten-2"
                            item-color="green"
                            item-text="name"
                            item-value="id"
                            @change="citySelectionChanged"
                            @click:clear="citySelectionChanged"
                        />
                    </div>


                    <div class="d-flex align-center mr-5">
                        <v-btn style="background:white;color:#00B51A"
                               height="45"
                               elevation="2"
                               v-on:click="newClub">
                            <h3 class="font-weight-medium text-none">
                                Neuen Club anlegen
                            </h3>
                        </v-btn>
                    </div>
                </v-row>
            </v-flex>
        </v-app-bar>

        <v-layout justify-space-between column>
            <v-row class="pa-0 d-flex align-start">
                <SideMenu/>
                <v-col class="mr-15">
                    <v-card class="mr-3 ml-7">
                        <v-simple-table class="mt-5">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th><h3 class="font-weight-bold text-capitalize">
                                        Land
                                    </h3></th>
                                    <th><h3 class="font-weight-bold text-capitalize">
                                        Bundesland
                                    </h3></th>
                                    <th><h3 class="font-weight-bold text-capitalize">
                                        Stadt
                                    </h3></th>
                                    <th><h3 class="font-weight-bold text-capitalize">
                                        Logo
                                    </h3></th>
                                    <th class="text-left">
                                        <h3 class="font-weight-bold text-capitalize">
                                            Club Name
                                        </h3></th>
                                    <th><h3 class="font-weight-bold text-capitalize">
                                        Nutzer
                                    </h3></th>
                                    <th><h3 class="font-weight-bold text-capitalize">
                                        Club Website
                                    </h3></th>
                                    <th><h3 class="font-weight-bold text-capitalize">
                                        Club E-Mail-Adresse
                                    </h3></th>
                                    <th><h3 class="font-weight-bold text-capitalize">
                                        Club ID
                                    </h3></th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="club in clubs" :key="club.id" @mouseover="selectItem(club)"
                                    @mouseleave="unSelectItem()">
                                    <td>{{ club.country }}</td>
                                    <td>{{ club.state }}</td>
                                    <td>{{ club.city }}</td>
                                    <td>
                                        <v-avatar class="mt-3 mb-3">
                                            <v-img :src="(club.logoUrl)" max-height="100" width="60"></v-img>
                                        </v-avatar>
                                    </td>
                                    <td>{{ club.name }}</td>
                                    <td>{{ club.userAmount }}</td>
                                    <td>{{ club.website }}</td>
                                    <td>{{ club.email }}</td>
                                    <td>{{ club.id }}</td>
                                    <td style="width: 35px">
                                        <v-btn :disabled="selectedItem !== club"
                                               v-on:click="editClub(club)"
                                               width="35"
                                               plain
                                               height="35"
                                               class="ma-2"
                                               ripple
                                               outlined
                                               color="#00B51A"
                                        >
                                            <v-icon size="25">mdi-pencil</v-icon>
                                        </v-btn>
                                    </td>
                                    <td style="width: 35px">
                                        <div class="text-center">
                                            <v-btn :disabled="selectedItem !== club" @click="selectionForDialog"
                                                   v-on="on"
                                                   v-bind="attrs"
                                                   width="35"
                                                   plain
                                                   height="35"
                                                   class="ma-2"
                                                   ripple
                                                   outlined
                                                   color="#00B51A"
                                            >
                                                <v-icon size="25">mdi-delete</v-icon>
                                            </v-btn>
                                        </div>
                                    </td>
                                </tr>
                                <v-dialog
                                    v-model="dialog"
                                    width="500"
                                >
                                    <v-card>
                                        <v-card-title class="headline green" style="color: white">
                                            Hinweis
                                        </v-card-title>

                                        <v-card-text class="mt-3">
                                            Soll dieser Club wirklich gelöscht werden?
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="green"
                                                text
                                                @click="dialog = false"
                                            >
                                                Nein
                                            </v-btn>
                                            <v-btn
                                                color="green"
                                                text
                                                @click="deleteClub()"
                                            >
                                                Ja
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-spacer/>
                        <v-pagination
                            color="#00B51A"
                            v-model="page"
                            :length="lastPage"
                            @input="fetchClubs(page)"
                        />
                    </v-card>
                </v-col>
            </v-row>
            <CustomFooter/>
        </v-layout>

    </v-container>
</template>

<script>
import club from "@/api/requests/club";
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import country from "@/api/requests/country";
import city from "@/api/requests/city";

export default {
    components: {CustomFooter, SideMenu},
    data() {
        return {
            loading: false,
            clubs: [],
            page: 1,
            lastPage: 1,
            searchQuery: '',
            selectedCountry: 83,
            countries: [],
            selectedState: null,
            states: [],
            selectedCity: null,
            dialog: null,
            snackbarText: '',
            snackbar: false,
            cities: [],
            error: null,
            selectedItem: null,
            selectedClub: null      //for dialog to know which club is currently selected
        }
    },
    created() {
        if (this.$store.state.token.length === 0) {
            this.$router.push('/login');
        } else {
            this.fetchCountries();
            this.fetchClubs();
            this.fetchStatesForCountry()
        }
    },
    methods: {
        deleteClub() {
            console.log(this.selectedClub.id);
            club.deleteClub(this.$store.state.token, this.selectedClub.id).then(response => {
                console.log(response);
                this.selectedCountry = 83;
                this.countrySelectionChanged();
                this.dialog = false;
                this.snackbarText = 'Club erfolgreich gelöscht.'
                this.snackbar = true;
            }).catch(e => {
                console.log(e.response);
                this.dialog = false;
                this.snackbarText = 'Club konnte nicht gelöscht werden.'
                this.snackbar = true;
            });
        },
        selectionForDialog() {
            this.dialog = true
            this.selectedClub = this.selectedItem;
        },
        clearQuery() {
            this.searchQuery = '';
            this.fetchDeals();
        },
        countrySelectionChanged() {
            this.selectedCity = null;
            this.cities = [];
            this.selectedState = null;
            this.states = [];
            this.fetchClubs();
            this.fetchStatesForCountry()
        },
        stateSelectionChanged() {
            if (this.selectedState == null) {
                this.countrySelectionChanged();
            } else {
                this.cities = [];
                this.selectedCity = null;
                this.fetchClubs();
                this.fetchCitiesForState()
            }
        },
        citySelectionChanged() {
            if (this.selectedCity == null) {
                this.stateSelectionChanged();
            } else {
                this.clubs = [];
                this.fetchClubs();
            }
        },
        fetchStatesForCountry() {
            city.getStatesForCountry(this.selectedCountry).then(response => {
                this.states = [];
                this.states = response.data.map(state => ({
                    name: state
                }));
            })
        },
        fetchCitiesForState() {
            country.getCitiesForState(this.selectedState).then(response => {
                this.cities = [];
                this.cities = response.data.map(city => ({
                    name: city.name,
                    id: city.id,
                }));
            })
        },
        fetchCountries() {
            this.loading = true
            country.getAll().then(response => {
                this.countries = [];
                this.countries = response.data.map(country => ({
                    name: country.name,
                    id: country.id,
                    code: country.code,
                }));
                this.loading = false;
            });
        },
        fetchClubs() {
            this.loading = true
            club.getAll(this.page, this.selectedCountry, this.selectedState, this.selectedCity, this.searchQuery).then(response => {
                this.lastPage = response.meta.last_page;
                this.clubs = response.data.map(club => ({
                    country: 'Deutschland',
                    state: club.city.state,
                    city: club.city.name,
                    name: club.name,
                    id: club.id,
                    logoUrl: club.logo_url,
                    website: club.website,
                    email: club.email,
                    userAmount: club.user_amount,
                    cityID: club.city.id,
                    cityName: club.city.name,
                    cityState: club.city.state
                }));
            }).catch(e => {
                console.log(e.response);
            });
        },
        selectItem(club) {
            this.selectedItem = club
        },
        unSelectItem() {
            this.selectedItem = null
        },
        editClub(club) {
            this.$router.push(`/admin-edit-club/${club.id}`)
        },
        newClub() {
            this.$router.push('/new-club');
        }
    }

}
</script>