<template>
  <v-flex>
    <v-container fluid class="d-flex pb-0 align-start fill-height" style="background: #EDEDED">
      <v-app-bar
          app
          color="#00B51A">
        <v-flex>
          <v-row>
            <div class="pa-0 d-flex align-start">
              <router-link to="/dashboard">
                <v-img
                    :src="require('../../../assets/logo_club4ever.svg')"
                    class="ma-0"
                    contain
                    max-height="50"
                    max-width="190"
                />
              </router-link>
            </div>
            <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
                {{ showTitle() }}
              </span>
            </div>
          </v-row>
        </v-flex>
      </v-app-bar>
      <v-layout justify-space-between column>
        <v-row class="mt-16 ml-14">
          <SideMenu v-if="role===1"/>
          <ClubAdminSideMenu v-else/>
          <v-col>
            <h1 v-if="loading">
              <v-progress-circular class="mt-5"
                                   indeterminate
                                   color="#00B51A"
              ></v-progress-circular>
            </h1>
            <v-card class="mt-2 ml-2" width="800" elevation="1" v-else>
              <v-col>
                <span class="font-weight-regular ml-2">
                  Bild
                </span>
                <v-row>
                  <v-avatar width="80" height="150" tile class="mt-4 mb-10 ml-5 mr-4" color="#ededed">
                    <v-img v-if="base64NewsImage!=null" min-width="80"
                           max-width="80" min-height="150" v-bind:src="(this.base64NewsImage)"/>

                    <div v-else class="text-center">
                      <v-icon large color="#00B51A">mdi-image-outline
                      </v-icon>
                    </div>
                  </v-avatar>


                  <FileUploader class="mt-16 ml-3 align-center"
                                @input="onLoadImage"
                                ref="upload"
                                title="Upload"
                                :accept="accept"
                                :multiple="multiple"
                                :maxfiles="1"
                                @update:filename="
			uploadNewsImageName = $event;
			$emit('update:filename', uploadNewsImageName);
		"
                  >

                  </FileUploader>
                </v-row>

                <v-form ref="form" class="mx-2" lazy-validation>


                  <v-radio-group
                      v-model="clubNews"
                      row

                  >
                    <v-radio
                        label="Admin News"
                        :value= false
                        color="#00B51A"
                    ></v-radio>
                    <v-radio
                        label="Club News"
                        :value= true
                        color="#00B51A"
                    ></v-radio>
                  </v-radio-group>
                  <div :hidden="!clubNews" >
                  <span class="font-weight-regular">
                  News an Club
                </span>
                  <v-autocomplete
                      v-model="selectedClub"
                      :disabled="!clubNews"
                      :items="clubs"
                      no-data-text="Keine Clubs gefunden"
                      outlined
                      color="#00B51A"
                      item-text="name"
                      item-value="id"
                  >
                  </v-autocomplete>
                  </div>
                  <div>
                <span class="font-weight-regular">
                  Titel
                </span>
                  <v-text-field
                      outlined
                      color="#00B51A"
                      v-model="headline"
                      :rules="[v => !!v || 'Bitte gib einen Titel für deine Club News ein']"
                      required
                  >
                  </v-text-field>


                  <span class="font-weight-regular">
               Beschreibung
              </span>
                  <v-col class="pa-0"
                         cols="12"
                  >
                    <v-textarea
                        outlined
                        color="#00B51A"
                        v-model="content"
                        :rules="[v => !!v || 'Bitte gib eine Beschreibung für deine Club News ein']"
                        required
                    />
                  </v-col>
                  </div>
                  <div class="text-center">
                    <v-btn class="mt-10 mb-5" style="background:#00B51A;color:white"
                           height="45"
                           width="200"
                           elevation="2"
                           :loading="saving"
                           v-on:click="saveClub">
                      <h3 class="font-weight-medium text-capitalize">
                        Speichern
                      </h3>
                    </v-btn>
                  </div>

                  <v-snackbar
                      v-model="snackbar"
                  >
                    {{ snackbarText }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                          color="white"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>

                </v-form>
              </v-col>
            </v-card>

          </v-col>

        </v-row>
        <CustomFooter/>
      </v-layout>
    </v-container>
  </v-flex>

</template>
<script>
import FileUploader from "@/components/FileUploader";
import imageUtils from "@/util/image_utils";
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import news from "@/api/requests/news";

export default {
  components: {FileUploader, SideMenu, CustomFooter},
  data() {
    return {
      clubs:[],
      selectedClub: null,
      loading: false,
      saving: false,
      error: null,
      headline: '',
      content: '',
      uploadNewsImageName: null,
      base64NewsImage: null,
      snackbarText: '',
      snackbar: false,
      role: null,
      routerLink: null,
      clubNews: false,
      dialog: null,
    }
  },
  props: {
    document: {},
    instantUpload: {
      default: false,
    },
    accept: {default: 'image/png, image/jpeg'},
    multiple: {default: false},
    maxfiles: {default: false},
  },
  created() {
    this.role = this.$store.state.role
    if(this.role===1){
      this.routerLink = "/dashboard"
      this.fetchClubs()
    }

    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onLoadImage($news) {
      this.dialog = false;
      this.base64NewsImage = $news;
      this.$emit('input', $news);
    },
    fetchClubs() {
      this.loading = true
      news.getAllClubsRaw().then(response => {
        console.log(response);
        this.clubs = response.data.map(club => ({
          name: club.name,
          id: club.id,
        }));
      }).catch(e => {
        console.log(e.response);
      });
      this.loading = false;
    },
    async saveClub() {
      if(this.clubNews === true)
      {
       if(this.selectedClub !== null) {
          if (this.$refs.form.validate()) {
            this.saving = true;
            try {
              let response = await news.saveClubNews(
                  this.$store.state.token,
                  this.headline,
                  this.content,
                  this.selectedClub
              );
              this.saving = false;
              this.snackbarText = "Club News erfolgreich veröffentlicht";
              this.snackbar = true;
              this.headline = "";
              this.content = "";
              this.$refs.form.resetValidation();
              if (this.base64NewsImage != null) {
                let url = `club-news-image/upload/${response.data.id}`;
                try {
                  let resizedImage = await imageUtils.resizeImage(this.base64NewsImage, 1000);
                  try {
                    await news.uploadImage(
                        resizedImage,
                        this.uploadNewsImageName,
                        url
                    );
                    this.base64NewsImage = null;
                    this.uploadNewsImageName = null;
                    this.$refs.upload.clearTitle();
                  } catch (e) {
                    console.error(e);
                  }
                } catch (err) {
                  console.error(err);
                }
              }

              this.$router.push('/admin-all-club-news');
            } catch (e) {
              console.log(e);
              this.snackbar = true;
              this.saving = false;
              this.snackbarText = "Club News konnten nicht veröffentlicht werden";
            }
          }
        }else{
         this.snackbar = true;
         this.saving = false;
         this.snackbarText = "Wähle bitte einen Club aus!";
       }
      }
      else {
        if (this.$refs.form.validate()) {
          this.saving = true;
          try {
            let response = await news.saveClubNews(
                this.$store.state.token,
                this.headline,
                this.content,
                null
            );
            this.headline = "";
            this.content = "";
            this.$refs.form.resetValidation();
            if (this.base64NewsImage != null) {
              let url = `admin-news-image/upload/${response.data.id}`;
              try {
                let resizedImage = await imageUtils.resizeImage(this.base64NewsImage, 1000);
                try {
                  await news.uploadImage(
                      resizedImage,
                      this.uploadNewsImageName,
                      url
                  );
                  this.base64NewsImage = null;
                  this.uploadNewsImageName = null;
                  this.$refs.upload.clearTitle();
                } catch (e) {
                  console.error(e);
                }
              } catch (err) {
                console.error(err);
              }
            }
            this.saving = false;

            this.snackbarText = "Admin News erfolgreich veröffentlicht";
            this.snackbar = true;
            this.goBack()
          } catch (e) {
            console.log(e);
            this.snackbar = true;
            this.saving = false;
            this.snackbarText = "Admin News konnten nicht veröffentlicht werden";
          }
        }
      }

    },
    showTitle(){
      if(this.clubNews === true) {
        return "Club-News schreiben"
      }
      else{
        return "Admin-News schreiben"
      }
    }
  },
};
</script>
