<!-- TODO: paginationBar -->

<template>
    <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
        <v-app-bar
            app
            color="#00B51A">
            <v-flex>
                <v-row>
                    <div class="pa-0 d-flex align-start">
                        <router-link to="/dashboard">
                            <v-img
                                :src="require('../assets/logo_club4ever.svg')"
                                class="ma-0"
                                contain
                                max-height="50"
                                max-width="190"
                            />
                        </router-link>
                    </div>
                    <div class="d-flex align-center">
                        <span class="font-weight-medium" style="color: white;font-size: x-large">
                            Rechnungslauf
                        </span>
                    </div>

                    <v-spacer/>
                    <div class="d-flex align-center mr-5">
                        <v-btn style="background:white;color:#00B51A"
                               height="45"
                               elevation="2"
                               v-on:click="cancleInvoieRun">
                            <h3 class="font-weight-medium text-none">
                                Abbrechen
                            </h3>
                        </v-btn>
                    </div>
                    <div class="d-flex align-center mr-5">
                        <v-btn style="background:white;color:#00B51A"
                               height="45"
                               elevation="2"
                               v-on:click="sendInvoiceRun = true">
                            <h3 class="font-weight-medium text-none">
                                Rechnungen senden
                            </h3>
                        </v-btn>
                    </div>
                </v-row>
            </v-flex>
        </v-app-bar>
        <v-layout justify-space-between column>
            <v-row class="pa-0 ml-2 d-flex align-start">
                <SideMenu/>
                <v-col class="mr-15">

                    <v-row class="mt-5 mr-15 ml-3">

                    <span class="font-weight-regular">Leistungszeitraum</span>

                    </v-row>
                    <v-row class="mt-5 mr-15 ml-3" align="center">
                        <v-card class="mr-3 ml-3">
                            <v-col align="center">
                                <div>
                                    <span class="font-weight-regular">
                                            <datetime class="theme-club4ever"
                                                      type="date"
                                                      v-model="start_date"
                                                      input-class="vdatemonthpicker"
                                                      :format="{ year: 'numeric', month: 'short'}"
                                                      :phrases="{ok: 'Bestätigen', cancel: 'Abbrechen'}"
                                                      required
                                                      title="Datum wählen"
                                                      :backdrop-click="false"
                                                      :flow="['month']"
                                            />
                                    </span>
                                </div>
                            </v-col>
                        </v-card>
                    </v-row>

                    <v-card class="mr-3 ml-3">
                        <v-simple-table class="mt-5">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th>
                                        <v-checkbox class="ml-auto"
                                                    v-model="allSelected"
                                                    color="#00B51A"
                                                    v-on:click="selectAll()"
                                        />
                                    </th>
                                    <th>
                                        <h3 class="font-weight-bold text-capitalize">Stadt</h3>
                                    </th>
                                    <th>
                                        <h3 class="font-weight-bold text-capitalize">Verein</h3>
                                    </th>
                                    <th>
                                        <h3 class="font-weight-bold text-capitalize">Empfänger</h3>
                                    </th>
                                    <th>
                                        <h3 class="font-weight-bold text-capitalize">Einzugsermächtigung</h3>
                                    </th>
                                    <th>
                                        <h3 class="font-weight-bold text-capitalize">Email-Addresse</h3>
                                    </th>
                                </tr>
                                </thead>

                                <tbody id="lbody">

                                <tr v-for="recipient in recipients" :key="recipient.id"
                                    @mouseup="selectItem(recipient)">
                                    <td>
                                        <v-checkbox class="ml-auto"
                                                    v-model="recipient.selected"
                                                    color="#00B51A"
                                                    @mouseup="selectItem(recipient)"
                                        ></v-checkbox>
                                    </td>
                                    <td>{{ recipient.city }}</td>
                                    <td>{{ recipient.customer.name }}</td>
                                    <td>{{ recipient.name }}</td>
                                    <td>{{ recipient.mandate }}</td>
                                    <td>{{ recipient.email }}</td>
                                </tr>

                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-dialog
                            v-model="sendingInvoices"
                            width="500"
                        >
                            <v-card>
                                <v-card-title class="headline green" style="color: white">
                                    Hinweis
                                </v-card-title>

                                <v-card-text class="mt-3">
                                    Rechnungen werden versendet
                                </v-card-text>

                                <v-divider></v-divider>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="sendInvoiceRun"
                            width="500"
                        >
                            <v-card>
                                <v-card-title class="headline green" style="color: white">
                                    Hinweis
                                </v-card-title>

                                <v-card-text class="mt-3">
                                    <span>Du bist dabei Rechnungen für den Zeitraum</span>
                                    <br><br>
                                    <span>{{ formatDateInvoice(start_date, end_date) }}</span>
                                    <br><br>
                                    <span>zu versenden</span>
                                </v-card-text>

                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="green"
                                        text
                                        @click="sendInvoiceRun = false"
                                    >
                                        Abbrechen
                                    </v-btn>
                                    <v-btn
                                        color="green"
                                        text
                                        @click="runInvoice"
                                    >
                                        Ausführen
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-spacer/>
                    </v-card>
                </v-col>
                <v-snackbar
                    v-model="snackbar"
                    @click="snackbar = false"
                >
                    {{ snackbarText }}
                </v-snackbar>
            </v-row>
            <CustomFooter/>
        </v-layout>
    </v-container>
</template>

<script>
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import customer from "@/api/requests/customer";
import recipient from "@/api/requests/recipient";
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

const {DateTime} = require("luxon");

export default {
    components: {SideMenu, CustomFooter, datetime: Datetime},
    data() {
        return {
            loading: false,
            customers: [],
            recipients: [],
            searchQuery: '',
            error: null,
            allSelected: true,
            snackbar: false,
            sendInvoiceRun: false,
            sendingInvoices: false,
            snackbarText: '',
            start_date: DateTime.now().toString(),
            end_date: DateTime.now().toString(),
            start_date_min: DateTime.now().toString(),
        }
    },
    async created() {
        if (this.$store.state.token.length === 0) {
            this.$router.push('/login');
        } else {
            this.fetchRecipients();
        }
    },
    methods: {
        selectItem(customer) {
            if (this.allSelected === true) {
                this.allSelected = false;
            }
            customer.selected = !customer.selected;
        },
        selectAll() {
            if (this.allSelected === false) {
                this.recipients.forEach((value) => {
                    value.selected = false
                })
            } else {
                this.recipients.forEach((value) => {
                    value.selected = true
                })
            }

        },
        fetchRecipients() {
            this.loading = true;
            recipient.getAllNoPagination(this.$store.state.token).then(response => {
                console.log(response);
                this.recipients = response.data.map(response => ({
                    id: response.id ?? '',
                    customer: response.customer ?? '',
                    email: response.email ?? '',
                    name: response.name ?? '',
                    address: response.address ?? '',
                    zipcode: response.zipcode ?? '',
                    city: response.city ?? '',
                    mandate: response.mandate ? "Ja" : 'Nein',
                    pos: response.pos ?? '',
                    selected: true
                }));
            }).catch(e => {
                console.log(e.response);
            });
            this.loading = false;
        },
        async sendSort(index) {
            this.loading = true;
            let changeRecipient = this.recipients[index].id;
            let aboveRecipient = this.recipients[index - 1] != null ? this.recipients[index - 1].id : null;
            let belowRecipient = this.recipients[index + 1] != null ? this.recipients[index + 1].id : null;
            await recipient.sort(this.$store.state.token, aboveRecipient ?? "", changeRecipient, belowRecipient ?? "").catch(e => {
                console.log(e.response);
            });
        },
        async runInvoice() {
            this.sendInvoiceRun = false
            if (this.sendingInvoices === false) {
                this.sendingInvoices = true;
                let numbers = [];
                let startDate = DateTime.fromISO(this.start_date).toFormat('yyyy-MM-dd');
                let endDate = DateTime.fromISO(this.end_date).toFormat('yyyy-MM-dd');
                this.recipients.forEach((value) => {
                    if (value.selected) {
                        numbers.push(value.id)
                    }
                });

                const invoiceData = {
                    start: startDate,
                    end: endDate,
                    customers: numbers,
                };

                await customer.invoiceRun(this.$store.state.token, invoiceData).then(response => {
                    console.log(response)
                    this.snackbar = true;
                    this.snackbarText = 'Rechnungen wurden versendet.'
                    this.customers.forEach((value) => {
                        value.selected = false
                    })
                    this.sendingInvoices = false;
                    if (this.allSelected === true) {
                        this.allSelected = false
                    }
                }).catch(e => {
                    console.log(e.response);
                    this.snackbarText = 'Rechnungen konnten nicht versendet.'
                    this.snackbar = true;
                });
            } else {
                console.log("here")
                this.snackbarText = 'Rechnungen werden noch versendet.'
                this.snackbar = true;
            }
        },
        cancleInvoieRun() {
            this.$router.go(-1);
        },
        formatDateInvoice(datetime, datetime2) {

            let tmpDate1 = new Date(datetime).toLocaleDateString(
                'de-de',
                {
                    year: 'numeric',
                    month: 'short',
                }
            );

            let tmpDate2 = new Date(datetime2).toLocaleDateString(
                'de-de',
                {
                    year: 'numeric',
                    month: 'short',
                }
            );

            if (tmpDate1 !== tmpDate2) {
                return tmpDate1 + " bis " + tmpDate2;
            } else {
                return tmpDate1;
            }

        },
    }

}
</script>