<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Alle aktuellen Angebote
              </span>
          </div>
          <v-spacer/>
          <div class="d-flex align-center mr-5">
            <v-text-field
                solo
                dense
                clearable
                color="#00B51A"
                height="45px"
                style="background-color: white; max-width: 200px; max-height: 40px; min-height: 40px; border-radius: 5px"
                placeholder="Suche"
                v-model="searchQuery"
                @keyup="fetchDeals()"
                @click:clear="clearQuery()"
                required
            />
          </div>
          <div class="d-flex align-center mr-5">
            <v-autocomplete
                v-model="selectedCountry"
                :items="countries"
                placeholder="Land"
                no-data-text="Keine Länder gefunden"
                solo
                clearable
                dense
                height="45px"
                class="pa-0 ma-0"
                style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; border-radius: 5px"
                item-text="name"
                color="green lighten-2"
                item-color="green"
                item-value="id"
                @change="countrySelectionChanged"
            />
          </div>
          <div class="d-flex align-center mr-5">
            <v-autocomplete
                v-model="selectedState"
                :items="states"
                placeholder="Bundesland"
                no-data-text="Keine Bundesländer gefunden"
                solo
                clearable
                dense
                height="45px"
                style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; height: 45px; border-radius: 5px"
                color="green lighten-2"
                item-color="green"
                item-text="name"
                item-value="id"
                @change="stateSelectionChanged"
            />
          </div>

          <div class="d-flex align-center mr-5">
            <v-autocomplete
                v-model="selectedCity"
                :items="cities"
                placeholder="Stadt"
                no-data-text="Keine Städte gefunden"
                solo
                clearable
                dense
                height="45px"
                style="background-color: white; max-width: 200px; max-height: 45px; min-height: 45px; height: 45px; border-radius: 5px"
                color="green lighten-2"
                item-color="green"
                item-text="name"
                item-value="id"
                @change="citySelectionChanged"
            />
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
      <v-row class="pa-0 ml-2 d-flex align-start">
        <SideMenu/>
        <v-col class="mr-15">
          <v-card class="mr-3 ml-3">
            <v-simple-table class="mt-6">
              <template v-slot:default>
                <thead>
                <tr>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Land
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Bundesland
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Stadt
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Logo
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Club Name
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Ablaufdatum
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Deal ID
                  </h3></th>
                  <th>
                    <h3 class="font-weight-bold text-capitalize">
                      Titel
                    </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Preis
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Betrag Club
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Betrag dessaso
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Verkäufer Firmenname
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Verkäufer Nachname, Vorname
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Verkäufer E-Mail
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Freistellen Reichweite
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Freistellen Laufzeit
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Globales Angebot
                  </h3></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="deal in deals" :key="deal.id" @mouseover="selectItem(deal)" @mouseleave="unSelectItem()">
                  <td>{{ deal.country }}</td>
                  <td>{{ deal.state }}</td>
                  <td>{{ deal.city }}</td>
                  <td>
                    <v-avatar class="mt-3 mb-3">
                      <v-img :src="(deal.clubLogo)" max-height="100" width="60"></v-img>
                    </v-avatar>
                  </td>
                  <td>{{ deal.clubName }}</td>
                  <td>{{ deal.expirationTime }}</td>
                  <td>{{ deal.id }}</td>
                  <td>{{ deal.title }}</td>
                  <td>{{ deal.price }}€</td>
                  <td>{{ deal.donationClub }}€</td>
                  <td>{{ deal.donationSystem }}€</td>
                  <td>{{ deal.companyName }}</td>
                  <td>{{ deal.vendorName }}</td>
                  <td>{{ deal.userEmail }}</td>
                  <td>
                    <v-checkbox color="green"
                                v-model="deal.dischargedRange"
                                @click="dischargeRange(deal.id)"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox color="green"
                                v-model="deal.dischargedRuntime"
                                @click="dischargeRuntime(deal.id)"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox color="green"
                                v-model="deal.isGlobal"
                                @click="setGlobal(deal.id)"
                    ></v-checkbox>
                  </td>
                  <td style="width: 35px">
                    <div class="text-center">
                      <v-dialog
                          v-model="dialog"
                          width="500"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="selectedRow !== deal" @click="selectDealForDialog"
                                 v-on="on"
                                 v-bind="attrs"
                                 width="35"
                                 plain
                                 height="35"
                                 class="ma-2"
                                 ripple
                                 outlined
                                 color="#00B51A"
                          >
                            <v-icon size="25">mdi-delete</v-icon>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title class="headline green" style="color: white">
                            Hinweis
                          </v-card-title>

                          <v-card-text class="mt-3">
                            Soll dieses Angebot wirklich gelöscht werden?
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green"
                                text
                                @click="dialog = false"
                            >
                              Nein
                            </v-btn>
                            <v-btn
                                color="green"
                                text
                                @click="deleteDeal"
                            >
                              Ja
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-pagination
                color="#00B51A"
                v-model="page"
                :length="lastPage"
                @input="fetchDeals()"
            ></v-pagination>
          </v-card>
          <v-snackbar
              v-model="snackbar"
          >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
      <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import deal from "@/api/requests/deal.js";
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import city from "@/api/requests/city";
import country from "@/api/requests/country";

export default {
  components: {SideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      deals: [],
      page: 1,
      lastPage: 1,
      searchQuery: '',
      selectedCountry: 83,
      countries: [],
      selectedState: null,
      states: [],
      selectedCity: null,
      cities: [],
      error: null,
      snackbarText: '',
      snackbar: false,
      dialog: null,
      selectedRow: null,
      selectedDeal: null,
    }
  },
  created() {
    this.fetchCountries();
    this.fetchDeals();
    this.fetchStatesForCountry()
  },
  methods: {
    clearQuery(){
      this.searchQuery = '';
      this.fetchDeals();
    },
    selectDealForDialog() {
      this.selectedDeal = this.selectedRow;
    },
    deleteDeal() {
      deal.delete(this.$store.state.token, this.selectedDeal.id).then(response => {
        console.log(response);
        this.selectedCountry = 83;
        this.countrySelectionChanged();
        this.dialog = false;
        this.snackbarText = 'Angebot erfolgreich gelöscht.'
        this.snackbar = true;
      }).catch(e => {
        console.log(e.response);
        this.dialog = false;
        this.snackbarText = 'Angebot konnte nicht gelöscht werden.'
        this.snackbar = true;
      });
    },
    dischargeRange(id) {
      deal.discharge(this.$store.state.token, id, `discharge-range`).then(response => {
        this.deals.forEach(entry => {
          if (entry.id === id) {
            entry.donationSystem = response.donation_system.toFixed(2).replace('.', ',');
          }
        })
      });
    },
    dischargeRuntime(id) {
      deal.discharge(this.$store.state.token, id, `discharge-runtime`).then(response => {
        this.deals.forEach(entry => {
          if (entry.id === id) {
            entry.donationSystem = response.donation_system.toFixed(2).replace('.', ',');
          }
        })
      });
    },
    setGlobal(id) {
      deal.setGlobal(this.$store.state.token, id).then(response => {
        if(response.status===200){
        this.deals.forEach(entry => {
          if (entry.id === id) {
            entry.isGlobal = response.data().isGlobal;
          }
        })
        }
      })
    },
    countrySelectionChanged() {
      this.selectedCity = null;
      this.cities = [];
      this.selectedState = null;
      this.states = [];
      this.fetchDeals();
      this.fetchStatesForCountry()
    },
    stateSelectionChanged() {
      if (this.selectedState == null) {
        this.countrySelectionChanged();
      } else {
        this.cities = [];
        this.selectedCity = null;
        this.fetchDeals();
        this.fetchCitiesForState()
      }
    },
    citySelectionChanged() {
      if (this.selectedCity == null) {
        this.stateSelectionChanged();
      } else {
        this.clubs = [];
        this.fetchDeals();
      }
    },
    fetchStatesForCountry() {
      city.getStatesForCountry(this.selectedCountry).then(response => {
        this.states = [];
        this.states = response.data.map(state => ({
          name: state
        }));
      })
    },
    fetchCitiesForState() {
      country.getCitiesForState(this.selectedState).then(response => {
        this.cities = [];
        this.cities = response.data.map(city => ({
          name: city.name,
          id: city.id,
        }));
      })
    },
    fetchCountries() {
      this.loading = true
      country.getAll().then(response => {
        this.countries = [];
        this.countries = response.data.map(country => ({
          name: country.name,
          id: country.id,
          code: country.code,
        }));
        this.loading = false;
      });
    },
    editDeal(deal) {
      this.$router.push({name: 'edit-deal', params: {id: deal.id}});
    },
    selectItem(deal) {
      this.selectedRow = deal
    },
    unSelectItem() {
      this.selectedRow = null
    },
    formatDate(date) {
      if (date == null) {
        return null;
      }
      return new Date(date).toLocaleDateString(
        'de-de',
        {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }
      );
    },
    formatDateTime(date) {
      if (date == null) {
        return null;
      }
      return new Date(date).toLocaleDateString(
        'de-de',
        {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }
      );
    },
    fetchDeals() {
      this.loading = true
      deal.getAll(this.$store.state.token, this.page, 'active', this.selectedCountry, this.selectedState, this.selectedCity, this.searchQuery).then(response => {
        this.lastPage = response.meta.last_page;
        this.deals = response.data.map(deal => ({
          country: 'Deutschland',
          state: deal.user.club.city.state,
          city: deal.user.club.city.name,
          clubName: deal.user.club.name,
          clubLogo: deal.user.club.logo_url,
          title: deal.title,
          id: deal.id,
          dischargedRuntime: deal.discharge_runtime != null,
          dischargedRange: deal.discharge_range != null,
          price: deal.price.toFixed(2).replace('.', ','),
          donationClub: deal.donation_club.toFixed(2).replace('.', ','),
          donationSystem: deal.donation_system.toFixed(2).replace('.', ','),
          vendorName: deal.user.last_name + ', ' + deal.user.first_name,
          companyName: deal.user.company_name == null ? '' : deal.user.company_name,
          expirationTime: this.formatDateTime(deal.expiration_time),
          userID: deal.user.id,
          userEmail: deal.user.email,
          isGlobal: deal.isGlobal
        }));
      }).catch(e => {
        console.log(e.response);
        if (e.response.status === 401) {
          this.$router.push('/login');
        }
      });
    },
  }

}
</script>