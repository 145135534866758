<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/dashboard">
              <v-img
                  :src="require('../../../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            {{ showTitle() }}
              </span>
          </div>

          <v-spacer/>
          <div class="d-flex align-center mr-5">
            <v-sheet width="200" height="45" color="#00B51A">
              <v-select color="white" item-color="#00B51A" height="45" style="color: white; border-color: white"
                        :items="items"
                        dense
                        solo
                        v-model="selected"
                        @change="selectionChanged"
              ></v-select>
            </v-sheet>
          </div>
          <div class="d-flex align-center mr-5">
            <v-btn style="background:white;color:#00B51A"
                   height="45"
                   elevation="2"
                   v-on:click="newClubNews">
              <h3 class="font-weight-medium text-none">
                Neue News anlegen
              </h3>
            </v-btn>
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
      <v-row class="pa-0 ml-2 d-flex align-start">
        <SideMenu/>
        <v-col class="mr-15">
          <v-card class="mr-3 ml-3">
            <v-simple-table class="mt-5">
              <template v-slot:default>
                <thead>
                <tr>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Titel
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                  Bild
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Beschreibung
                  </h3></th>

                  <th><h3 class="font-weight-bold text-capitalize">
                    Gesendet am
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize" >
                    Gesendet an
                  </h3></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="news in clubNews" :key="news.id" @mouseover="selectItem(news)" @mouseleave="unSelectItem()">
                  <td>{{ news.title }}</td>
                  <td>
                    <v-avatar tile size="60"  color="#EDEDED" class="mt-3 mb-3">
                      <v-img v-if="news.image !=null" :src="(news.image)"></v-img>
                      <v-icon v-else size="36" color="#C0BFBA">mdi-image-outline</v-icon>
                    </v-avatar>
                  </td>
                  <td>{{ news.body }}</td>

                  <td>{{ news.date }}</td>
                  <td>{{ news.sender }}</td>
                  <td style="width: 35px">
                    <v-btn :disabled="selectedRow !== news" @click="selectNewsForEdit(news)"
                           width="35"
                           plain
                           height="35"
                           class="ma-2"
                           ripple
                           outlined
                           color="#00B51A"
                    >
                      <v-icon size="25">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td style="width: 35px">
                    <div class="text-center">

                        <v-btn :disabled="selectedRow !== news" @click="selectClubNewsForDialog"
                               v-on="on"
                               v-bind="attrs"
                               width="35"
                               plain
                               height="35"
                               class="ma-2"
                               ripple
                               outlined
                               color="#00B51A"
                        >
                          <v-icon size="25">mdi-delete</v-icon>
                        </v-btn>

                    </div>
                  </td>
                </tr>
                <v-dialog
                    v-model="dialog"
                    width="500"
                    persistent :retain-focus="false"
                >


                  <v-card>
                    <v-card-title class="headline green" style="color: white">
                      Hinweis
                    </v-card-title>

                    <v-card-text class="mt-3">
                      Soll diese News wirklich gelöscht werden?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="green"
                          text
                          @click="dialog = false"
                      >
                        Nein
                      </v-btn>
                      <v-btn
                          color="green"
                          text
                          @click="deleteNews()"
                      >
                        Ja
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                </tbody>
              </template>
            </v-simple-table>
            <v-spacer/>
            <v-pagination
                color="#00B51A"
                v-model="page"
                :length="lastPage"
                @input="fetchClubNews()"
            />
          </v-card>
          <v-snackbar
              v-model="snackbar"
          >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
      <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import news from "@/api/requests/news";
const {DateTime} = require("luxon");

export default {
  components: { SideMenu, CustomFooter},
  data() {
    return {
      DateTime: DateTime,
      loading: false,
      users: [],
      clubNews: [],
      page: 1,
      lastPage: 1,
      searchQuery: '',
      error: null,
      selectedItem: null,
      snackbarText: '',
      snackbar: false,
      selectedRow: null,
      selectedClubNews: null,
      dialog: null,
      filter:'admin',
      selected: 'Admin-News',
      items: ['Admin-News', 'Club-News'],
    }
  },
  created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchClubNews();
    }
  },
  methods: {
    deleteNews() {
      if(this.filter === 'club'){
        news.deleteClubNews(this.$store.state.token, this.selectedClubNews.id).then(response => {
          console.log(response);
          this.dialog = false;
          this.fetchClubNews();
          this.snackbarText = 'Club News erfolgreich gelöscht.'
          this.snackbar = true;
        }).catch(e => {
          console.log(e.response);
          this.dialog = false;
          this.snackbarText = 'Club News konnte nicht gelöscht werden.'
          this.snackbar = true;
        });
      }
      else{
        news.deleteAdminNews(this.$store.state.token, this.selectedClubNews.id).then(response => {
          console.log(response);
          this.dialog = false;
          this.fetchClubNews();
          this.snackbarText = 'Admin News erfolgreich gelöscht.';
          this.snackbar = true;
        }).catch(e => {
          console.log(e.response);
          this.dialog = false;
          this.snackbarText = 'Admin News konnte nicht gelöscht werden.';
          this.snackbar = true;
        });
      }
    },
    selectClubNewsForDialog() {
      this.dialog = true;
      this.selectedClubNews = this.selectedRow;
    },
    selectNewsForEdit(news) {
      if(this.selected === 'Admin-News')
        this.$router.push(`/edit-admin-news/${news.id}`);
      else if(this.selected === 'Club-News')
        this.$router.push(`/edit-club-news/${news.id}`);
    },
    selectItem(news) {
      this.selectedRow = news;
    },
    unSelectItem() {
      this.selectedRow = null;
    },
    fetchClubNews() {
      this.loading = true
      news.getAll(this.$store.state.token, this.page, this.filter).then(response => {
        console.log(response);
        this.lastPage = response.meta.last_page;
        this.clubNews = response.data.map(news => ({
          id: news.id,
          title: news.headline == null ? '' : news.headline,
          body: news.content == null ? '' : news.content,
          date: news.date == null ? '' : this.formatTimestamp(news.date),
          image: news.image_url,
          sender: news.club == null ? 'Alle' : news.club.name,
        }));
      }).catch(e => {
        console.log(e.response);
      });
    },
    formatTimestamp(time) {
      if (time === '') {
        return '';
      }
      else{
        let tmpDate = new Date(time).toLocaleDateString(
            'de-de',
            {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            }
        );
        let tmpTime = new Date(time).toLocaleTimeString(
            'de-de',
            {
              hour: 'numeric',
              minute: '2-digit',
            }
        );
        return tmpDate + ' um ' + tmpTime + ' Uhr';
      }
    },
    showTitle(){
      if(this.selected === 'Club-News') {
        return "Alle Club-News"
      }
      else{
        return "Alle Admin-News"
      }
    },
    newClubNews() {
      this.$router.push('/admin-new-club-news');
    },
    selectionChanged(item) {
      switch (item) {
        case this.items[0]:
          this.filter = 'admin';
          break;
        case this.items[1]:
          this.filter = 'club';
          break;
      }
      this.fetchClubNews()
    }
  }

}
</script>