<!-- TODO: paginationBar -->

<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Alle Dienstleistungen
              </span>
          </div>
          <v-spacer/>
          <div class="d-flex align-center mr-5">
            <v-btn style="background:white;color:#00B51A"
                   height="45"
                   elevation="2"
                   v-on:click="newService()">
              <h3 class="font-weight-medium text-none">
                Neuen Dienstleistung anlegen
              </h3>
            </v-btn>
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
      <v-row class="pa-0 ml-2 d-flex align-start">
        <SideMenu/>
        <v-col class="mr-15">
          <v-card class="mr-3 ml-3">
            <v-simple-table class="mt-5">
              <template v-slot:default>
                <thead>
                <tr>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Bezeichnung
                  </h3></th>
                  <th><h3 class="font-weight-bold text-capitalize">
                    Preis
                  </h3></th>
                  <th/><th/>
                </tr>
                </thead>
                <tbody id="lbody">
                <tr v-for="service in services" :key="service.id" @mouseover="selectItem(service)" @mouseleave="unSelectItem()">
                  <td>{{ service.name }}</td>
                  <td>{{ service.price }}</td>

                  <td style="width: 35px">
                    <v-btn :disabled="selectedItem !== service"
                           v-on:click.stop="editService(service)"
                           width="35"
                           plain
                           height="35"
                           class="ma-2"
                           ripple
                           outlined
                           color="#00B51A"
                    >
                      <v-icon size="25">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td style="width: 35px">
                    <div class="text-center">
                      <v-btn :disabled="selectedItem !== service"
                             v-on:click.stop="selectionForDialog"
                             width="35"
                             plain
                             height="35"
                             class="ma-2"
                             ripple
                             outlined
                             color="#00B51A"
                      >
                        <v-icon size="25">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <v-dialog
                    v-model="dialog"
                    width="500"
                >
                  <v-card>
                    <v-card-title class="headline green" style="color: white">
                      Hinweis
                    </v-card-title>

                    <v-card-text class="mt-3">
                      Soll diese Dienstleistung wirklich gelöscht werden?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="green"
                          text
                          @click="dialog = false"
                      >
                        Nein
                      </v-btn>
                      <v-btn
                          color="green"
                          text
                          @click="deleteService(selectedService.id)"
                      >
                        Ja
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                </tbody>
              </template>
            </v-simple-table>
            <v-spacer/>
            <v-pagination
                color="#00B51A"
                v-model="page"
                :length="lastPage"
                @input="fetchServices"
            />
          </v-card>
        </v-col>
      </v-row>
      <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import SideMenu from "@/components/SideMenu";
import CustomFooter from "@/components/CustomFooter";
import service from "@/api/requests/service";
import Sortable from 'sortablejs';

export default {
  components: {SideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      services: [],
      page: 1,
      lastPage: 1,
      searchQuery: '',
      error: null,
      selectedItem: null,
      selectedService: null,
      dialog: null,
      headers:[
        {
          text: "",
          align: "left",
          sortable: false
        }
      ]

    }
  },
  async created() {
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.fetchServices();
    }
  },
  mounted() {
    let elm = document.getElementById("lbody");
    const _self = this;
    Sortable.create(elm, {
      async onEnd ({newIndex, oldIndex}) {
        const rowSelected = _self.services.splice(oldIndex, 1)[0];
        _self.services.splice(newIndex, 0, rowSelected);
        await _self.sendSort(newIndex)
      }
    });
  },
  methods: {
    selectItem(service) {
      this.selectedItem = service;
    },
    unSelectItem() {
      this.selectedItem = null;
    },
    selectionForDialog() {
      this.dialog = true;
      this.selectedService = this.selectedItem;
    },
    editService(service) {
      this.$router.push(`/edit-service/${service.id}`);
    },
    newService() {
        this.$router.push(`/new-service`);
    },
    fetchServices() {
      this.loading = true;
      service.getAll(this.$store.state.token, this.page).then(response => {
        this.lastPage = response.meta.last_page
        this.services = response.data.map(service => ({
          id: service.id ?? '',
          name: service.name ?? '',
          price: service.price.toFixed(2).toString().replace('.', ',') ?? '',
        }));
      }).catch(e => {
        console.log(e.response);
      });
      this.loading = false;
    },
    async sendSort(index){
      this.loading = true;
      let changeService = this.services[index].id ;
      let aboveService = this.services[index - 1] != null ? this.services[index - 1].id : null;
      let belowService = this.services[index + 1] != null ? this.services[index + 1].id : null;
      await service.sort(this.$store.state.token, aboveService ?? "",changeService, belowService ?? "").catch(e => {
        console.log(e.response);
      });
    },
    deleteService(id) {
      service.delete(this.$store.state.token, id).then(response =>{
        console.log(response)
        this.dialog = false
        this.fetchServices()
      }).catch(e => {
        console.log(e.response);
      })

    },
  }

}
</script>