import Repository from "../repository";

export default {
    async getAll(token, page) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        try {
            const response = await Repository.get(`twcustomer?paginate=24&page=${page}`, config);
            return response.data
        } catch (e) {
            console.log(e);
        }
    },

    async getAllID(token) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        try {
            const response = await Repository.get(`twcustomer?simple=true`, config);
            return response.data
        } catch (e) {
            console.log(e);
        }
    },
    async getAllNoPagination(token) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        try {
            const response = await Repository.get('twcustomer', config);
            return response.data
        } catch (e) {
            console.log(e);
        }
    },

    async getCustomerByID(token, customerID) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        try {
            const response = await Repository.get(`twcustomer/${customerID}`, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    async saveCustomer(token, customerNo, name) {
        console.log({customerNo, name});
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const customerData = {
            customerNo: customerNo,
            name: name,
        };
        console.log(customerData);

        try {
            const response = await Repository.post(`twcustomer`, customerData, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    async update(token, twCustomerId, customerNo, name) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const customerData = {
            customerNo: customerNo,
            name: name,
        };
        const response = await Repository.put(`twcustomer/${twCustomerId}`, customerData, config);
        return response.data;
    },
    
    async delete(token, customerID) {
        // TODO: not yet working. need to be implemented in Backend and tested!
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        try {
            const response = await Repository.delete(`twcustomer/${customerID}`, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    async sortCustomer(token, aboveId, changeId, belowId) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const customerData = {
            id_above: aboveId,
            id_change: changeId,
            id_below: belowId,
        };
        try {
            const response = await Repository.post(`sortTWC`, customerData, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    async invoiceRun(token, invoiceData) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        try {
            const response = await Repository.post(`invoiceRun`, invoiceData, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

}