import Repository from "../repository";

export default {
    async getAll(page, role, countryID, state, cityID, query) {
        const response = await Repository.get(`users?paginate=25&page=${page}&role=${role}&country-id=${countryID}&state=${state}&city-id=${cityID}&query=${query}`);
        return response.data;
    },
    async getUserByID(userID) {
        const response = await Repository.get(`users/${userID}`);
        return response.data;
    },
    async changeRole(token, userID, role) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const userData = {
            role: role
        };
        const response = await Repository.post(`changeRole/${userID}`, userData, config);
        return response.data;
    },
    async update(userID) {
        const userData = {
            role: '4',
        };
        const response = await Repository.put(`users/${userID}`, userData);
        return response.data;
    },
    async delete(token, userID) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const response = await Repository.delete(`delete-user/${userID}`, config);
        return response.data;
    },
    async getAllUsersForClub(page, token, role) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const response = await Repository.get(`get-users-for-club?paginate=25&page=${page}&role=${role}`, config);
        return response.data;
    },
    async changeClub(token, clubID) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const response = await Repository.post(`changeClub/${clubID}`, {}, config);
        return response.data;
    },
    async saveUser(token, firstName, lastName, email, clubID, gender, role) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const userData = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            club_id: clubID,
            gender: gender,
            role: role,
        };
        const response = await Repository.post('add-user-as-admin', userData, config);
        return response.data;
    },
}
