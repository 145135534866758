<template>
  <v-container fluid class="mt-14 ml-10 pb-0 fill-height align-start" style="background: #EDEDED">
    <v-app-bar
        app
        color="#00B51A">
      <v-flex>
        <v-row>
          <div class="pa-0 d-flex align-start">
            <router-link to="/club-admin-dashboard">
              <v-img
                  :src="require('../assets/logo_club4ever.svg')"
                  class="ma-0"
                  contain
                  max-height="50"
                  max-width="190"
              />
            </router-link>
          </div>
          <div class="d-flex align-center">
              <span class="font-weight-medium" style="color: white;font-size: x-large">
            Alle Nutzer
              </span>
          </div>
          <v-spacer/>
          <div class="d-flex align-center mr-5">
            <v-sheet width="200" height="45" color="#00B51A">
              <v-select color="white" item-color="#00B51A" height="45" style="color: white; border-color: white"
                        :items="items"
                        dense
                        solo
                        v-model="selectedFilter"
                        @change="selectionChanged"
              ></v-select>
            </v-sheet>
          </div>
          <div v-if="this.role===4" class="d-flex align-center mr-5">
            <v-btn style="background:white;color:#00B51A"
                   height="45"
                   elevation="2"
                   v-on:click="newUser">
              <h3 class="font-weight-medium text-none">
                Neuen Club Admin anlegen
              </h3>
            </v-btn>
          </div>
        </v-row>
      </v-flex>
    </v-app-bar>
    <v-layout justify-space-between column>
    <v-row class="pa-0 ml-2 d-flex align-start">
      <ClubAdminSideMenu/>
      <v-col class="mr-15">
        <v-card class="mr-3 ml-3">
          <v-simple-table class="mt-5">
            <template v-slot:default>
              <thead>
              <tr>
                <th><h3 class="font-weight-bold text-capitalize">
                  Land
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Bundesland
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Stadt
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Logo
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Club Name
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Nutzer Rolle
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Nutzer Profilbild
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Nutzer Firmenname
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Nutzer Nachname, Vorname
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  E-Mail
                </h3></th>
                <th><h3 class="font-weight-bold text-capitalize">
                  Nutzer-ID
                </h3></th>
                <th/>
              </tr>
              </thead>
              <tbody>
              <tr v-for="user in users" :key="user.id" @mouseover="selectItem(user)" @mouseleave="unSelectItem()">
                <td>{{ user.country }}</td>
                <td>{{ user.state }}</td>
                <td>{{ user.city }}</td>
                <td>
                  <v-avatar class="mt-3 mb-3">
                    <v-img :src="(user.clubLogo)" max-height="100" width="60"></v-img>
                  </v-avatar>
                </td>
                <td>{{ user.clubName }}</td>
                <td>{{ user.role }}</td>
                <td>
                  <v-avatar size="45"  color="#EDEDED" class="mt-3 mb-3">
                    <v-img v-if="user.profileImage !=null" :src="(user.profileImage)"></v-img>
                    <v-icon v-else size="30" color="#C0BFBA">mdi-account</v-icon>
                  </v-avatar>
                </td>
                <td>{{ user.companyName }}</td>
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.id }}</td>
                <td v-if="(role===4 && (selectedFilter===items[2]||selectedFilter===items[1])) || (role ===2 && selectedFilter===items[1])" style="width: 35px">
                  <div class="text-center">
                      <v-btn :disabled="selectedRow !== user" @click="selectUserForDialog"
                             width="35"
                             plain
                             height="35"
                             class="ma-2"
                             ripple
                             outlined
                             color="#00B51A"
                      >
                        <v-icon size="25">mdi-delete</v-icon>
                      </v-btn>
                  </div>
                </td>
              </tr>
              <v-dialog
                  v-model="dialog"
                  width="500"
              >


                <v-card>
                  <v-card-title class="headline green" style="color: white">
                    Hinweis
                  </v-card-title>

                  <v-card-text class="mt-3">
                    Soll dieser Nutzer wirklich gelöscht werden?
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green"
                        text
                        @click="dialog = false"
                    >
                      Nein
                    </v-btn>
                    <v-btn
                        color="green"
                        text
                        @click="deleteUser(user.id)"
                    >
                      Ja
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              </tbody>
            </template>
          </v-simple-table>
          <v-spacer/>
          <v-pagination
              color="#00B51A"
              v-model="page"
              :length="lastPage"
              @input="fetchUsers()"
          />
        </v-card>
        <v-snackbar
            v-model="snackbar"
        >
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
      <CustomFooter/>
    </v-layout>
  </v-container>
</template>

<script>
import user from "@/api/requests/user";
import ClubAdminSideMenu from "@/components/ClubAdminSideMenu";
import CustomFooter from "@/components/CustomFooter";

export default {
  components: {ClubAdminSideMenu, CustomFooter},
  data() {
    return {
      loading: false,
      users: [],
      selectedFilter: 'Alle',
      items: ['Alle', 'Club Admins', 'Nutzer'],
      page: 1,
      dialog: null,
      role: null,
      snackbarText: '',
      snackbar: false,
      lastPage: 1,
      selectedRole: '',
      selectedRow: null,
      selectedUser: null,
      error: null,
    }
  },
  created() {
    this.role = this.$store.state.role;
    if (this.$store.state.token.length === 0) {
      this.$router.push('/login');
    } else {
      this.selectedRole = ''
      this.fetchUsers();
    }
  },
  methods: {
    selectUserForDialog(){
      this.dialog = true;
      this.selectedUser = this.selectedRow;
    },
    deleteUser(){
      user.delete(this.$store.state.token, this.selectedUser.id).then(response =>{
        console.log(response);
        this.selectionChanged(this.selectedFilter);
        this.dialog = false;
        this.snackbarText = 'Nutzer erfolgreich gelöscht.'
        this.snackbar = true;
      }).catch(e => {
        console.log(e.response);
        this.dialog = false;
        this.snackbarText = 'Nutzer konnte nicht gelöscht werden.'
        this.snackbar = true;
      });
    },
    selectItem(user) {
      this.selectedRow = user
    },
    unSelectItem() {
      this.selectedRow = null
    },
    newUser(){
      this.$router.push('/club-admin-new-user');
    },
    selectionChanged(item) {
      this.selectedFilter = item;
      switch (item) {
        case this.items[0]:
          this.selectedRole = ''
          this.page = 1
          this.fetchUsers();
          break;
        case this.items[1]:
          this.selectedRole = '2'
          this.page = 1
          this.fetchUsers();
          break;
        case this.items[2]:
          this.selectedRole = '3'
          this.page = 1
          this.fetchUsers();
          break;
      }
    },
    fetchUsers() {
      this.loading = true
      user.getAllUsersForClub(this.page, this.$store.state.token, this.selectedRole).then(response => {
        this.lastPage = response.meta.last_page;
        console.log(this.lastPage);
        this.users = response.data.map(user => ({
          country: 'Germany',
          state: user.club.city.state,
          city: user.club.city.name,
          clubLogo: user.club.logo_url,
          clubName: user.club.name,
          profileImage: user.profile_image_url,
          name: user.last_name + ', ' + user.first_name,
          companyName: user.company_name == null ? '' : user.company_name,
          id: user.id,
          email: user.email,
          role: this.parseRoleToString(user.role),
        }));
      }).catch(e => {
        console.log(e.response);
      });
    },
    parseRoleToString(role){
      switch(role){
        case 2: return 'Club Admin';
        case 3: return 'Nutzer';
        case 4: return 'dessaso Kontakt';
      }
    },
  }

}
</script>