<template>
  <v-flex class="background-svg">
    <div class="d-flex align-end">
      <v-col class="mt-15 align-end" cols="12">
        <v-form ref="form" class="mx-2 d-flex flex-column align-center mb-6" lazy-validation>
          <div class="d-flex">
            <span class="font-weight-medium mb-5" style="color: white; font-size: 30px">
               Backoffice
              </span>
          </div>
          <div class="d-flex">
            <v-img
                :src="require('../assets/logo_club4ever.svg')"
                class="my-3"
                contain
                max-height="100"
            />
          </div>

          <v-card class="ma-5 mt-11 mb-16" color="white" width="420">
            <v-container>
              <v-col>
                <div class="justify-start">
                 <span class="font-weight-regular">
               E-Mail-Adresse
              </span>
                </div>

                <v-text-field class="mt-2"
                              color="#00B51A"
                              outlined
                              v-model="email"
                              :rules="[v => !!v || 'Bitte gib deine E-Mail-Adresse ein']"
                >
                </v-text-field>
                <span class="font-weight-regular">
               Passwort
              </span>
                <v-text-field class="mt-2"
                              color="#00B51A"
                              type="password"
                              outlined
                              v-model="password"
                              :rules="[v => !!v || 'Bitte gib dein Passwort ein']"
                >
                </v-text-field>
                <div class="text-center">
                  <v-btn class="mt-5 w-full" style="background:#00B51A;color:white"
                         height="45"
                         width="200"
                         elevation="2"
                         v-on:click="login">
                    <h3 class="font-weight-medium text-capitalize">
                      Login
                    </h3>
                  </v-btn>
                </div>
                <div class="text-center mt-3">
                  <v-btn text color="blue" style="text-transform: none !important;" v-on:click="forgotPassword">
                    <h4 class="font-weight-medium">
                      Passwort vergessen
                    </h4>
                  </v-btn>
                </div>

                <v-snackbar
                    v-model="snackbar"
                >
                  Bitte überprüfe deine Eingaben

                  <template v-slot:action="{ attrs }">
                    <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                    >
                      OK
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-col>
            </v-container>
          </v-card>
          <div class="d-flex">
            <span class="font-weight-medium mt-16 mb-2" style="color: white; font-size: 15px">
               powered by
              </span>
          </div>
          <div class="d-flex">
            <v-img
                :src="require('../assets/logo_dessaso.svg')"
                class="my-3"
                contain
                width="150"
                max-height="100"
            />
          </div>
        </v-form>
      </v-col>
    </div>
  </v-flex>
</template>

<style>
.background-svg {
  background-image: url(../assets/industrial.jpeg);
  background-size: cover;
  background-position: center;
}
</style>
<script>
import auth from "@/api/requests/auth";

export default {
  data() {
    return {
      email: '',
      password: '',
      snackbar: false,
    }
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        auth.login(this.email, this.password).then(response => {
          this.$store.commit('saveToken', response.remember_token);
          this.$store.commit('saveRole', response.role);
          if(response.role === 2 || response.role === 4){
            this.$router.push('/club-admin-dashboard');
          }else if(response.role === 1){
            this.$router.push('/dashboard');
          }
        }).catch(e => {
          this.snackbar = true;
          console.log(e);
        });
      }
    },
    forgotPassword(){
      this.$router.push('/forgot-password');
    }
  }
}
</script>