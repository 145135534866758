import Repository from "../repository";

export default {
    async getAll(token, page, recipientId, month, year) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        try {
            const response = await Repository.get(`billing?paginate=25&page=${page}&recipientId=${recipientId}&month=${month}&year=${year}`, config);
            return response.data
        } catch (e) {
            console.log(e);
        }
    },

    async getBillItemByID(token, billItemID) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        try {
            const response = await Repository.get(`billing/${billItemID}`, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    async saveBillItem(token, recipientId, serviceId, quantity) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        const billItemData = {
            recipientId: recipientId,
            serviceId: serviceId,
            quantity: quantity,
        };
        try {
            const response = await Repository.post(`billing`, billItemData, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    async saveBulkBillItems(token, billItems){
      const config = {
          headers: {Authorization: `Bearer ${token}`},
      }
      const billItemsBody = {
          billItems: billItems
      }
      try {
          const response = await Repository.post(`billingBulk`, billItemsBody, config);
          return response.data;
      }catch (e) {
          console.log(e);
      }
    },

    async update(token, billItemId, serviceId, quantity) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        const billItemData = {
            serviceId: serviceId,
            quantity: quantity,
        };
        const response = await Repository.put(`billing/${billItemId}`, billItemData, config);
        return response.data;
    },

    async delete(token, billItemID) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        try {
            const response = await Repository.delete(`billing/${billItemID}`, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },

    async autoLoadBilling(token, recipientId, month, year) {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        }
        try {
            const response = await Repository.get(`billing/autoload/${recipientId}?month=${month}&year=${year}`, config);
            return response.data;
        } catch (e) {
            console.log(e);
        }
    }
}